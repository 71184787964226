import type { FormEvent, KeyboardEvent } from 'react';
import classNames from 'classnames';

import { Key } from 'src/constants';
import type { CheckboxProps } from './checkbox.props';

import './checkbox.scss';

function Checkbox({
  id,
  isChecked,
  isDisabled,
  isError,
  isSemiState,
  label,
  onChange,
}: CheckboxProps) {
  const className = classNames('checkbox', !label && 'checkbox_without-label', {
    checkbox_checked: isChecked,
    checkbox_disabled: isDisabled,
    checkbox_error: isError,
    checkbox_semi: isSemiState,
  });

  const handleChange = (evt: FormEvent<HTMLInputElement>) => onChange(evt.currentTarget.checked);

  const handleKeyDown = (evt: KeyboardEvent<HTMLInputElement>) => {
    if (evt.key === Key.ENTER) {
      onChange(evt.currentTarget.checked);
    }
  };

  return (
    <div className={className}>
      <input
        className="checkbox__input"
        id={id}
        checked={isChecked}
        disabled={isDisabled}
        type="checkbox"
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
      <span className="checkbox__texts">
        <label className="checkbox__label" htmlFor={id}>
          <span className="checkbox__icon-wrapper">
            <span className="checkbox__square-icon">
              <span className="checkbox__check-icon" />
            </span>
          </span>
          {label && <span className="checkbox__label-text">{label}</span>}
        </label>
      </span>
    </div>
  );
}

export default Checkbox;
