import classNames from 'classnames';

import Loader from 'src/components/common/loader';
import Button from 'src/components/ui/button';
import { ReactComponent as DownloadIcon } from 'src/assets/icons/download-doc.svg';

import type { FiltersPanelProps } from './filters-panel.props';

import './filters-panel.scss';

const FiltersPanel = ({
  className,
  children,
  isDownloading,
  isDisabled,
  onExportData,
}: FiltersPanelProps) => {
  return (
    <section className={classNames('filters-panel', className)}>
      <div className="filters-panel__wrapper  scrollbar">
        <div className="filters-panel__main-content">{children}</div>
        {onExportData && (
          <Button
            icon={isDownloading ? <Loader isInline size="small" /> : <DownloadIcon />}
            isDisabled={isDownloading || isDisabled}
            label="Export as XLS"
            theme="outlined"
            onClick={onExportData}
          />
        )}
      </div>
    </section>
  );
};

export default FiltersPanel;
