import React, { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';

import useLocale from 'src/hooks/use-locale';
import { DEFAULT_LANGUAGE, Language, Locale, LocaleProperty, LocaleValue } from 'src/constants';

interface LanguageContextProps {
  language: Language;
  texts: Record<LocaleProperty, string>;
  getText: (key: LocaleProperty) => string;
  setLanguage: (language: Language) => void;
}

export const initialLanguageContextProps = {
  language: DEFAULT_LANGUAGE,
  texts: Locale[DEFAULT_LANGUAGE],
  getText: (key: LocaleProperty) => Locale[DEFAULT_LANGUAGE][key],
  setLanguage: (_language: Language) => {},
};

export const LanguageContext = createContext<LanguageContextProps>(initialLanguageContextProps);

const LanguageProvider = ({ children }: { children: ReactNode }) => {
  const [language, setLanguage] = useState<Language>(DEFAULT_LANGUAGE);
  const locale = useMemo(() => LocaleValue[language], [language]);
  const getText = useCallback((key: LocaleProperty) => Locale[language][key], [language]);

  useLocale(locale);

  return (
    <LanguageContext.Provider value={{ language, texts: Locale[language], getText, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

const useLanguage = () => useContext(LanguageContext);

export { LanguageProvider, useLanguage };
