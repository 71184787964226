import React from 'react';
import classNames from 'classnames';

import type { RadioboxProps } from './radiobox.props';

import './radiobox.scss';

function Radiobox<ValueType extends string = string>({
  idx,
  isChecked,
  isDisabled,
  label,
  name,
  value,
  onChange,
}: RadioboxProps<ValueType>) {
  const id = `radiobox_${name}_${idx}`;
  const radioboxClassName = classNames('radiobox', {
    radiobox_checked: isChecked,
    radiobox_disabled: isDisabled,
  });

  return (
    <div className={radioboxClassName}>
      <input
        className="radiobox__input"
        id={id}
        checked={isChecked}
        disabled={isDisabled}
        name={name}
        type="radio"
        onChange={() => onChange(value)}
      />
      <label className="radiobox__label" htmlFor={id}>
        <span className="radiobox__label-icon">
          <span className="radiobox__label-icon-wrapper" />
        </span>
        <span className="radiobox__label-text">{label}</span>
      </label>
    </div>
  );
}

export default Radiobox;
