import { makeAutoObservable, reaction } from 'mobx';

import popupStore from 'src/stores/popup-store';
import invoicesStore from './invoices-store';
import sidebarStore from './sidebar-store';
import socketStore from './socket-store';

import { mapApiToDetailedInvoice } from 'src/adapters';
import {
  fetchInvoice,
  fetchInvoiceCancel,
  fetchInvoicePaymentCreate,
  fetchInvoicePaymentRecord,
} from 'src/api';
import {
  ApiDateFormat,
  AppRoute,
  ErrorText,
  LocaleFormat,
  PopupName,
  PopupTitle,
  PUBLIC_URL,
} from 'src/constants';
import { convertDate, getMainCurrency, getSubunitCurrency } from 'src/utils';
import type { IApiDetailedInvoice, IDetailedInvoice, TRecordPaymentMethod } from 'src/interfaces';

class InvoiceDetailsStore {
  invoice: IDetailedInvoice | null = null;
  isLoading = false;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    socketStore.setInvoicesListener(this.updateInvoice);

    reaction(
      () => sidebarStore.dataId,
      (id) => {
        if (id) {
          this.openInvoice(id);
        } else {
          this.closeInvoice();
        }
      },
      { fireImmediately: true }
    );
  }

  setInvoice(invoice: IDetailedInvoice | null) {
    this.invoice = invoice;
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  openInvoice(id: string) {
    void this.loadInvoice(id);
    socketStore.subscribeToInvoice(id);
  }

  closeInvoice() {
    socketStore.unsubscribeFromInvoice();
    this.clearStore();
  }

  updateInvoice(updatedInvoice: IApiDetailedInvoice) {
    if (this.invoice && this.invoice.id === updatedInvoice.id) {
      this.setInvoice(mapApiToDetailedInvoice(updatedInvoice));
      invoicesStore.updateInvoice(updatedInvoice);
    }
  }

  clearStore() {
    this.setIsLoading(false);
    this.setInvoice(null);
  }

  async handleCancelInvoice() {
    const invoiceId = this.invoice?.id;
    if (!invoiceId) {
      return;
    }

    const { error, isSuccess } = await fetchInvoiceCancel(invoiceId);
    popupStore.showPopup(PopupName.WARN, {
      title: isSuccess ? PopupTitle.SUCCESS : ErrorText.REQUEST_FAILURE,
      text: isSuccess ? 'Invoice successfully canceled' : error || ErrorText.DEFAULT,
    });
  }

  async handleClickCreatePaymentButton() {
    const invoiceId = this.invoice?.id;
    if (!invoiceId) {
      return;
    }

    const createPayment = async ({
      amount,
      installments,
    }: {
      amount: number;
      installments: number;
    }) => {
      const cents = getSubunitCurrency(amount);
      const { data, error, isSuccess } = await fetchInvoicePaymentCreate(invoiceId, {
        amount: cents,
        installments,
      });

      if (isSuccess && data.id) {
        window.open(
          `${PUBLIC_URL}${AppRoute.PAYMENT}/${data.id}`,
          '_blank',
          'noopener, noreferrer'
        );
      } else {
        popupStore.showPopup(PopupName.WARN, {
          title: ErrorText.REQUEST_FAILURE,
          text: error || ErrorText.DEFAULT,
        });
      }

      return isSuccess;
    };

    popupStore.showPopup(PopupName.CREATE_PAYMENT, {
      maxAmount: getMainCurrency(this.invoice!.balance),
      createPayment,
    });
  }

  // WARN: DO NOT TEST ON DEV!
  async handleClickRecordPaymentButton() {
    const invoiceId = this.invoice?.id;
    if (!invoiceId) {
      return;
    }

    const onRecordPayment = async ({
      amount,
      date,
      method,
    }: {
      amount: number;
      date: string;
      method: TRecordPaymentMethod;
    }) => {
      const { error, isSuccess } = await fetchInvoicePaymentRecord(invoiceId, {
        amount: getSubunitCurrency(amount),
        paid_at: convertDate(date, LocaleFormat.he, ApiDateFormat),
        payment_type: method,
      });

      if (isSuccess) {
        sidebarStore.hideSidebar();
        popupStore.showPopup(PopupName.WARN, {
          title: PopupTitle.SUCCESS,
          text: 'The payment was successfully recorded',
        });
      } else {
        popupStore.showPopup(PopupName.WARN, {
          title: ErrorText.REQUEST_FAILURE,
          text: error || ErrorText.DEFAULT,
        });
      }
    };

    popupStore.showPopup(PopupName.RECORD_PAYMENT, {
      maxAmount: getMainCurrency(this.invoice!.balance),
      onRecordPayment,
    });
  }

  async loadInvoice(id: string) {
    this.setIsLoading(true);
    const invoice = await fetchInvoice(id);
    this.setInvoice(invoice);
    this.setIsLoading(false);
  }
}

export default new InvoiceDetailsStore();
