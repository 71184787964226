import React from 'react';

import FinancialsStatistics from 'src/components/common/financials-statistics';
import Header from 'src/components/common/header';

import invoicesStore from 'src/stores/invoices-store';

import { Title } from 'src/constants';

const MainHeader = () => {
  return (
    <Header
      itemsStore={invoicesStore}
      searchTitle={Title.SEARCH_INPUT_INVOICES}
      statistics={FinancialsStatistics}
      title={Title.MAIN}
    />
  );
};

export default MainHeader;
