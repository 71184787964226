export enum PaymentMethod {
  BALANCE = 'balance',
  BANK_TRANSFER = 'bank_transfer',
  BIT = 'bit',
  CASH = 'cash',
  CHECK = 'check',
  CREDIT_DEBIT_CARD = 'card',
  INSTALLMENT = 'installment',
}

export const PaymentMethodLabel: Record<PaymentMethod, string> = {
  [PaymentMethod.BALANCE]: 'Balance',
  [PaymentMethod.BANK_TRANSFER]: 'Bank Transfer',
  [PaymentMethod.BIT]: 'Bit',
  [PaymentMethod.CASH]: 'Cash',
  [PaymentMethod.CHECK]: 'Check',
  [PaymentMethod.CREDIT_DEBIT_CARD]: 'Card',
  [PaymentMethod.INSTALLMENT]: 'Installments',
};

export const DEFAULT_SELECT_INSTALLMENTS_NUMBER = 1;
export const MAX_INSTALLMENTS_NUMBER = 12;
export const FULL_PAYMENT_INSTALLMENTS_NUMBER = 1;
