import { FULL_PAYMENT_INSTALLMENTS_NUMBER } from 'src/constants';
import { formatApiToPhone, formatDate } from 'src/utils';
import type { IApiListPayment, IApiPayment, IListPayment, IPayment } from 'src/interfaces';

export const mapApiToListPayment = ({
  invoice_no,
  invoice_id,
  invoice_status,
  purchaser_name,
  purchaser_phone,
  paid_at,
  meshulam_invoice_url,
  ...rest
}: IApiListPayment): IListPayment => {
  return {
    ...rest,
    invoiceNo: invoice_no,
    invoiceId: invoice_id,
    invoiceStatus: invoice_status,
    paidAt: paid_at,
    purchaserName: purchaser_name,
    purchaserPhone: purchaser_phone,
    meshulamInvoiceUrl: meshulam_invoice_url,
  };
};

export const mapApiToPayment = ({
  installments,
  invoice_no,
  meshulam_invoice_url,
  paid_at,
  purchaser_email,
  purchaser_name,
  purchaser_phone,
  ...rest
}: IApiPayment): IPayment => {
  return {
    ...rest,
    installments: installments || FULL_PAYMENT_INSTALLMENTS_NUMBER,
    invoiceNo: invoice_no || '',
    meshulamInvoiceUrl: meshulam_invoice_url,
    paidAt: paid_at ? formatDate(paid_at) : '',
    purchaser: {
      email: purchaser_email || '',
      name: purchaser_name || '',
      phone: purchaser_phone ? formatApiToPhone(purchaser_phone) : '',
    },
  };
};
