import type { ITableHeaderCell } from 'src/interfaces';

export const MAX_TABLE_LIST_COUNT_PER_PAGE = 15;

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export enum TableType {
  MAIN = 'main',
  PAYMENTS = 'payments',
  SIDEBAR_PAYMENTS = 'sidebar-payments',
}

export enum TableColumn {
  ACTIONS = 'actions',
  AMOUNT = 'amount',
  BALANCE = 'balance',
  CREATED_AT = 'created_at',
  INVOICE = 'invoice',
  PAID = 'paid',
  PURCHASER = 'purchaser',
  SELLER = 'seller',
  STATUS = 'status',
  TOTAL = 'total',
  TYPE = 'type',
}

export const TABLE_MAIN_COLUMNS: ITableHeaderCell[] = [
  { id: TableColumn.CREATED_AT, value: 'Date' },
  { id: TableColumn.INVOICE, value: 'Invoice #' },
  { id: TableColumn.SELLER, value: 'Seller' },
  { id: TableColumn.PURCHASER, value: 'Purchaser' },
  { id: TableColumn.TOTAL, value: 'Total, ₪' },
  { id: TableColumn.PAID, value: 'Paid, ₪' },
  { id: TableColumn.BALANCE, value: 'Balance, ₪' },
  { id: TableColumn.STATUS, value: 'Status' },
];

export const TABLE_SIDEBAR_PAYMENTS_COLUMNS: ITableHeaderCell[] = [
  { id: TableColumn.CREATED_AT, value: 'Date' },
  { id: TableColumn.AMOUNT, value: 'Amount' },
  { id: TableColumn.TYPE, value: 'Type' },
  { id: TableColumn.STATUS, value: 'Status' },
];

export const TABLE_PAYMENTS_COLUMNS: ITableHeaderCell[] = [
  { id: TableColumn.CREATED_AT, value: 'Date' },
  { id: TableColumn.INVOICE, value: 'Invoice #' },
  { id: TableColumn.SELLER, value: 'Seller' },
  { id: TableColumn.PURCHASER, value: 'Purchaser' },
  { id: TableColumn.TYPE, value: 'Type' },
  { id: TableColumn.PAID, value: 'Paid, ₪' },
  { id: TableColumn.BALANCE, value: 'Balance, ₪' },
  { id: TableColumn.STATUS, value: 'Sale status' },
  { id: TableColumn.ACTIONS, value: 'Actions' },
];
