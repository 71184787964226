import React from 'react';
import { observer } from 'mobx-react-lite';

import Button from 'src/components/ui/button';

import popupStore from 'src/stores/popup-store';

import { PopupName } from 'src/constants';
import type { ICommonPopupProps } from 'src/interfaces';

const PopupWarn = observer(({ onCloseButtonClick }: ICommonPopupProps) => {
  const {
    buttonLabel = 'Ok',
    text = 'Please try again later',
    title = 'Something went wrong',
  } = popupStore.getPopupState(PopupName.WARN);

  return (
    <>
      <div className="popup__header">
        <p className="popup__title">{title}</p>
        <p className="popup__text">{text}</p>
      </div>
      <Button
        className="popup__button"
        label={buttonLabel}
        theme="filled"
        onClick={onCloseButtonClick}
      />
    </>
  );
});

export default PopupWarn;
