import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import DescriptionList from 'src/components/common/description-list';
import Financials from 'src/components/common/financials';
import InfoBlock from 'src/components/common/info-block';
import Loader from 'src/components/common/loader';
import SidebarPaymentsTable from 'src/components/common/sidebar-payments-table';
import SummaryProductList from 'src/components/common/summary-product-list';

import invoiceDetailsStore from 'src/stores/invoice-details-store';

import useIsTablet from 'src/hooks/use-is-tablet';
import { formatApiToPhone } from 'src/utils';

import './print-invoice-page.scss';

const PrintInvoicePage = observer(() => {
  const { id } = useParams();
  const { invoice, isLoading, clearStore, loadInvoice } = invoiceDetailsStore;
  const [isLogoLoaded, setLogoLoaded] = useState(false);
  const isTablet = useIsTablet();

  useEffect(() => {
    if (id) {
      void loadInvoice(id);
    }
    return clearStore;
  }, [clearStore, id, loadInvoice]);

  useEffect(() => {
    if (!isLoading && invoice && isLogoLoaded) {
      window.print();
    }
  }, [invoice, isLoading, isLogoLoaded]);

  if (isLoading || !invoice) {
    return <Loader position="fixed" />;
  }

  const { balance, items, no, paid, payments, purchaser, total } = invoice!;

  const summaryData = [
    {
      key: 'Name',
      value: purchaser.name,
    },
    {
      key: 'Phone',
      value: formatApiToPhone(purchaser.phone),
    },
    {
      key: 'Email',
      value: purchaser.email,
    },
  ];

  return (
    <div className="print-invoice-page scrollbar">
      <div className="print-invoice-page__wrapper">
        <img
          className="print-invoice-page__logo"
          alt="Bezikaron Logo"
          height={40}
          src="/logos/logo-sidebar.svg"
          width={100}
          onLoad={() => setLogoLoaded(true)}
        />

        <h1 className="print-invoice-page__title">Invoice {no}</h1>
        <div className="print-invoice-page__content">
          <InfoBlock title="Purchaser" withGap>
            <DescriptionList pairData={summaryData} />
          </InfoBlock>

          <InfoBlock title="Products & Services">
            <SummaryProductList productItems={items} />
          </InfoBlock>

          <InfoBlock isTitleNoUnderline={!isTablet} title="Invoice Details" withGap>
            <Financials balance={balance} location="sidebar" paid={paid} total={total} />
          </InfoBlock>

          {!!payments.length && (
            <InfoBlock isPayments title="Payments">
              <SidebarPaymentsTable isLoading={isLoading} isPrintable paymentItems={payments} />
            </InfoBlock>
          )}
        </div>
      </div>
    </div>
  );
});

export default PrintInvoicePage;
