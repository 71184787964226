import type React from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import TooltipTrigger from 'src/components/ui/tooltip-trigger';

import type { NavbarItemProps } from './navbar-item.props';

import './navbar-item.scss';

function NavbarItem({ className, children, buttonProps, linkProps, tooltipText }: NavbarItemProps) {
  const renderInnerElement = () => {
    if (buttonProps) {
      const { onClick } = buttonProps;
      const handleButtonClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
        evt.preventDefault();
        onClick();
      };

      return (
        <button
          className="navbar-item__button"
          aria-label={tooltipText}
          onClick={handleButtonClick}
        >
          {children}
        </button>
      );
    }

    if (linkProps) {
      const { url, isExternal } = linkProps;

      if (isExternal) {
        return (
          <a className="navbar-item__link" aria-label={tooltipText} href={url}>
            {children}
          </a>
        );
      }

      return (
        <NavLink
          className={({ isActive }) =>
            classNames('navbar-item__link', isActive && 'navbar-item__link_active')
          }
          aria-label={tooltipText}
          end
          to={url}
        >
          {children}
        </NavLink>
      );
    }

    return children;
  };

  return (
    <li className={classNames('navbar-item', className)}>
      <TooltipTrigger text={tooltipText}>{renderInnerElement()}</TooltipTrigger>
    </li>
  );
}

export default NavbarItem;
