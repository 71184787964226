import React from 'react';
import classNames from 'classnames';

import ListStub from 'src/components/common/list-stub';
import MobileCard from './components/mobile-card';

import type { MobileListProps } from './mobile-list.props';

import './mobile-list.scss';

const MobileList = ({
  isProcessing,
  isResetFilterDisabled,
  items,
  type,
  onResetFilter,
}: MobileListProps) => {
  const isEmpty = !isProcessing && !items.length;

  return (
    <ul className={classNames('mobile-list', !items.length && 'mobile-list_empty')}>
      {isEmpty ? (
        <li className="mobile-list__list-item">
          <ListStub isResetFilterDisabled={isResetFilterDisabled} onResetFilter={onResetFilter} />
        </li>
      ) : (
        items.map((item) => (
          <li key={item.id} className="mobile-list__list-item">
            <MobileCard item={item} type={type} />
          </li>
        ))
      )}
    </ul>
  );
};

export default MobileList;
