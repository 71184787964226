import { MutableRefObject, useEffect } from 'react';

export default function useOnClickOutside(
  ref: MutableRefObject<HTMLDivElement | null>,
  onClick: (e?: any) => void,
  isListenerStopped: boolean = false,
  targetException?: string
) {
  useEffect(() => {
    const listener = (e: any) => {
      if (
        !ref?.current ||
        ref.current.contains(e.target) ||
        (targetException && Boolean(e.target.closest(targetException)))
      ) {
        return;
      }
      onClick(e);
    };

    if (!isListenerStopped) {
      document.addEventListener('mousedown', listener);
    } else {
      document.removeEventListener('mousedown', listener);
    }

    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [ref, onClick, isListenerStopped, targetException]);
}
