import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Loader from 'src/components/common/loader';
import Button from 'src/components/ui/button';
import IconButton from 'src/components/ui/icon-button';
import Footer from './components/footer';
import MeshulamForm from './components/meshulam-form';
import PaymentReceipt from './components/payment-receipt';
import { ReactComponent as BankCardIcon } from 'src/assets/icons/bank-card.svg';
import { ReactComponent as BitLogoIcon } from 'src/assets/icons/bit-logo.svg';
import { ReactComponent as InstallmentsIcon } from 'src/assets/icons/installment.svg';
import { ReactComponent as ShareIcon } from 'src/assets/icons/share.svg';

import PaymentStore from 'src/stores/payment-store';
import popupStore from 'src/stores/popup-store';

import { useLanguage } from 'src/contexts';
import {
  ApiResponseStatus,
  AppRoute,
  Language,
  LocaleFormat,
  PaymentMethod,
  PaymentStatus,
  PopupName,
  PUBLIC_URL,
} from 'src/constants';
import { convertDate } from 'src/utils';

import './payment-page.scss';

const getPaidAt = (paidAt: string, language: Language) =>
  language === Language.ENGLISH ? convertDate(paidAt, LocaleFormat.he, LocaleFormat.en) : paidAt;

const PaymentPage = observer(() => {
  const { id = '' } = useParams();
  const { language, getText, texts } = useLanguage();
  const { INSTALLMENTS, INVOICE, PAID_ON, PAYMENT, PAY_WITH_BANK_CARD, PAY_WITH_BIT } = texts;
  const [store] = useState<PaymentStore>(() => new PaymentStore(id));
  const { isInstallments, isLoading, payment, paymentUrl, getPaymentUrl, setPaymentUrl } = store;
  const CardButtonIcon = isInstallments ? InstallmentsIcon : BankCardIcon;
  const cardButtonLabel = isInstallments ? INSTALLMENTS : PAY_WITH_BANK_CARD;

  const handleShowSharePopup = useCallback(() => {
    const shareData = {
      language,
      title: PAYMENT,
      url: `${PUBLIC_URL || window.location.origin}${AppRoute.PAYMENT}/${id}`,
    };

    popupStore.showPopup(PopupName.SHARE, shareData);
  }, [PAYMENT, id, language]);

  const handlePaymentButtonClick = useCallback(
    (paymentType: PaymentMethod.CREDIT_DEBIT_CARD | PaymentMethod.BIT) =>
      getPaymentUrl(paymentType, (status?: number, errorText?: string) => {
        const getErrorText = () => {
          if (status === ApiResponseStatus.BAD_REQUEST) {
            return language === Language.HEBREW && errorText ? errorText : texts.INVALID_PHONE;
          }

          return texts.SOMETHING_WENT_WRONG;
        };

        popupStore.showPopup(PopupName.WARN, {
          title: texts.PAYMENT_ERROR,
          text: getErrorText(),
        });
      }),
    [getPaymentUrl, texts, language]
  );

  if (isLoading || !payment) {
    return <Loader />;
  }

  return (
    <div className="payment-page">
      <div className="payment-page__wrapper">
        <div className="payment-page__heading">
          <div className="payment-page__title">
            <h1 className="payment-page__name">
              {PAYMENT} {payment.no}
            </h1>
            <IconButton
              className="payment-page__share-button"
              ariaLabel={getText('SHARE')}
              icon={<ShareIcon />}
              theme="square"
              onClick={handleShowSharePopup}
            />
          </div>
          <span className="payment-page__subtitle">
            {INVOICE} {payment.invoiceNo}
          </span>
        </div>

        {paymentUrl ? (
          <MeshulamForm
            paymentId={payment.id}
            paymentUrl={paymentUrl}
            setPaymentUrl={setPaymentUrl}
          />
        ) : (
          <>
            <PaymentReceipt payment={payment} />

            {payment.status === PaymentStatus.PAID ? (
              <span className="payment-page__paid">
                {PAID_ON} {getPaidAt(payment.paidAt, language)}
              </span>
            ) : (
              <div className="payment-page__actions">
                <Button
                  className="payment-page__button"
                  icon={<CardButtonIcon />}
                  label={cardButtonLabel}
                  size="big"
                  theme="filled"
                  onClick={() => handlePaymentButtonClick(PaymentMethod.CREDIT_DEBIT_CARD)}
                />
                <Button
                  className="payment-page__button"
                  icon={<BitLogoIcon />}
                  label={PAY_WITH_BIT}
                  size="big"
                  theme="filled"
                  onClick={() => handlePaymentButtonClick(PaymentMethod.BIT)}
                />
              </div>
            )}
          </>
        )}
      </div>

      <Footer />
    </div>
  );
});

export default PaymentPage;
