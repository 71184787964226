import React from 'react';

import type { SummaryProductListProps } from './summary-product-list.props';

import './summary-product-list.scss';

const SummaryProductList = ({ productItems }: SummaryProductListProps) => {
  return (
    <ul className="summary-product-list">
      {productItems.map((product, idx) => (
        <li key={`${product.name}_${idx}`} className="summary-product-list__item">
          <span className="summary-product-list__name">{product.name}</span>
        </li>
      ))}
    </ul>
  );
};

export default SummaryProductList;
