import type { IApiListInvoice, IListInvoice } from 'src/interfaces';

export const mapApiToInvoice = ({
  purchaser_email,
  purchaser_name,
  purchaser_phone,
  added_at,
  created_at,
  updated_at,
  ...rest
}: IApiListInvoice): IListInvoice => {
  return {
    ...rest,
    createdAt: created_at,
    purchaserEmail: purchaser_email,
    purchaserName: purchaser_name,
    purchaserPhone: purchaser_phone,
  };
};
