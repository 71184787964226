import { observer } from 'mobx-react-lite';

import InputSearch from 'src/components/ui/inputs/input-search';

import constantsStore from 'src/stores/constants-store';

import type { TopPanelProps } from './top-panel.props';

import './top-panel.scss';

const TopPanel = observer(
  ({ itemsStore, searchTitle, statistics: Statistics, subtitle = '', title }: TopPanelProps) => {
    const { searchString, setSearchString } = itemsStore;
    const { isLoading: isSellersLoading } = constantsStore;

    return (
      <div className="top-panel">
        <div className="top-panel__intro">
          <h1 className="top-panel__title">{title}</h1>
          {subtitle && <p className="top-panel__subtitle">{subtitle}</p>}
        </div>
        <div className="top-panel__data">
          <Statistics />
          <InputSearch
            isDisabled={isSellersLoading}
            placeholder={searchTitle}
            size="flattened"
            value={searchString}
            onChange={setSearchString}
          />
        </div>
      </div>
    );
  }
);

export default TopPanel;
