import { makeAutoObservable } from 'mobx';
import io, { Socket } from 'socket.io-client';

import { ApiRoute, AppRoute, InvoiceStatus } from 'src/constants';
import { getCurrentRoute } from 'src/utils';
import type { IApiDetailedInvoice, IPaymentsStatistics, IStatistics } from 'src/interfaces';

interface IStatisticsDisconnectListener {
  id: string;
  cb: (reason: Socket.DisconnectReason) => void;
}

interface IStatisticsErrorListener {
  id: string;
  cb: (error: Error) => void;
}

interface IStatisticsListener {
  id: string;
  cb: (statistics: IStatistics, skipUpdate: boolean) => void;
}

interface IPaymentsStatisticsListener {
  id: string;
  cb: (statistics: IPaymentsStatistics, skipUpdate: boolean) => void;
}

type TPaymentsStatisticsDisconnectListener = (reason: Socket.DisconnectReason) => void;

type TPaymentsStatisticsErrorListener = (error: Error) => void;

type TInvoiceListener = (invoice: IApiDetailedInvoice) => void;

interface IStatisticsSocketQuery {
  created_at: {
    from: string;
    to: string;
  };
  status: InvoiceStatus[];
}

interface IPaymentsStatisticsSocketQuery {
  paid_at: {
    from: string;
    to: string;
  };
  seller: string;
  invoice_status: InvoiceStatus[];
}

class SocketStore {
  init(token: string) {
    const currentRoute = getCurrentRoute(window.location.pathname);

    if (currentRoute.includes(AppRoute.PRINT_INVOICE)) {
      return;
    }

    this.setToken(token);
  }

  invoiceListener: TInvoiceListener | null = null;
  invoiceSocket: Socket | null = null;
  isFirstPaymentsStatisticsLoad: boolean = true;
  paymentsStatisticsListeners: IPaymentsStatisticsListener[] = [];
  paymentsStatisticsDisconnectListener: TPaymentsStatisticsDisconnectListener | null = null;
  paymentsStatisticsErrorListener: TPaymentsStatisticsErrorListener | null = null;
  paymentsStatisticsSocket: Socket | null = null;
  isFirstStatisticsLoad: boolean = true;
  statisticsDisconnectListeners: IStatisticsDisconnectListener[] = [];
  statisticsErrorListeners: IStatisticsErrorListener[] = [];
  statisticsListeners: IStatisticsListener[] = [];
  statisticsSocket: Socket | null = null;
  token: string = '';

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setInvoicesListener(listener: TInvoiceListener | null) {
    this.invoiceListener = listener;
  }

  setInvoiceSocket(socket: Socket | null) {
    this.invoiceSocket = socket;
  }

  setIsFirstPaymentsStatisticsLoad(isFirst: boolean) {
    this.isFirstPaymentsStatisticsLoad = isFirst;
  }

  setPaymentsStatisticsListeners(listeners: IPaymentsStatisticsListener[]) {
    this.paymentsStatisticsListeners = listeners;
  }

  addPaymentsStatisticsListener(listener: IPaymentsStatisticsListener) {
    if (!this.paymentsStatisticsListeners.length) {
      this.paymentsStatisticsListeners.push(listener);
      return;
    }

    const listenerIdx = this.paymentsStatisticsListeners.findIndex(
      (item) => item.id === listener.id
    );

    if (listenerIdx === -1) {
      this.paymentsStatisticsListeners.push(listener);
    } else {
      this.paymentsStatisticsListeners.splice(listenerIdx, 1, listener);
    }
  }

  removePaymentsStatisticsListener(listenerId: string) {
    this.paymentsStatisticsListeners = this.paymentsStatisticsListeners.filter(
      (item) => item.id !== listenerId
    );
  }

  setPaymentsStatisticsDisconnectListener(listener: TPaymentsStatisticsDisconnectListener | null) {
    this.paymentsStatisticsDisconnectListener = listener;
  }

  setPaymentsStatisticsErrorListener(listener: TPaymentsStatisticsErrorListener | null) {
    this.paymentsStatisticsErrorListener = listener;
  }

  setPaymentsStatisticsSocket(socket: Socket | null) {
    this.paymentsStatisticsSocket = socket;
  }

  setIsFirstStatisticsLoad(isFirst: boolean) {
    this.isFirstStatisticsLoad = isFirst;
  }

  setStatisticsDisconnectListeners(listeners: IStatisticsDisconnectListener[]) {
    this.statisticsDisconnectListeners = listeners;
  }

  setStatisticsErrorListeners(listeners: IStatisticsErrorListener[]) {
    this.statisticsErrorListeners = listeners;
  }

  setStatisticsListeners(listeners: IStatisticsListener[]) {
    this.statisticsListeners = listeners;
  }

  addStatisticsListener(listener: IStatisticsListener) {
    if (!this.statisticsListeners.length) {
      this.statisticsListeners.push(listener);
      return;
    }

    const listenerIdx = this.statisticsListeners.findIndex((item) => item.id === listener.id);

    if (listenerIdx === -1) {
      this.statisticsListeners.push(listener);
    } else {
      this.statisticsListeners.splice(listenerIdx, 1, listener);
    }
  }

  removeStatisticsListener(listenerId: string) {
    this.statisticsListeners = this.statisticsListeners.filter((item) => item.id !== listenerId);
  }

  addStatisticsErrorListener(listener: IStatisticsErrorListener) {
    if (!this.statisticsErrorListeners.length) {
      this.statisticsErrorListeners.push(listener);
      return;
    }

    const listenerIdx = this.statisticsErrorListeners.findIndex((item) => item.id === listener.id);

    if (listenerIdx === -1) {
      this.statisticsErrorListeners.push(listener);
    } else {
      this.statisticsErrorListeners.splice(listenerIdx, 1, listener);
    }
  }

  removeStatisticsErrorListener(listenerId: string) {
    this.statisticsErrorListeners = this.statisticsErrorListeners.filter(
      (item) => item.id !== listenerId
    );
  }

  addStatisticsDisconnectListener(listener: IStatisticsDisconnectListener) {
    if (!this.statisticsDisconnectListeners.length) {
      this.statisticsDisconnectListeners.push(listener);
      return;
    }

    const listenerIdx = this.statisticsDisconnectListeners.findIndex(
      (item) => item.id === listener.id
    );

    if (listenerIdx === -1) {
      this.statisticsDisconnectListeners.push(listener);
    } else {
      this.statisticsDisconnectListeners.splice(listenerIdx, 1, listener);
    }
  }

  removeStatisticsDisconnectListener(listenerId: string) {
    this.statisticsDisconnectListeners = this.statisticsDisconnectListeners.filter(
      (item) => item.id !== listenerId
    );
  }

  setStatisticsSocket(socket: Socket | null) {
    this.statisticsSocket = socket;
  }

  setToken(token: string) {
    this.token = token;
  }

  createSocket<T, EventName extends string = 'data'>(url: string, query?: Record<string, any>) {
    const token = this.token;
    if (!token) {
      return;
    }

    const socketOptions = {
      auth: {
        token,
      },
      reconnectionAttempts: 3,
      reconnectionDelay: 1000,
      forceNew: true,
      ...(query && { query }),
    };

    const socket: Socket<{ [event in EventName]: (data: T) => void }> = io(
      `${getApiBaseUrl()}/${url}`,
      socketOptions
    );

    return socket;
  }

  subscribeToManagerStatistics(filter: IStatisticsSocketQuery) {
    this.unsubscribeFromStatistics();

    const filterString = JSON.stringify(filter);
    const socket = this.createSocket<IStatistics, `data-${string}`>(
      ApiRoute.WEB_SOCKET_MANAGER_STATISTICS,
      {
        filter: filterString,
      }
    );

    if (!socket) {
      return;
    }

    socket.on('connect', () => {
      console.info('manager stats ws connected');
    });

    socket.on('connect_error', (error) => {
      console.info('manager stats connect error', error);
      this.statisticsErrorListeners.forEach((listener) => listener.cb(error));
    });

    socket.on('disconnect', (reason) => {
      console.info('manager stats ws disconnected');
      this.statisticsDisconnectListeners.forEach((listener) => listener.cb(reason));
    });

    socket.on(`data-${filterString}`, (statistics) => {
      this.statisticsListeners.forEach((listener) =>
        listener.cb(statistics, this.isFirstStatisticsLoad)
      );

      if (this.isFirstStatisticsLoad) {
        this.setIsFirstStatisticsLoad(false);
      }
    });

    this.setStatisticsSocket(socket);
  }

  subscribeToSellerStatistics(sellerId: string, filter: IStatisticsSocketQuery) {
    this.unsubscribeFromStatistics();

    const filterString = JSON.stringify(filter);
    const socket = this.createSocket<IStatistics, `data-${string}`>(
      ApiRoute.WEB_SOCKET_SELLER_STATISTICS(sellerId),
      {
        filter: filterString,
      }
    );

    if (!socket) {
      return;
    }

    socket.on('connect', () => {
      console.info('seller stats ws connected');
    });

    socket.on('connect_error', (error) => {
      console.info('seller stats connect error', error);
      this.statisticsErrorListeners.forEach((listener) => listener.cb(error));
    });

    socket.on('disconnect', (reason) => {
      console.info('seller stats ws disconnected');
      this.statisticsDisconnectListeners.forEach((listener) => listener.cb(reason));
    });

    socket.on(`data-${filterString}`, (statistics) => {
      this.statisticsListeners.forEach((listener) =>
        listener.cb(statistics, this.isFirstStatisticsLoad)
      );

      if (this.isFirstStatisticsLoad) {
        this.setIsFirstStatisticsLoad(false);
      }
    });

    this.setStatisticsSocket(socket);
  }

  subscribeToPaymentsStatistics(filter: IPaymentsStatisticsSocketQuery) {
    this.unsubscribeFromPaymentsStatistics();

    const filterString = JSON.stringify(filter);
    const socket = this.createSocket<IPaymentsStatistics, `data-${string}`>(
      ApiRoute.WEB_SOCKET_PAYMENTS_TOTAL,
      {
        filter: filterString,
      }
    );

    if (!socket) {
      return;
    }

    socket.on('connect', () => {
      console.info('payments stats ws connected');
    });

    socket.on('connect_error', (error) => {
      console.info('payments stats connect error', error);
      this.paymentsStatisticsErrorListener?.(error);
    });

    socket.on('disconnect', (reason) => {
      console.info('payments stats ws disconnected', reason);
      this.paymentsStatisticsDisconnectListener?.(reason);
    });

    socket.on(`data-${filterString}`, (statistics) => {
      this.paymentsStatisticsListeners.forEach((listener) =>
        listener.cb(statistics, this.isFirstPaymentsStatisticsLoad)
      );

      if (this.isFirstPaymentsStatisticsLoad) {
        this.setIsFirstPaymentsStatisticsLoad(false);
      }
    });

    this.setPaymentsStatisticsSocket(socket);
  }

  subscribeToInvoice(invoiceId: string) {
    const last4Chars = invoiceId.slice(-4);
    const socket = this.createSocket<IApiDetailedInvoice>(ApiRoute.WEB_SOCKET_INVOICE(invoiceId));
    if (!socket) {
      return;
    }

    socket.on('connect', () => {
      console.info(`invoice *${last4Chars} ws connected`);
    });

    socket.on('connect_error', () => {
      console.info(`invoice *${last4Chars} ws connect error`);
    });

    socket.on('disconnect', () => {
      console.info(`invoice *${last4Chars} ws disconnected`);
    });

    socket.on('data', (invoice) => {
      this.invoiceListener?.(invoice);
    });

    this.setInvoiceSocket(socket);
  }

  unsubscribeFromInvoice() {
    if (this.invoiceSocket) {
      this.invoiceSocket.disconnect();
      this.setInvoiceSocket(null);
    }
  }

  unsubscribeFromStatistics() {
    if (this.statisticsSocket) {
      this.statisticsSocket.disconnect();
      this.setStatisticsSocket(null);
    }
    this.setIsFirstStatisticsLoad(true);
  }

  unsubscribeFromPaymentsStatistics() {
    if (this.paymentsStatisticsSocket) {
      this.paymentsStatisticsSocket.disconnect();
      this.setPaymentsStatisticsSocket(null);
    }
    this.setIsFirstPaymentsStatisticsLoad(true);
  }

  disconnect() {
    this.unsubscribeFromStatistics();
    this.unsubscribeFromPaymentsStatistics();
    this.unsubscribeFromInvoice();
  }

  clear() {
    this.disconnect();
    this.setStatisticsDisconnectListeners([]);
    this.setStatisticsErrorListeners([]);
    this.setStatisticsListeners([]);
    this.setPaymentsStatisticsListeners([]);
    this.setInvoicesListener(null);
    this.setToken('');
  }
}

function getApiBaseUrl() {
  try {
    return new URL(window.API_URL).origin;
  } catch {
    console.error('Invalid url in Window.API_URL');
    return window.API_URL;
  }
}

export default new SocketStore();
