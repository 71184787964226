import React from 'react';

import { useLanguage } from 'src/contexts';
import { formatMoney } from 'src/utils';
import type { PaymentReceiptProps } from './payment-receipt.props';

import './payment-receipt.scss';

const PaymentReceipt = ({ payment }: PaymentReceiptProps) => {
  const { amount, balance, installments, items, purchaser, total } = payment;
  const { texts } = useLanguage();

  const {
    CURRENT_PAYMENT,
    EMAIL,
    NAME,
    NUMBER_OF_PAYMENTS,
    PHONE,
    PRODUCTS_AND_SERVICES,
    PURCHASER_DETAILS,
    REMAINING_BALANCE,
    TOTAL_AMOUNT,
  } = texts;

  return (
    <div className="payment-receipt">
      <div className="payment-receipt__block payment-receipt__block_with-border">
        <h2 className="payment-receipt__title">{PURCHASER_DETAILS}</h2>
        <div className="payment-receipt__content">
          <div className="payment-receipt__info payment-receipt__info_row">
            <span className="payment-receipt__name">{NAME}:</span>
            <span className="payment-receipt__value">{purchaser.name}</span>
          </div>

          <div className="payment-receipt__info payment-receipt__info_row">
            <span className="payment-receipt__name">{PHONE}:</span>
            <span className="payment-receipt__value">{purchaser.phone}</span>
          </div>

          <div className="payment-receipt__info payment-receipt__info_row">
            <span className="payment-receipt__name">{EMAIL}:</span>
            <span className="payment-receipt__value">{purchaser.email || '-'}</span>
          </div>
        </div>
      </div>

      <div className="payment-receipt__block payment-receipt__block_with-border">
        <h2 className="payment-receipt__title">{PRODUCTS_AND_SERVICES}</h2>
        <div className="payment-receipt__content">
          {items.map((item, idx) => (
            <span key={idx} className="payment-receipt__name payment-receipt__name_product">
              {item.name}
            </span>
          ))}
        </div>
      </div>

      <div className="payment-receipt__block">
        <div className="payment-receipt__content">
          <div className="payment-receipt__info">
            <span className="payment-receipt__name payment-receipt__name_total">
              {CURRENT_PAYMENT}:
            </span>
            <span className="payment-receipt__value payment-receipt__value_total">
              {formatMoney(amount, false, true)}
            </span>
          </div>

          <div className="payment-receipt__info">
            <span className="payment-receipt__name payment-receipt__name_total">
              {NUMBER_OF_PAYMENTS}:
            </span>
            <span className="payment-receipt__value payment-receipt__value_total">
              {installments}
            </span>
          </div>

          <div className="payment-receipt__info">
            <span className="payment-receipt__name payment-receipt__name_total">
              {TOTAL_AMOUNT}:
            </span>
            <span className="payment-receipt__value payment-receipt__value_total">
              {formatMoney(total, false, true)}
            </span>
          </div>

          <div className="payment-receipt__info">
            <span className="payment-receipt__name payment-receipt__name_total">
              {REMAINING_BALANCE}:
            </span>
            <span className="payment-receipt__value payment-receipt__value_total">
              {formatMoney(balance, false, true)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentReceipt;
