import { FC, useRef } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import PopupConfirm from '../popup-confirm';
import PopupCreatePayment from '../popup-create-payment';
import PopupPaymentStatus from '../popup-payment-status';
import PopupPaymentsFilters from '../popup-payments-filters';
import PopupRecordPayment from '../popup-record-payment';
import PopupSalesFilters from '../popup-sales-filters';
import PopupShare from '../popup-share';
import PopupWarn from '../popup-warn';

import popupStore from 'src/stores/popup-store';

import useIsTablet from 'src/hooks/use-is-tablet';
import useKeyUpGlobal from 'src/hooks/use-key-up-global';
import useMobilePopup from 'src/hooks/use-mobile-popup';
import useOnClickOutside from 'src/hooks/use-on-click-outside';
import useUnmountAnimation from 'src/hooks/use-unmount-animation';
import useWindowScrollBlock from 'src/hooks/use-window-scroll-block';
import { Key, PopupName } from 'src/constants';
import type { ICommonPopupProps } from 'src/interfaces';
import type { PopupProps } from './popup.props';

import './popup.scss';

const POPUP_COMPONENTS: Record<PopupName, FC<ICommonPopupProps>> = {
  [PopupName.CONFIRM]: PopupConfirm,
  [PopupName.CREATE_PAYMENT]: PopupCreatePayment,
  [PopupName.SALES_FILTERS]: PopupSalesFilters,
  [PopupName.PAYMENTS_FILTERS]: PopupPaymentsFilters,
  [PopupName.PAYMENT_STATUS]: PopupPaymentStatus,
  [PopupName.RECORD_PAYMENT]: PopupRecordPayment,
  [PopupName.SHARE]: PopupShare,
  [PopupName.WARN]: PopupWarn,
};

const Popup = observer(({ popupName }: PopupProps) => {
  const popupRef = useRef<HTMLDivElement>(null);
  const isTablet = useIsTablet();

  const { className, isVisible, onClose } = popupStore.getPopupState(popupName);

  const closePopup = () => {
    popupStore.hidePopup(popupName);
    onClose?.();
  };

  useMobilePopup(popupRef, closePopup, isTablet);
  const { isUnmounting, onAnimationEnd, unmountComponent } = useUnmountAnimation(closePopup);
  useOnClickOutside(popupRef, unmountComponent, false, '.flatpickr-calendar');
  useKeyUpGlobal(Key.ESCAPE, (evt) => {
    evt.stopPropagation();
    unmountComponent();
  });
  useWindowScrollBlock(true);

  const PopupComponent = POPUP_COMPONENTS[popupName] || null;

  return (
    <div
      className={classNames(
        'popup',
        className,
        isUnmounting && 'popup_hiding',
        `popup_${popupName}`,
        'scrollbar'
      )}
    >
      <div className="popup__shadow"></div>
      <div className="popup__wrapper scrollbar" ref={popupRef} onAnimationEnd={onAnimationEnd}>
        {isVisible && <PopupComponent isMobile={!isTablet} onCloseButtonClick={unmountComponent} />}
      </div>
    </div>
  );
});

export default Popup;
