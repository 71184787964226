import React from 'react';

import CopyToClipboardButton from '../copy-to-clipboard-button';
import { ReactComponent as CopyIcon } from 'src/assets/icons/share/copy.svg';

import { Language, Locale } from 'src/constants';
import type { ShareButtonConfigProps, ShareButtonProps, ShareButtonType } from 'src/interfaces';

import './share-button.scss';

function ShareButton({
  language = Language.ENGLISH,
  title,
  type,
  url,
}: ShareButtonProps<ShareButtonType>) {
  const { button: Button, color, icon: Icon, label } = getShareButtons(language, type);
  return (
    <div className="share-button">
      <Button language={language} title={title} url={url}>
        <span className="share-button__icon" style={{ backgroundColor: color }}>
          <Icon />
        </span>
      </Button>
      <div className="share-button__label">{label}</div>
    </div>
  );
}

function getShareButtons(language: Language, type: ShareButtonType) {
  const texts = Locale[language];

  const SHARE_BUTTONS: Record<ShareButtonType, ShareButtonConfigProps> = {
    copy: {
      label: texts.COPY_URL,
      button: CopyToClipboardButton,
      icon: CopyIcon,
      color: '#808080',
    },
  };

  return SHARE_BUTTONS[type];
}

export default ShareButton;
