import React from 'react';
import { observer } from 'mobx-react-lite';

import Loader from 'src/components/common/loader';
import Button from 'src/components/ui/button';
import Input from 'src/components/ui/inputs/input';
import InputDatePicker from 'src/components/ui/inputs/input-date-picker';
import Radiobox from 'src/components/ui/radiobox';

import popupStore from 'src/stores/popup-store';

import useMouseStopPropagation from 'src/hooks/use-mouse-stop-propagation';
import { ErrorText, PaymentMethod, PaymentMethodLabel, PopupName, PopupTitle } from 'src/constants';
import { checkDateValid, getCurrentDate, isDateInFuture, parseStringToNumber } from 'src/utils';
import type { ICommonPopupProps, TRecordPaymentMethod } from 'src/interfaces';

// WARN: DO NOT TEST RECORD PROCESS ON DEV!
const PopupRecordPayment = observer(({ isMobile, onCloseButtonClick }: ICommonPopupProps) => {
  const { maxAmount = 0, onRecordPayment } = popupStore.getPopupState(PopupName.RECORD_PAYMENT);
  const [amount, setAmount] = React.useState<number>(maxAmount);
  const [method, setMethod] = React.useState<TRecordPaymentMethod>(PaymentMethod.CHECK);
  const [date, setDate] = React.useState<string>(getCurrentDate);
  const [dateError, setDateError] = React.useState<string>('');
  const [isProcessing, setProcessing] = React.useState<boolean>(false);
  const { toggleMouseListener } = useMouseStopPropagation();
  const isDateValid = checkDateValid(date) && !isDateInFuture(date);
  const isFormValid = !!amount && isDateValid;

  const paymentMethods: TRecordPaymentMethod[] = [
    PaymentMethod.CHECK,
    PaymentMethod.BANK_TRANSFER,
    PaymentMethod.CASH,
    PaymentMethod.BALANCE,
  ];

  const handleChangeInput = (value: string) => {
    const newAmount = parseStringToNumber(value);
    setAmount(Math.min(newAmount, maxAmount));
  };

  const handleBlurDate = () => {
    setDateError(isDateValid ? '' : ErrorText.DATE_IN_FUTURE);
  };

  // WARN: DO NOT TEST ON DEV!
  const handleClickRecordPaymentButton = async () => {
    setProcessing(true);
    await onRecordPayment({
      amount,
      method,
      date,
    });
    setProcessing(false);
  };

  return (
    <>
      <div className="popup__main">
        <p className="popup__title">{PopupTitle.RECORD_PAYMENT}</p>

        <Input
          isDisabled={isProcessing}
          label="Payment Amount, ₪"
          value={`${amount}`}
          onChange={handleChangeInput}
        />
      </div>

      <div className="popup__content">
        <div className="popup__method">
          <div className="popup__method-title">Payment Method</div>
          <div className="popup__method-list">
            {paymentMethods.map((paymentType, idx) => (
              <Radiobox<TRecordPaymentMethod>
                key={paymentType}
                idx={idx}
                isChecked={paymentType === method}
                isDisabled={isProcessing}
                label={PaymentMethodLabel[paymentType]}
                name="payment-method"
                value={paymentType}
                onChange={setMethod}
              />
            ))}
          </div>
        </div>

        <InputDatePicker
          disableNextDates
          isDisabled={isProcessing}
          isValid={!dateError}
          keepHorizontalLeftPosition
          label="Payment Date"
          textError={dateError}
          value={date}
          onBlur={handleBlurDate}
          onCalendarVisibilityChange={toggleMouseListener}
          onChange={setDate}
        />
      </div>

      <div className="popup__buttons">
        <Button
          isDisabled={isProcessing}
          label="Cancel"
          size={isMobile ? 'regular' : 'big'}
          theme="outlined"
          onClick={onCloseButtonClick}
        />

        <Button
          icon={isProcessing ? <Loader isInline size="small" /> : null}
          isDisabled={!isFormValid || isProcessing}
          label={isProcessing ? 'Loading...' : 'Record a Payment'}
          size={isMobile ? 'regular' : 'big'}
          theme="filled"
          // WARN: DO NOT TEST ON DEV!
          onClick={handleClickRecordPaymentButton}
        />
      </div>
    </>
  );
});

export default PopupRecordPayment;
