import { makeAutoObservable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

import socketStore from 'src/stores/socket-store';

import type { IPaymentsStatistics } from 'src/interfaces';

const SOCKET_LISTENER_ID = uuidv4();

class PaidStatisticsStore {
  init() {
    socketStore.addPaymentsStatisticsListener({
      id: SOCKET_LISTENER_ID,
      cb: this.setPaymentsStatistics,
    });
    socketStore.setPaymentsStatisticsDisconnectListener(this.handleSocketDisconnect);
    socketStore.setPaymentsStatisticsErrorListener(this.handleSocketError);
  }

  paidStatistics?: number;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setPaymentsStatistics(statistics: IPaymentsStatistics | null) {
    this.paidStatistics = statistics?.sum;
  }

  handleSocketDisconnect() {
    this.setPaymentsStatistics(null);
  }

  handleSocketError() {
    this.setPaymentsStatistics(null);
  }

  clear() {
    this.setPaymentsStatistics(null);
    socketStore.unsubscribeFromPaymentsStatistics();
  }
}

export default new PaidStatisticsStore();
