import React from 'react';

import Button from 'src/components/ui/button';
import { ReactComponent as NotFoundIcon } from 'src/assets/icons/not-found-icon.svg';

import { useLanguage } from 'src/contexts';
import { AppRoute } from 'src/constants';

import './not-found-page.scss';

const NotFoundPage = () => {
  const { texts } = useLanguage();
  const { PAGE_NOT_FOUND, CHECK_THE_PAGE_ADDRESS, GO_TO_MAIN_PAGE } = texts;

  return (
    <div className="not-found-page">
      <div className="not-found-page__container">
        <NotFoundIcon className="not-found-page__icon" />
        <div className="not-found-page__info">
          <div className="not-found-page__text-wrapper">
            <h1 className="not-found-page__title">{PAGE_NOT_FOUND}</h1>
            <p className="not-found-page__subtitle">{CHECK_THE_PAGE_ADDRESS}</p>
          </div>

          <Button
            className="not-found-page__button"
            href={AppRoute.INVOICES}
            label={GO_TO_MAIN_PAGE}
            tag="link"
            size="big"
            theme="outlined"
          />
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
