import React from 'react';

import Header from 'src/components/common/header';
import PaidStatistics from 'src/components/common/paid-statistics';

import paymentsStore from 'src/stores/payments-store';

import { Title } from 'src/constants';

const PaymentsHeader = () => (
  <Header
    itemsStore={paymentsStore}
    searchTitle={Title.SEARCH_INPUT_PAYMENTS}
    statistics={PaidStatistics}
    title={Title.PAYMENT_PAGE}
  />
);

export default PaymentsHeader;
