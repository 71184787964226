export const HE_LOCALES = {
  CEMETERY_WEBSITE: 'אתר החברה',
  CHECK_THE_PAGE_ADDRESS: 'בדוק את כתובת הדף', // value from Google Translate
  CLOSE: 'לשחרר',
  COPIED: 'הועתק',
  COPY_URL: 'העתק קישור',
  CURRENT_PAYMENT: 'לתשלום',
  DOWNLOAD_FREE_MOBILE_APP: 'הורד את האפליקציה בחינם לנייד',
  EMAIL: 'אימייל',
  GO_TO_MAIN_PAGE: 'עבור לדף הראשי', // value from Google Translate
  GO_TO_PAYMENT_PAGE: 'עבור לדף תשלום', // value from Google Translate
  GO_TO_X_PAGE: '{X} עבור לדף',
  INDEX_PAGE: 'אינדקס',
  INVALID_PHONE: 'טלפון לא חוקי', // value from Google Translate
  INSTALLMENTS: 'תשלומים',
  INVOICE: 'חשבונית', // value from Google Translate
  NAME: 'שם מלא',
  NUMBER_OF_PAYMENTS: 'מספר תשלומים',
  PAGE_LINK_HAS_BEEN_COPIED: 'הקישור לעמוד הועתק ללוח',
  PAGE_NOT_FOUND: 'העמוד לא נמצא',
  PAID_ON: 'שילם עבור', // value from Google Translate
  PAY_WITH_BANK_CARD: 'כרטיס אשראי',
  PAY_WITH_BIT: 'Bit',
  PAYMENT: 'תשלום',
  PAYMENT_ERROR: 'שגיאה בתשלום',
  PAYMENT_FAILED: 'התשלום נכשל', // value from Google Translate
  PAYMENT_FORM_EXPIRED: 'טופס התשלום פג תוקף',
  PAYMENT_SUCCESSFUL: 'התשלום הצליח', // value from Google Translate
  PHONE: 'טלפון',
  PLEASE_CONTACT_MANAGER: 'נא לפנות למנהל', // value from Google Translate
  PRIVACY_POLICY: 'מדיניות פרטיות ותנאי השימוש',
  PRODUCTS_AND_SERVICES: 'מוצרים ושירותים', // value from Google Translate
  PURCHASER_DETAILS: 'הלקוח',
  REMAINING_BALANCE: 'יתרה',
  SHARE: 'שתף',
  SHARE_PAYMENT_PAGE: 'שתף דף תשלום', // value from Google Translate
  SOMETHING_WENT_WRONG: 'משהו השתבש',
  TOTAL_AMOUNT: 'סה"כ הזמנה',
  YOU_CAN_NOW_CLOSE_THIS_PAGE: 'עכשיו אתה יכול לסגור את הדף הזה', // value from Google Translate
};
