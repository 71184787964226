import React from 'react';
import { observer } from 'mobx-react-lite';

import Financials from 'src/components/common/financials';
import InfoBlock from 'src/components/common/info-block';
import SidebarPaymentsTable from 'src/components/common/sidebar-payments-table';
import Button from 'src/components/ui/button';

import invoiceDetailsStore from 'src/stores/invoice-details-store';

import { InvoiceStatus } from 'src/constants';
import type { AccountTabSectionProps } from './account-tab-section.props';

import './account-tab-section.scss';

const AccountTabSection = observer(({ invoice, isDesktop }: AccountTabSectionProps) => {
  const { handleClickCreatePaymentButton, handleClickRecordPaymentButton } = invoiceDetailsStore;
  const { balance, paid, payments, total } = invoice;
  const isActionButtonsDisabled = invoice.status === InvoiceStatus.CANCELED;
  const isInvoiceButtonsDisabled = isActionButtonsDisabled || !balance;

  return (
    <section className="account-tab-section">
      <InfoBlock isTitleNoUnderline={!isDesktop} title="Invoice Details" withGap>
        <Financials
          balance={balance}
          isShortTitles={!isDesktop}
          location="sidebar"
          paid={paid}
          total={total}
        />
      </InfoBlock>

      {!!payments.length && (
        <InfoBlock isPayments title="Payments">
          <SidebarPaymentsTable
            paymentItems={payments}
            isActionButtonsDisabled={isActionButtonsDisabled}
          />
        </InfoBlock>
      )}

      <div className="account-tab-section__buttons-wrapper">
        <Button
          isDisabled={isInvoiceButtonsDisabled}
          label="Create Payment Page"
          size={isDesktop ? 'big' : 'regular'}
          theme="filled"
          onClick={handleClickCreatePaymentButton}
        />

        <Button
          isDisabled={isInvoiceButtonsDisabled}
          label="Record a Payment"
          size={isDesktop ? 'big' : 'regular'}
          theme="filled"
          onClick={handleClickRecordPaymentButton}
        />
      </div>
    </section>
  );
});

export default AccountTabSection;
