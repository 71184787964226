import { observer } from 'mobx-react-lite';

import Button from 'src/components/ui/button';
import IconButton from 'src/components/ui/icon-button';
import { ReactComponent as DownloadIcon } from 'src/assets/icons/download-doc.svg';
import { ReactComponent as FilterIcon } from 'src/assets/icons/filter.svg';
import { ReactComponent as SearchIcon } from 'src/assets/icons/search.svg';

import constantsStore from 'src/stores/constants-store';
import paymentsStore from 'src/stores/payments-store';
import popupStore from 'src/stores/popup-store';
import searchWindowStore from 'src/stores/search-window-store';

import { PopupName, Title } from 'src/constants';

import './payments-mobile-filters.scss';

const PaymentsMobileFilters = observer(() => {
  const { isLoading } = constantsStore;
  const { exportPayments, isDownloading, items } = paymentsStore;
  const handleFilterBtnClick = () => popupStore.showPopup(PopupName.PAYMENTS_FILTERS);

  return (
    <section className="payments-mobile-filters">
      <Button
        icon={<FilterIcon />}
        label="Filters"
        theme="outlined"
        onClick={handleFilterBtnClick}
      />
      <Button
        icon={<DownloadIcon />}
        isDisabled={isDownloading || !items.length || isLoading}
        label="XLS"
        theme="outlined"
        onClick={exportPayments}
      />
      <IconButton
        className="payments-mobile-filters__search-button"
        ariaLabel={Title.SEARCH_INPUT_PAYMENTS}
        icon={<SearchIcon />}
        onClick={searchWindowStore.show}
      />
    </section>
  );
});

export default PaymentsMobileFilters;
