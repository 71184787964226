import { observer } from 'mobx-react-lite';

import IconButton from 'src/components/ui/icon-button';
import MenuItem from '../menu-item';
import { ReactComponent as ExitIcon } from 'src/assets/icons/exit.svg';

import globalAppStore from 'src/stores/global-app-store';

import { NAVIGATION_LINKS } from 'src/constants';
import type { MobileMenuProps } from './mobile-menu.props';

import './mobile-menu.scss';

const MobileMenu = observer(({ userName }: MobileMenuProps) => {
  return (
    <div className="mobile-menu">
      <ul className="mobile-menu__items">
        {NAVIGATION_LINKS.map(({ label, icon: Icon, url }) => (
          <MenuItem linkProps={{ url }} label={label} key={label}>
            <Icon />
          </MenuItem>
        ))}
      </ul>
      <div className="mobile-menu__footer">
        <p className="mobile-menu__intro">
          Welcome,
          <br />
          <span className="mobile-menu__intro-name">{userName}</span>
        </p>
        <div className="mobile-menu__actions">
          <IconButton
            className="mobile-menu__button"
            ariaLabel="Logout"
            icon={<ExitIcon />}
            onClick={globalAppStore.logout}
          />
        </div>
      </div>
    </div>
  );
});

export default MobileMenu;
