import React from 'react';
import { observer } from 'mobx-react-lite';

import Financials from '../financials';

import statisticsStore from 'src/stores/statistics-store';

const FinancialsStatistics = observer(() => {
  const { balance, paid, total } = statisticsStore.statistics ?? {};

  return <Financials balance={balance} isShortTitles location="main" paid={paid} total={total} />;
});

export default FinancialsStatistics;
