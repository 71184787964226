import { observer } from 'mobx-react-lite';

import GoogleLogin from 'src/components/common/google-login';
import Loader from 'src/components/common/loader';
import TextError from 'src/components/ui/text-error';

import authStore from 'src/stores/auth-store';

import { GoogleOAuthProvider } from 'src/contexts';
import useIsTablet from 'src/hooks/use-is-tablet';
import { Title } from 'src/constants';

import './login-page.scss';

const LoginPage = observer(() => {
  const { authError, isLoading, authenticateUser, setGoogleAuthError } = authStore;
  const isTablet = useIsTablet();

  return (
    <GoogleOAuthProvider clientId={window.GOOGLE_OAUTH_CLIENT_ID}>
      <div className="login-page">
        {isLoading && <Loader isDebounce position="overlay" />}
        <div className="login-page__container">
          <div className="login-page__content">
            <img
              className="login-page__logo"
              src="/logos/logo-big-black.svg"
              width={177}
              height={255}
              alt="Bezikaron logo"
            />
            <div className="login-page__info-container">
              <div className="login-page__text-wrapper">
                <h1 className="login-page__title">{Title.MAIN}</h1>
                <span className="login-page__text">Please sign in to continue</span>
              </div>
              <GoogleLogin
                size="large"
                width={isTablet ? 400 : undefined}
                onSuccess={authenticateUser}
                onError={setGoogleAuthError}
              />
              {authError && <TextError textError={authError} />}
            </div>
          </div>
        </div>
      </div>
    </GoogleOAuthProvider>
  );
});

export default LoginPage;
