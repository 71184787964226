import React from 'react';
import { observer } from 'mobx-react-lite';

import DescriptionList from 'src/components/common/description-list';
import ExternalLink from 'src/components/common/external-link';
import InfoBlock from 'src/components/common/info-block';
import Status from 'src/components/common/status';
import SummaryProductList from 'src/components/common/summary-product-list';
import Button from 'src/components/ui/button';
import CrmUrl from '../crm-url';

import globalAppStore from 'src/stores/global-app-store';
import invoiceDetailsStore from 'src/stores/invoice-details-store';
import popupStore from 'src/stores/popup-store';

import { InvoiceStatus, PopupName, PopupText, PopupTitle } from 'src/constants';
import { formatApiToPhone } from 'src/utils';
import type { SummaryTabSectionProps } from './summary-tab-section.props';

import './summary-tab-section.scss';

const SummaryTabSection = observer(({ invoice, isDesktop }: SummaryTabSectionProps) => {
  const { isManager } = globalAppStore;
  const { handleCancelInvoice } = invoiceDetailsStore;
  const { crmUrl, items, purchaser, status } = invoice;

  const summaryData = [
    {
      key: 'Name',
      value: purchaser.name,
    },
    {
      key: 'Phone',
      value: formatApiToPhone(purchaser.phone),
    },
    {
      key: 'Email',
      value: purchaser.email ? (
        <ExternalLink href={`mailto:${purchaser.email}`} isDecorated>
          {purchaser.email}
        </ExternalLink>
      ) : (
        '-'
      ),
    },
  ];

  return (
    <section className="summary-tab-section">
      <InfoBlock title={isDesktop ? '' : 'Invoice'} withGap>
        <DescriptionList
          pairData={[
            ...(isDesktop
              ? []
              : [
                  {
                    key: 'Status',
                    value: <Status type={status} />,
                  },
                ]),
            {
              className: 'description-list__item_crm',
              key: 'CRM URL',
              value: <CrmUrl url={crmUrl} />,
            },
          ]}
        />
      </InfoBlock>

      <InfoBlock title="Purchaser" withGap>
        <DescriptionList pairData={summaryData} />
      </InfoBlock>

      <InfoBlock title="Products & Services">
        <SummaryProductList productItems={items} />
      </InfoBlock>

      {isManager && (
        <div className="summary-tab-section__button-wrapper">
          <Button
            className="summary-tab-section__button"
            isDisabled={invoice.status === InvoiceStatus.CANCELED}
            label="Cancel Invoice"
            size={isDesktop ? 'big' : 'regular'}
            theme="outlined"
            onClick={() => {
              popupStore.showPopup(PopupName.CONFIRM, {
                mainActionLabel: 'Yes, cancel',
                secondaryActionLabel: 'No',
                text: PopupText.CANCEL_INVOICE,
                title: PopupTitle.CANCEL_INVOICE,
                mainActionHandler: handleCancelInvoice,
              });
            }}
          />
        </div>
      )}
    </section>
  );
});

export default SummaryTabSection;
