import classNames from 'classnames';

import type { InfoBlockProps } from './info-block.props';

import './info-block.scss';

const InfoBlock = ({
  children,
  isTitleNoUnderline,
  isPayments,
  title,
  withGap,
}: InfoBlockProps) => (
  <div
    className={classNames(
      'info-block',
      title && 'info-block_with-title',
      isPayments && 'info-block_payments',
      withGap && 'info-block_with_gap'
    )}
  >
    {title && (
      <span
        className={classNames(
          'info-block__title',
          isTitleNoUnderline && 'info-block__title_no-underline'
        )}
      >
        {title}
      </span>
    )}
    <div className="info-block__body">{children}</div>
  </div>
);

export default InfoBlock;
