import { Title } from './title';
import { ReactComponent as CardIcon } from 'src/assets/icons/card.svg';
import { ReactComponent as CurrencyIcon } from 'src/assets/icons/currency.svg';

import { AppRoute } from 'src/constants/app-route';

export const SINGLE_SALES_NAVIGATION_LINKS = [
  {
    label: Title.MAIN,
    icon: CardIcon,
    url: AppRoute.INVOICES,
  },
];

export const MULTIPLE_NAVIGATION_LINKS = [
  {
    label: Title.MAIN,
    icon: CardIcon,
    url: AppRoute.INVOICES,
  },
  {
    label: Title.PAYMENT_PAGE,
    icon: CurrencyIcon,
    url: AppRoute.PAYMENTS,
  },
];

export const NAVIGATION_LINKS = MULTIPLE_NAVIGATION_LINKS;
