import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';

import Filters from 'src/components/common/filters';
import Button from 'src/components/ui/button';

import DateRangeStore from 'src/stores/common/date-range-store';
import salesFilterStore from 'src/stores/filters/sales-filter-store';

import { type InvoiceStatus, PageDataType } from 'src/constants';
import type { ICommonPopupProps } from 'src/interfaces';

const PopupSalesFilters = observer(({ onCloseButtonClick }: ICommonPopupProps) => {
  const { dateRangeStore, applyFilters } = salesFilterStore;
  const [store] = useState(() => new DateRangeStore(dateRangeStore.dateRange));
  const [selectedSeller, setSelectedSeller] = useState<string>(salesFilterStore.selectedSeller);
  const [selectedStatuses, setSelectedStatuses] = useState<InvoiceStatus[]>(
    salesFilterStore.selectedStatuses
  );

  const toggleStatus = (status: InvoiceStatus) => {
    if (selectedStatuses.includes(status)) {
      setSelectedStatuses(selectedStatuses.filter((item) => status !== item));
    } else {
      setSelectedStatuses([...selectedStatuses, status]);
    }
  };

  const handleApplyButton = () => {
    applyFilters(selectedSeller, selectedStatuses, store.dateRange);
    onCloseButtonClick();
  };

  return (
    <>
      <div className="popup__content">
        <Filters
          filtersType={PageDataType.INVOICES}
          dateRangeStore={store}
          selectedStatuses={selectedStatuses}
          selectedSeller={selectedSeller}
          toggleStatus={toggleStatus}
          setSelectedSeller={setSelectedSeller}
        />
      </div>
      <div className="popup__buttons">
        <Button
          className="popup__button"
          label="Cancel"
          theme="outlined"
          onClick={onCloseButtonClick}
        />
        <Button
          className="popup__button"
          label="Apply"
          theme="filled"
          onClick={handleApplyButton}
        />
      </div>
    </>
  );
});

export default PopupSalesFilters;
