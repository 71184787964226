export enum Language {
  ENGLISH = 'english',
  HEBREW = 'hebrew',
}

export const DEFAULT_LANGUAGE: Language = Language.HEBREW;

export const LanguageLabel: Record<Language, string> = {
  [Language.ENGLISH]: 'EN',
  [Language.HEBREW]: 'HE',
};
