import { makeAutoObservable } from 'mobx';

import { fetchPaymentById, fetchPaymentUrl } from 'src/api';
import { AppRoute, FULL_PAYMENT_INSTALLMENTS_NUMBER, type PaymentMethod } from 'src/constants';
import type { IPayment } from 'src/interfaces';

class PaymentStore {
  init(paymentId: string) {
    void this.getPayment(paymentId);
  }

  isLoading = false;
  payment: IPayment | null = null;
  paymentUrl: string = '';

  constructor(paymentId: string) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.init(paymentId);
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setPayment(payment: IPayment) {
    this.payment = payment;
  }

  setPaymentUrl(paymentUrl: string) {
    this.paymentUrl = paymentUrl;
  }

  get isInstallments() {
    return Boolean(
      this.payment?.installments && this.payment?.installments > FULL_PAYMENT_INSTALLMENTS_NUMBER
    );
  }

  async getPayment(paymentId: string) {
    this.setIsLoading(true);

    const payment = await fetchPaymentById(paymentId);

    if (payment) {
      this.setPayment(payment);
    } else {
      window.location.href = AppRoute.NOT_FOUND;
    }

    this.setIsLoading(false);
  }

  async getPaymentUrl(
    paymentType: PaymentMethod.CREDIT_DEBIT_CARD | PaymentMethod.BIT,
    onFailed: (status?: number, errorText?: string) => void
  ) {
    if (!this.payment) {
      return;
    }

    this.setIsLoading(true);

    const response = await fetchPaymentUrl(this.payment.id, paymentType);

    if (response.isSuccess && response.data) {
      this.setPaymentUrl(response.data);
    } else {
      onFailed(response?.status, response.error);
    }

    this.setIsLoading(false);
  }
}

export default PaymentStore;
