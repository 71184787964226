import React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import Button from 'src/components/ui/button';

import sidebarStore from 'src/stores/sidebar-store';

import { SidebarTab } from 'src/constants';

import './sidebar-tabs.scss';

const SidebarTabs = observer(() => {
  const { activeTab, setActiveTab } = sidebarStore;

  return (
    <ul className="sidebar-tabs">
      {Object.values(SidebarTab).map((tab: SidebarTab) => (
        <li className="sidebar-tabs__item" key={tab}>
          <Button
            className={classNames(
              'sidebar-tabs__button',
              activeTab === tab && 'sidebar-tabs__button_active'
            )}
            label={tab}
            onClick={() => setActiveTab(tab)}
          />
        </li>
      ))}
    </ul>
  );
});

export default SidebarTabs;
