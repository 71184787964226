import baseAxios, { AxiosRequestConfig } from 'axios';
import { v4 as uuidv4 } from 'uuid';

import { AlertType } from 'src/components/ui/alert';

import globalAlertStore from 'src/stores/global-alert-store';
import globalAppStore from 'src/stores/global-app-store';

import { ApiResponseStatus } from 'src/constants';

const api = baseAxios.create({
  baseURL: window.API_URL,
});

api.interceptors.request.use(
  (options) => {
    const token = globalAppStore.token;
    if (token) {
      if (!options.headers) {
        // @ts-ignore
        options.headers = {};
      }
      options.headers.Authorization = `Bearer ${token}`;
    }
    return options;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const {
      config: { method = '', url = '' } = {},
      data: { message = '' } = {},
      status,
    } = error?.response || {};

    let title = `${status}: ${method.toUpperCase()} /${url}`;

    if (!status && !method && !url) {
      title = 'An unexpected error has occurred';
    }

    if (status === ApiResponseStatus.UNAUTHORIZED) {
      globalAppStore.logout();
    }

    globalAlertStore.addAlert({
      id: uuidv4(),
      desc: message,
      timeout: 10000,
      title,
      type: AlertType.error,
    });

    return Promise.reject(error);
  }
);

export const doGet = <T = any>(url: string, options?: AxiosRequestConfig) =>
  api.get<T>(url, options);
export const doPost = <T = any, P = any>(url: string, data?: P, options?: AxiosRequestConfig) =>
  api.post<T>(url, data, options);
export const doPatch = <T = any, P = any>(url: string, data: P, options?: AxiosRequestConfig) =>
  api.patch<T>(url, data, options);
export const doPut = <T = any, P = any>(url: string, data?: P, options?: AxiosRequestConfig) =>
  api.put<T>(url, data, options);
export const doDelete = <T = any>(url: string, options?: AxiosRequestConfig) =>
  api.delete<T>(url, options);
