import React from 'react';
import classNames from 'classnames';

import ExternalLink from 'src/components/common/external-link';
import { ReactComponent as AppStoreEnglishIcon } from 'src/assets/icons/app-icons/app-store.svg';
import { ReactComponent as AppStoreHebrewIcon } from 'src/assets/icons/app-icons/app-store-hebrew.svg';
import { ReactComponent as GooglePlayEnglishIcon } from 'src/assets/icons/app-icons/google-play.svg';
import { ReactComponent as GooglePlayHebrewIcon } from 'src/assets/icons/app-icons/google-play-hebrew.svg';
import { ReactComponent as AtidLogoIcon } from 'src/assets/icons/logo-atid.svg';

import { useLanguage } from 'src/contexts';
import {
  ABOUT_LINK,
  APP_STORE_LINK,
  GOOGLE_PLAY_LINK,
  INDEX_PAGE_LINK,
  Language,
  PRIVACY_POLICY_URL,
  SOCIAL_LINKS,
} from 'src/constants';
import type { FooterProps } from './footer.props';

import './footer.scss';

const Footer = ({ className }: FooterProps) => {
  const { language, texts } = useLanguage();
  const hasAppLinks = GOOGLE_PLAY_LINK || APP_STORE_LINK;
  const { CEMETERY_WEBSITE, DOWNLOAD_FREE_MOBILE_APP, GO_TO_X_PAGE, INDEX_PAGE, PRIVACY_POLICY } =
    texts;
  const isHebrew = language === Language.HEBREW;
  const AppStoreIcon = isHebrew ? AppStoreHebrewIcon : AppStoreEnglishIcon;
  const GooglePlayIcon = isHebrew ? GooglePlayHebrewIcon : GooglePlayEnglishIcon;

  return (
    <footer className={classNames('footer', className, hasAppLinks && 'footer_with-app-links')}>
      {hasAppLinks && (
        <div className="footer__app-links-wrapper">
          <span className="footer__app-links-description">{DOWNLOAD_FREE_MOBILE_APP}</span>

          {GOOGLE_PLAY_LINK && (
            <ExternalLink className="footer__app-link" href={GOOGLE_PLAY_LINK} title="Google Play">
              <GooglePlayIcon className="footer__app-icon footer__app-icon_google" />
            </ExternalLink>
          )}

          {APP_STORE_LINK && (
            <ExternalLink className="footer__app-link" href={APP_STORE_LINK} title="App Store">
              <AppStoreIcon className="footer__app-icon footer__app-icon_apple" />
            </ExternalLink>
          )}
        </div>
      )}

      <ExternalLink
        className="footer__logo"
        href="https://allfuneral.com/"
        title="All Funeral Services"
      >
        <AtidLogoIcon className="footer__logo__img" />
      </ExternalLink>

      <div
        className={classNames(
          'footer__nav',
          SOCIAL_LINKS.length > 2 && 'footer__nav_many-social-links'
        )}
      >
        <div className="footer__links">
          {INDEX_PAGE_LINK && (
            <ExternalLink className="footer__link" href={INDEX_PAGE_LINK}>
              {INDEX_PAGE}
            </ExternalLink>
          )}
          {ABOUT_LINK && (
            <ExternalLink className="footer__link" href={ABOUT_LINK}>
              {CEMETERY_WEBSITE}
            </ExternalLink>
          )}
          {PRIVACY_POLICY_URL && (
            <ExternalLink className="footer__link" href={PRIVACY_POLICY_URL || ''}>
              {PRIVACY_POLICY}
            </ExternalLink>
          )}
        </div>
        <div className="footer__social">
          {SOCIAL_LINKS.map(({ label, logo: Logo, url }) => (
            <ExternalLink
              key={label}
              className="footer__social-link"
              ariaLabel={GO_TO_X_PAGE.replace('{X}', label)}
              href={url}
            >
              <Logo className="footer__social-icon" />
            </ExternalLink>
          ))}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
