export const ALL = 'All';
export const PUBLIC_URL = process.env.PUBLIC_URL || '';

export const SEARCH_STRING_MIN_LENGTH = 3;
export const SEARCH_DEBOUNCE = 800;

export const CONFIG_REQUIRED_PARAMS: (keyof Window)[] = [
  'API_URL',
  'CONTACT_PHONE',
  'GOOGLE_OAUTH_CLIENT_ID',
  'PAYMENT_SUCCESS_MAIN_PAGE_PUBLIC_URL',
];
export const CONFIG_REQUIRED_PARAMS_MAPPED = CONFIG_REQUIRED_PARAMS.map((item) => ({
  name: item,
  value: window[item],
}));

export const INVOICE_GET_PARAM = 'invoice_id';
export const PAYMENT_GET_PARAM = 'payment_id';

export const { ABOUT_LINK, APP_STORE_LINK, GOOGLE_PLAY_LINK, INDEX_PAGE_LINK, PRIVACY_POLICY_URL } =
  window;
