import React from 'react';
import classNames from 'classnames';

import { StatusLabel } from 'src/constants';
import { getStatusModifier, isPaymentStatus } from 'src/utils';
import type { StatusProps } from './status.props';

import './status.scss';

const Status = ({ className, isPrintable, type }: StatusProps) => {
  const statusModifier = getStatusModifier(type);
  const isPaymentType = isPaymentStatus(type);

  const classes = classNames(
    'status',
    `status_${statusModifier}`,
    className,
    isPaymentType && 'status_payment',
    isPrintable && 'status_printable'
  );
  return <span className={classes}>{StatusLabel[type]}</span>;
};

export default Status;
