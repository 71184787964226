import classNames from 'classnames';

import LanguageButton from '../language-button';

import { useLanguage } from 'src/contexts';
import { Language } from 'src/constants';
import type { LanguageTogglerProps } from './language-toggler.props';

import './language-toggler.scss';

const LanguageToggler = ({ className }: LanguageTogglerProps) => {
  const { language, setLanguage } = useLanguage();

  return (
    <div className={classNames('language-toggler', className)}>
      <LanguageButton
        isActive={language === Language.HEBREW}
        language={Language.HEBREW}
        onClick={setLanguage}
      />
      <LanguageButton
        isActive={language === Language.ENGLISH}
        language={Language.ENGLISH}
        onClick={setLanguage}
      />
    </div>
  );
};

export default LanguageToggler;
