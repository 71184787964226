import { UserRole } from 'src/constants';
import type { IApiUser, IUser } from 'src/interfaces';

export const mapApiToUser = ({ fullname, is_manager, ...rest }: IApiUser): IUser => {
  return {
    ...rest,
    fullName: fullname,
    role: is_manager ? UserRole.MANAGER : UserRole.SELLER,
  };
};
