import React from 'react';

import type { FilterBlockProps } from './filter-block.props';

import './filter-block.scss';

const FilterBlock = ({ children, title }: FilterBlockProps) => (
  <div className="filter-block">
    <span className="filter-block__title">{title}</span>
    {children}
  </div>
);

export default FilterBlock;
