import React from 'react';
import { observer } from 'mobx-react-lite';

import Button from 'src/components/ui/button';

import popupStore from 'src/stores/popup-store';

import { PopupName } from 'src/constants';
import type { ICommonPopupProps } from 'src/interfaces';

const PopupConfirm = observer(({ onCloseButtonClick }: ICommonPopupProps) => {
  const {
    mainActionLabel,
    secondaryActionLabel,
    text = '',
    title = '',
    mainActionHandler,
    secondaryActionHandler = onCloseButtonClick,
  } = popupStore.getPopupState(PopupName.CONFIRM);

  return (
    <>
      <div className="popup__header">
        <p className="popup__title">{title}</p>
        {text && <p className="popup__text">{text}</p>}
      </div>
      <div className="popup__buttons">
        <Button
          className="popup__button"
          label={secondaryActionLabel}
          theme="outlined"
          onClick={secondaryActionHandler}
        />
        <Button
          className="popup__button"
          label={mainActionLabel}
          theme="filled"
          onClick={mainActionHandler}
        />
      </div>
    </>
  );
});

export default PopupConfirm;
