import type React from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import type { MenuItemProps } from './menu-item.props';

import './menu-item.scss';

function MenuItem({ children, buttonProps, linkProps, label }: MenuItemProps) {
  const renderInnerElement = () => {
    if (buttonProps) {
      const { onClick } = buttonProps;
      const handleButtonClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
        evt.preventDefault();
        onClick();
      };

      return (
        <button className="menu-item__button" aria-label={label} onClick={handleButtonClick}>
          {children}
          {label}
        </button>
      );
    }

    if (linkProps) {
      const { url, isExternal } = linkProps;

      if (isExternal) {
        return (
          <a className="menu-item__link" aria-label={label} href={url}>
            {children}
            {label}
          </a>
        );
      }

      return (
        <NavLink
          className={({ isActive }) =>
            classNames('menu-item__link', isActive && 'menu-item__link_active')
          }
          aria-label={label}
          end
          to={url}
        >
          {children}
          {label}
        </NavLink>
      );
    }

    return children;
  };

  return <li className="menu-item">{renderInnerElement()}</li>;
}

export default MenuItem;
