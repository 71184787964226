import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import popupStore from 'src/stores/popup-store';

import { useLanguage } from 'src/contexts';
import {
  AppRoute,
  MeshulamAction,
  MeshulamLink,
  PAYMENT_GET_PARAM,
  PopupName,
} from 'src/constants';
import type { MeshulamFormProps } from './meshulam-form.props';

import './meshulam-form.scss';

const MeshulamForm = observer(({ paymentId, paymentUrl, setPaymentUrl }: MeshulamFormProps) => {
  const navigate = useNavigate();
  const { texts } = useLanguage();

  useEffect(() => {
    const listenBrowserMessages = (
      result: MessageEvent<{
        action: MeshulamAction;
        status: number;
      }>
    ) => {
      if (result.origin === MeshulamLink.TEST || result.origin === MeshulamLink.PROD) {
        switch (result.data.action) {
          case MeshulamAction.CLOSE: {
            setPaymentUrl('');
            break;
          }

          case MeshulamAction.PAYMENT: {
            if (result.data.status === 1 && paymentId) {
              navigate(`${AppRoute.PAYMENT_SUCCESS}?${PAYMENT_GET_PARAM}=${paymentId}`);
            } else {
              navigate(`${AppRoute.PAYMENT_FAIL}?${PAYMENT_GET_PARAM}=${paymentId}`);
            }
            break;
          }

          case MeshulamAction.FAILED_LOAD: {
            popupStore.showPopup(PopupName.WARN, {
              title: texts.PAYMENT_ERROR,
              text: texts.PAYMENT_FORM_EXPIRED,
            });
            setPaymentUrl('');
            break;
          }
        }
      }
    };

    window.addEventListener('message', listenBrowserMessages);

    return () => window.removeEventListener('message', listenBrowserMessages);
  }, [navigate, paymentId, setPaymentUrl, texts]);

  return (
    <div className="meshulam-form">
      <iframe
        className="meshulam-form__frame"
        width={520}
        height={728}
        src={paymentUrl}
        title={texts.PAYMENT}
      />
    </div>
  );
});

export default MeshulamForm;
