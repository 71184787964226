import { observer } from 'mobx-react-lite';

import Logo from 'src/components/common/logo';
import NavbarItem from './navbar-item';
import { ReactComponent as ExitIcon } from 'src/assets/icons/exit.svg';

import globalAppStore from 'src/stores/global-app-store';

import { NAVIGATION_LINKS } from 'src/constants';

import './navbar.scss';

const Navbar = observer(() => (
  <nav className="navbar">
    <Logo />

    <ul className="navbar__menu">
      {NAVIGATION_LINKS.map(({ label, icon: Icon, url }) => (
        <NavbarItem linkProps={{ url }} tooltipText={label} key={label}>
          <Icon />
        </NavbarItem>
      ))}
    </ul>

    <ul className="navbar__menu  navbar__menu_bottom">
      <NavbarItem buttonProps={{ onClick: globalAppStore.logout }} tooltipText="Logout">
        <ExitIcon />
      </NavbarItem>
    </ul>
  </nav>
));

export default Navbar;
