import { observer } from 'mobx-react-lite';

import Loader from 'src/components/common/loader';
import Button from 'src/components/ui/button';
import IconButton from 'src/components/ui/icon-button';
import { ReactComponent as DownloadIcon } from 'src/assets/icons/download-doc.svg';
import { ReactComponent as FilterIcon } from 'src/assets/icons/filter.svg';
import { ReactComponent as SearchIcon } from 'src/assets/icons/search.svg';

import constantsStore from 'src/stores/constants-store';
import invoicesStore from 'src/stores/invoices-store';
import popupStore from 'src/stores/popup-store';
import searchWindowStore from 'src/stores/search-window-store';

import { PopupName, Title } from 'src/constants';

import './main-mobile-filters.scss';

const MainMobileFilters = observer(() => {
  const { isLoading } = constantsStore;
  const { exportInvoices, isDownloading, items } = invoicesStore;
  const handleFilterBtnClick = () => popupStore.showPopup(PopupName.SALES_FILTERS);

  return (
    <section className="main-mobile-filters">
      <Button
        icon={<FilterIcon />}
        label="Filters"
        theme="outlined"
        onClick={handleFilterBtnClick}
      />
      <Button
        icon={isDownloading ? <Loader isInline size="small" /> : <DownloadIcon />}
        isDisabled={isDownloading || !items.length || isLoading}
        label="XLS"
        theme="outlined"
        onClick={exportInvoices}
      />
      <IconButton
        className="main-mobile-filters__search-button"
        ariaLabel={Title.SEARCH_INPUT_INVOICES}
        icon={<SearchIcon />}
        onClick={searchWindowStore.show}
      />
    </section>
  );
});

export default MainMobileFilters;
