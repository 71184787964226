export function debounce(f: Function, ms: number) {
  let isCoolDown = false;

  return function (this: any, ...args: any[]) {
    if (isCoolDown) {
      return;
    }

    isCoolDown = true;

    setTimeout(() => {
      f.apply(this, args);
      isCoolDown = false;
    }, ms);
  };
}
