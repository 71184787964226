import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import type { ButtonProps } from './button.props';

import './button.scss';

function Button({
  className,
  href = '',
  icon,
  isDisabled,
  label,
  size = 'regular',
  tag = 'button',
  target,
  theme,
  type = 'button',
  onClick,
  ...props
}: ButtonProps) {
  const classes = classNames('button', className, theme && `button_${theme}`, `button_${size}`, {
    button_disabled: isDisabled,
    button_icon: icon,
    button_link: tag !== 'button',
  });

  const renderInnerElements = () => (
    <span className="button__wrapper">
      {icon && <span className="button__icon">{icon}</span>}
      <span className="button__label">{label}</span>
    </span>
  );

  switch (tag) {
    case 'button':
      return (
        <button
          className={classes}
          aria-label={label}
          disabled={isDisabled}
          type={type}
          onClick={onClick}
          {...props}
        >
          {renderInnerElements()}
        </button>
      );
    case 'link':
      return (
        <Link
          className={classes}
          aria-label={label}
          target={target || '_self'}
          to={href}
          onClick={onClick}
          {...props}
        >
          {renderInnerElements()}
        </Link>
      );
    case 'a':
      return (
        <a
          className={classes}
          aria-label={label}
          href={href as string}
          rel="noopener noreferrer"
          target={target || '_blank'}
          onClick={onClick}
          {...props}
        >
          {renderInnerElements()}
        </a>
      );
    default:
      return null;
  }
}

export default Button;
