const ISRAEL_PHONE_CODE = '972';
const LOCAL_PREFIX = '0';

const getOnlyNumbers = (str: string) => str.replace(/\D/g, '');

export const formatPhoneToApi = (phone = '') =>
  getOnlyNumbers(phone).replace(new RegExp(`^${LOCAL_PREFIX}`), ISRAEL_PHONE_CODE);

export const formatApiToPhone = (phone: string) => {
  if (!phone) {
    return '';
  }

  const newPhone = getOnlyNumbers(phone).replace(new RegExp(`^${ISRAEL_PHONE_CODE}`), LOCAL_PREFIX);
  return newPhone ? `${newPhone.slice(0, 3)}-${newPhone.slice(3)}` : '';
};
