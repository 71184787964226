import { useCallback, useEffect, useState } from 'react';

import IconButton from 'src/components/ui/icon-button';
import TooltipTrigger from 'src/components/ui/tooltip-trigger';
import { ReactComponent as CopyIcon } from 'src/assets/icons/copy.svg';
import { ReactComponent as WindowIcon } from 'src/assets/icons/window.svg';

import { Position } from 'src/constants';
import { copyToClipboard } from 'src/utils';
import type { CrmUrlProps } from './crm-url.props';

import './crm-url.scss';

const COPY_TOOLTIP_TIMEOUT = 3000;

const CrmUrl = ({ url }: CrmUrlProps) => {
  const [shouldShowTooltip, setShouldShowTooltip] = useState(false);

  const hideTooltip = useCallback(() => {
    setShouldShowTooltip(false);
  }, []);

  const showTooltip = useCallback(() => {
    setShouldShowTooltip(true);
  }, []);

  const handleCopyButtonClick = useCallback(() => {
    void copyToClipboard(url, showTooltip);
  }, [url, showTooltip]);

  useEffect(() => {
    let timer: NodeJS.Timer;

    if (shouldShowTooltip) {
      timer = setTimeout(() => {
        hideTooltip();
      }, COPY_TOOLTIP_TIMEOUT);

      window.addEventListener('scroll', hideTooltip, true);
    } else {
      window.removeEventListener('scroll', hideTooltip, true);
    }

    return () => {
      clearTimeout(timer);
      window.removeEventListener('scroll', hideTooltip, true);
    };
  }, [hideTooltip, shouldShowTooltip]);

  return (
    <div className="crm-url">
      <TooltipTrigger
        isHideOnScroll={false}
        text="Link copied"
        tooltipPosition={Position.RIGHT}
        shouldShowOnHover={false}
        shouldShowTooltip={shouldShowTooltip}
        theme="light"
      >
        <div className="crm-url__actions">
          <IconButton ariaLabel="Copy link" icon={<CopyIcon />} onClick={handleCopyButtonClick} />
          <IconButton ariaLabel="Open crm url" href={url} icon={<WindowIcon />} tag="a" />
        </div>
      </TooltipTrigger>
    </div>
  );
};

export default CrmUrl;
