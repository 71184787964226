import { doGet, doPost } from './axios-config';

import { mapApiToPerson, mapApiToUser } from 'src/adapters';
import { ApiRoute, ErrorText } from 'src/constants';
import type { IApiPerson, IApiUser, IUser } from 'src/interfaces';

const URL = ApiRoute.USERS;

export async function fetchUsers() {
  try {
    const result = await doGet<IApiPerson[]>(URL);

    return result.data?.map(mapApiToPerson) || null;
  } catch (err: any) {
    console.log(err);
    return null;
  }
}

export async function fetchUserSignin(
  credential: string,
  onSuccess: (token: string, user: IUser) => void,
  onFail: (error: string) => void
) {
  try {
    const result = await doPost<IApiUser>(`${URL}/sign-in/google`, { token: credential });

    if (result.headers.authorization) {
      onSuccess(result.headers.authorization.split(' ')[1], mapApiToUser(result.data));
    } else {
      onFail(ErrorText.REQUEST_FAILURE);
    }
  } catch (err: any) {
    console.log(err);
    const errorText = err.response?.data?.message || ErrorText.REQUEST_FAILURE;
    onFail(errorText);
  }
}
