import { FULL_PAYMENT_INSTALLMENTS_NUMBER } from 'src/constants';
import { formatApiToPhone, formatDate } from 'src/utils';
import type {
  IApiDetailedInvoice,
  IApiInvoicePayment,
  IDetailedInvoice,
  IInvoicePayment,
} from 'src/interfaces';

const mapPayment = ({
  has_payment_page,
  installments,
  meshulam_invoice_url,
  paid_at,
  ...rest
}: IApiInvoicePayment): IInvoicePayment => {
  return {
    ...rest,
    hasPaymentPage: has_payment_page ?? false,
    installments: installments || FULL_PAYMENT_INSTALLMENTS_NUMBER,
    meshulamInvoiceUrl: meshulam_invoice_url,
    paidAt: paid_at ? formatDate(paid_at) : '',
  };
};

export const mapApiToDetailedInvoice = ({
  added_at,
  crm_url,
  purchaser_email,
  purchaser_name,
  purchaser_phone,
  payments,
  ...rest
}: IApiDetailedInvoice): IDetailedInvoice => {
  return {
    ...rest,
    crmUrl: crm_url,
    payments: payments?.length ? payments.map(mapPayment) : [],
    purchaser: {
      email: purchaser_email || '',
      name: purchaser_name || '',
      phone: purchaser_phone ? formatApiToPhone(purchaser_phone) : '',
    },
  };
};
