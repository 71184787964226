import React from 'react';
import classNames from 'classnames';

import type { InputPlaceholderProps } from './input-placeholder.props';

import './input-placeholder.scss';

function InputPlaceholder({ hide, isValid, placeholder, style }: InputPlaceholderProps) {
  if (hide || !placeholder) {
    return null;
  }

  return (
    <span
      className={classNames('input-placeholder', isValid || 'input-placeholder_invalid')}
      style={style}
    >
      {placeholder}
    </span>
  );
}

export default InputPlaceholder;
