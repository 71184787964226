import classNames from 'classnames';

import type { TableColProps } from './table-col.props';

import './table-col.scss';

const TableCol = ({ children, className, isBold, isFade, text, onMouseDown }: TableColProps) => (
  <td
    className={classNames(
      'table-col',
      isBold && 'table-col_bold',
      isFade && 'table-col_fade',
      className
    )}
  >
    <div className="table-col__wrapper" onMouseDown={onMouseDown}>
      {text && <span className="table-col__text">{text}</span>}
      {children}
    </div>
  </td>
);

export default TableCol;
