import React from 'react';
import { observer } from 'mobx-react-lite';

import Financials from '../financials';

import paidStatisticsStore from 'src/stores/paid-statistics-store';

const PaidStatistics = observer(() => {
  const { paidStatistics } = paidStatisticsStore ?? {};

  return <Financials isPaidOnly location="main" paid={paidStatistics} />;
});

export default PaidStatistics;
