const copyToClipboardFallback = (text: string) => {
  return new Promise(function (resolve: any, reject) {
    const range = document.createRange();
    range.selectNodeContents(document.body);
    document.getSelection()?.addRange(range);

    let success = false;
    function listener(e: any) {
      e.clipboardData.setData('text/plain', text);
      e.preventDefault();
      success = true;
    }
    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
    document.getSelection()?.removeAllRanges();
    success ? resolve() : reject();
  }).catch((err) => console.log(err));
};

export const copyToClipboard = async (text: string, onSuccess?: () => void) => {
  try {
    if (!navigator.clipboard) {
      await copyToClipboardFallback(text);
      onSuccess?.();
      return;
    }
    await navigator.clipboard.writeText(text);
    onSuccess?.();
  } catch (err) {
    console.log(`Failed to copy ${text}`);
  }
};
