import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';

import Loader from 'src/components/common/loader';
import Button from 'src/components/ui/button';
import Input from 'src/components/ui/inputs/input';
import Select from 'src/components/ui/select';

import popupStore from 'src/stores/popup-store';

import {
  DEFAULT_SELECT_INSTALLMENTS_NUMBER,
  MAX_INSTALLMENTS_NUMBER,
  PopupName,
  PopupTitle,
  SelectDirection,
} from 'src/constants';
import { createNumberedArray, parseStringToNumber } from 'src/utils';
import type { ICommonPopupProps } from 'src/interfaces';

const paymentNumberOptions = createNumberedArray(MAX_INSTALLMENTS_NUMBER).map((num) => {
  const id = num.toString();
  return { id, value: id };
});

const PopupCreatePayment = observer(({ isMobile, onCloseButtonClick }: ICommonPopupProps) => {
  const { maxAmount, createPayment } = popupStore.getPopupState(PopupName.CREATE_PAYMENT);
  const [amount, setAmount] = useState<number>(maxAmount);
  const [installments, setInstallments] = useState<number>(DEFAULT_SELECT_INSTALLMENTS_NUMBER);
  const [isProcessing, setProcessing] = useState(false);

  const handleAmountChange = (value: string) => {
    const newAmount = parseStringToNumber(value);
    setAmount(Math.min(newAmount, maxAmount));
  };

  const handleInstallmentsChange = (value: string) => {
    setInstallments(parseStringToNumber(value));
  };

  const handleClickSendButton = async () => {
    setProcessing(true);
    const isSuccess = await createPayment({ amount, installments });
    setProcessing(false);
    if (isSuccess) {
      onCloseButtonClick();
    }
  };

  return (
    <>
      <div className="popup__main">
        <p className="popup__title">{PopupTitle.CREATE_PAYMENT}</p>

        <div className="popup__inputs">
          <Input
            isDisabled={isProcessing}
            label="Payment Amount, ₪"
            value={`${amount}`}
            onChange={handleAmountChange}
          />

          <Select
            direction={isMobile ? SelectDirection.TOP : SelectDirection.BOTTOM}
            isDisabled={isProcessing}
            items={paymentNumberOptions}
            label="Number of Payments"
            value={`${installments}`}
            onChange={handleInstallmentsChange}
          />
        </div>
      </div>

      <div className="popup__buttons">
        <Button
          label="Cancel"
          size={isMobile ? 'regular' : 'big'}
          theme="outlined"
          onClick={onCloseButtonClick}
        />

        <Button
          icon={isProcessing && <Loader isInline size="small" />}
          isDisabled={!amount || isProcessing}
          label="Create Page"
          size={isMobile ? 'regular' : 'big'}
          theme="filled"
          onClick={handleClickSendButton}
        />
      </div>
    </>
  );
});

export default PopupCreatePayment;
