import { useEffect, useState } from 'react';

import { debounce } from 'src/utils';

const LISTENER_DEBOUNCE_INTERVAL = 200;

const getInitialValues = () => {
  if (typeof window !== 'undefined') {
    return {
      height: window.innerHeight,
      width: window.innerWidth,
    };
  }

  return {
    height: 0,
    width: 0,
  };
};

export default function useWindowSize(interval = LISTENER_DEBOUNCE_INTERVAL) {
  const [windowSize, setWindowSize] = useState(getInitialValues());

  useEffect(() => {
    const onWindowResize = () =>
      setWindowSize({
        height: window.innerHeight,
        width: window.innerWidth,
      });

    const handleResize = debounce(onWindowResize, interval);

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [interval]);

  return windowSize;
}
