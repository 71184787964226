import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import LoaderWrapper from './components/loader-wrapper';
import { ReactComponent as LoaderIcon } from 'src/assets/icons/loader.svg';

import type { LoaderProps } from './loader.props';

import './loader.scss';

const DEBOUNCE_INTERVAL = 300;

const Loader = ({
  className,
  debounceTime = DEBOUNCE_INTERVAL,
  isDebounce = false,
  isInline,
  position,
  size = 'regular',
}: LoaderProps) => {
  const [showComponent, setShowComponent] = useState(!isDebounce);

  useEffect(() => {
    const timer = setTimeout(() => setShowComponent(true), debounceTime);
    return () => clearTimeout(timer);
  }, [debounceTime]);

  return showComponent ? (
    <LoaderWrapper
      className={classNames(
        `loader loader_size_${size}`,
        position && `loader_position_${position}`,
        className
      )}
      isInline={isInline}
    >
      <LoaderIcon className="loader__spinner" />
    </LoaderWrapper>
  ) : null;
};

export default Loader;
