import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import Loader from 'src/components/common/loader';
import IconButton from 'src/components/ui/icon-button';
import Input from 'src/components/ui/inputs/input';
import Label from 'src/components/ui/label';
import { ReactComponent as Arrow } from 'src/assets/icons/chevron-down.svg';
import { ReactComponent as Cross } from 'src/assets/icons/cross.svg';
import { ReactComponent as Search } from 'src/assets/icons/search.svg';

import useOnClickOutside from 'src/hooks/use-on-click-outside';
import { SelectDirection } from 'src/constants';
import type { SelectProps } from './select.props';

import './select.scss';

function Select({
  direction = SelectDirection.BOTTOM,
  isDisabled,
  isLoading,
  isValid = true,
  items,
  label,
  size = 'oversize',
  value,
  withSearch,
  onChange,
}: SelectProps) {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [inputVal, setInputVal] = useState<string>('');
  const [isSearchFocus, setIsSearchFocus] = useState<boolean>(false);
  const searchRef = useRef<HTMLInputElement>(null);

  const filteredItems = items.filter((item) =>
    item.value.toLowerCase().includes(inputVal.toLowerCase().trim())
  );

  const selectRef = React.useRef<HTMLDivElement | null>(null);

  useOnClickOutside(selectRef, () => setIsOpen(false));

  const handleClick = () => setIsOpen(!isOpen);

  const handleChange = (id: string) => {
    onChange(id);
    setIsOpen(false);
  };

  const handleSearchFocus = () => {
    setIsSearchFocus(true);
  };

  const handleSearchBlur = () => {
    setIsSearchFocus(false);
  };

  const handleClearButtonClick = () => {
    setInputVal('');
  };

  const handleSearchButtonClick = () => {
    if (searchRef.current) {
      searchRef.current.focus();
    }
  };

  const isSelectDisabled = isDisabled || isLoading || items.length === 0;

  useEffect(() => {
    if (!isOpen) {
      setInputVal('');
      setIsSearchFocus(false);
    }
  }, [isOpen]);

  useEffect(() => {
    const { current: select } = selectRef;
    if (select) {
      const options: AddEventListenerOptions & EventListenerOptions = { passive: false };
      const onTouchMove = (event: TouchEvent) => event.stopPropagation();
      select.addEventListener('touchmove', onTouchMove, options);
      return () => select.removeEventListener('touchmove', onTouchMove, options);
    }
  }, []);

  const currentValueLabel = items.find((item) => item.id === value)?.value;

  const selectClasses = classNames('select', `select_${size}`, {
    select_disabled: isSelectDisabled,
    select_empty: !value,
    select_label: !!label,
    select_open: isOpen,
  });

  const labelClasses = classNames('select__label', {
    label_placeholder: !isOpen && !currentValueLabel,
  });

  const renderSearchIcon = () =>
    isSearchFocus ? (
      <IconButton
        ariaLabel="clear search"
        icon={<Cross className="select__icon select__icon_clear" />}
        theme="plain"
        onClick={handleClearButtonClick}
      />
    ) : (
      <IconButton
        ariaLabel="search"
        icon={<Search className="select__icon select__icon_search" />}
        theme="plain"
        onClick={handleSearchButtonClick}
      />
    );

  return (
    <div className={selectClasses} ref={selectRef}>
      <button
        className={classNames('select__button', !isValid && 'select__button_invalid')}
        disabled={isSelectDisabled}
        type="button"
        onClick={handleClick}
      >
        <span className="select__button-wrapper">
          {label && (!isOpen || direction === SelectDirection.BOTTOM) ? (
            <Label className={labelClasses} isSpan label={label} />
          ) : null}
          <span className="select__value" dir="auto">
            {currentValueLabel}
          </span>
          {isLoading && !isDisabled ? (
            <Loader isInline size="small" />
          ) : (
            <Arrow className="select__icon select__icon_arrow" />
          )}
        </span>
      </button>
      {isOpen && (
        <div className={`select__dropbox select__dropbox_${direction} scrollbar`}>
          {withSearch && (
            <Input
              className="select__dropbox-search"
              ref={searchRef}
              icon={renderSearchIcon()}
              placeholder="SEARCH"
              value={inputVal}
              onBlur={handleSearchBlur}
              onChange={setInputVal}
              onFocus={handleSearchFocus}
            />
          )}

          <div className="select__dropbox-container">
            {filteredItems.map((item) => {
              const classes = classNames('select__dropbox-item', {
                'select__dropbox-item_active': item.id === value,
                'select__dropbox-item_empty': !item.value,
              });
              return (
                <button
                  className={classes}
                  key={item.id}
                  dir="auto"
                  onClick={() => handleChange(item.id)}
                >
                  {item.value}
                </button>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default Select;
