import React from 'react';
import classNames from 'classnames';

import type { DescriptionListProps } from './description-list.props';

import './description-list.scss';

const DescriptionList = ({ className, pairData }: DescriptionListProps) => {
  return (
    <ul className={classNames('description-list', className)}>
      {pairData.map((pair, idx) => (
        <li
          key={`descriptionListPair-${idx}`}
          className={classNames('description-list__item', pair.className)}
        >
          <span className="description-list__key">{pair.key}:</span>
          <span className="description-list__value">{pair.value}</span>
        </li>
      ))}
    </ul>
  );
};

export default DescriptionList;
