import { useCallback } from 'react';
import classNames from 'classnames';

import { LanguageLabel } from 'src/constants';
import type { LanguageButtonProps } from './language-button.props';

import './language-button.scss';

const LanguageButton = ({ className, isActive, language, onClick }: LanguageButtonProps) => {
  const handleClick = useCallback(() => {
    onClick(language);
  }, [language, onClick]);

  return (
    <button
      className={classNames(
        `language-button language-button_${language}`,
        isActive && 'language-button_active',
        className
      )}
      aria-label={`Toggle to ${language}`}
      onClick={handleClick}
    >
      <span className="language-button__text">{LanguageLabel[language]}</span>
    </button>
  );
};

export default LanguageButton;
