import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import MainLayout from 'src/layouts/main-layout';
import PaymentLayout from 'src/layouts/payment-layout';
import LoginPage from 'src/pages/login-page';
import MainPage, { MainHeader, MainMobileFilters } from 'src/pages/main-page';
import NotFoundPage from 'src/pages/not-found-page';
import PaymentPage from 'src/pages/payment-page';
import PaymentStatusPage from 'src/pages/payment-status-page';
import PaymentsPage, { PaymentsHeader, PaymentsMobileFilters } from 'src/pages/payments-page';
import PrintInvoicePage from 'src/pages/print-invoice-page';
import Popups from 'src/components/popups';
import Loader from './components/common/loader';
import PrivateRoute from './components/common/private-route';

import globalAppStore from 'src/stores/global-app-store';

import useVhValue from 'src/hooks/use-vh-value';
import { AppRoute, PaymentPageStatus, PUBLIC_URL } from 'src/constants';

import 'src/scss/index.scss';

const App = observer(() => {
  useVhValue();
  const { isConfigCorrect, isLoading, missingConfigVars, token } = globalAppStore;

  if (!isConfigCorrect) {
    return (
      <div className="app app_err">
        <h2 className="app__error">Failed to load config params:</h2>
        <ul className="app__error-list">
          {missingConfigVars.map((item) => (
            <li className="app__error-list-item" key={item.name}>
              <span className="app__error-text">{item.name}</span>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  if (isLoading) {
    return <Loader position="fixed" />;
  }

  return (
    <div className="app">
      <Router basename={PUBLIC_URL}>
        <Routes>
          {!token && <Route element={<LoginPage />} path={AppRoute.LOGIN} />}
          <Route element={<PrivateRoute isRedirect={!token} />}>
            <Route
              element={
                <MainLayout
                  children={<MainPage />}
                  header={MainHeader}
                  mobileFilters={MainMobileFilters}
                />
              }
              path={AppRoute.INVOICES}
            />
            <Route element={<PrintInvoicePage />} path={`${AppRoute.PRINT_INVOICE}/:id`} />
            <Route
              element={
                <MainLayout
                  children={<PaymentsPage />}
                  header={PaymentsHeader}
                  mobileFilters={PaymentsMobileFilters}
                />
              }
              path={AppRoute.PAYMENTS}
            />
          </Route>
          <Route
            element={<PaymentLayout children={<PaymentPage />} />}
            path={`${AppRoute.PAYMENT}/:id`}
          />
          <Route
            element={
              <PaymentLayout
                children={<PaymentStatusPage status={PaymentPageStatus.SUCCESSFUL} />}
              />
            }
            path={AppRoute.PAYMENT_SUCCESS}
          />
          <Route
            element={
              <PaymentLayout children={<PaymentStatusPage status={PaymentPageStatus.FAILED} />} />
            }
            path={AppRoute.PAYMENT_FAIL}
          />

          <Route
            element={<PaymentLayout children={<NotFoundPage />} />}
            path={AppRoute.NOT_FOUND}
          />

          <Route element={<Navigate replace to={AppRoute.INVOICES} />} path="*" />
        </Routes>
        <Popups />
      </Router>
    </div>
  );
});

export default App;
