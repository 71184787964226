import { CURRENCY_CENTS_COUNT, CURRENCY_CODE } from 'src/constants';

const LocaleString = {
  he: 'he-IL',
};

export function formatMoney(sum: number, withCents = false, withCurrencyCode = false) {
  if (!sum) {
    return '0';
  }

  const moneySum = sum / CURRENCY_CENTS_COUNT;

  const options = withCents
    ? {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }
    : { maximumFractionDigits: 0 };

  return withCurrencyCode
    ? `${CURRENCY_CODE}${moneySum.toLocaleString(LocaleString.he, options)}`
    : `${moneySum.toLocaleString(LocaleString.he, options)}`;
}

export const getMainCurrency = (cents: number) => Math.floor(cents / CURRENCY_CENTS_COUNT) || 0;
export const getSubunitCurrency = (mainCurrency: number) =>
  Math.floor(mainCurrency * CURRENCY_CENTS_COUNT) || 0;
