import { observer } from 'mobx-react-lite';

import Loader from 'src/components/common/loader';
import Button from 'src/components/ui/button';

import globalAppStore from 'src/stores/global-app-store';

import { useLanguage } from 'src/contexts';
import { AppRoute, LocaleProperty, PAYMENT_GET_PARAM, PaymentPageStatus } from 'src/constants';
import { formatApiToPhone, getSearchParam } from 'src/utils';
import type { PaymentStatusPageProps } from './payment-status-page.props';

import './payment-status-page.scss';

const PageData: Record<PaymentPageStatus, Record<string, LocaleProperty>> = {
  [PaymentPageStatus.SUCCESSFUL]: {
    buttonLabel: 'GO_TO_MAIN_PAGE',
    text: 'YOU_CAN_NOW_CLOSE_THIS_PAGE',
    title: 'PAYMENT_SUCCESSFUL',
  },

  [PaymentPageStatus.FAILED]: {
    buttonLabel: 'GO_TO_PAYMENT_PAGE',
    text: 'PLEASE_CONTACT_MANAGER',
    title: 'PAYMENT_FAILED',
  },
};

const PaymentStatusPage = observer(({ status }: PaymentStatusPageProps) => {
  const { texts } = useLanguage();
  const paymentId = getSearchParam(PAYMENT_GET_PARAM);
  const data = PageData[status];
  const mainPageUrl = globalAppStore.isAuthorized
    ? AppRoute.INVOICES
    : window.PAYMENT_SUCCESS_MAIN_PAGE_PUBLIC_URL;
  const buttonLink =
    status === PaymentPageStatus.FAILED ? `${AppRoute.PAYMENT}/${paymentId}` : mainPageUrl;
  const buttonTag = status === PaymentPageStatus.FAILED ? 'link' : 'a';

  if (!paymentId) {
    window.location.href = mainPageUrl;
    return <Loader />;
  }

  const { buttonLabel, text, title } = data;

  return (
    <div className={`payment-status-page  payment-status-page_${status}`}>
      <div className="payment-status-page__info">
        <h1 className="payment-status-page__title">{texts[title]}</h1>
        <p className="payment-status-page__text">
          {texts[text]}
          {status === PaymentPageStatus.FAILED ? `: ${formatApiToPhone(window.CONTACT_PHONE)}` : ''}
        </p>
      </div>
      <Button
        className="payment-status-page__button"
        href={buttonLink}
        label={texts[buttonLabel]}
        size="big"
        tag={buttonTag}
        target="_self"
        theme="outlined"
      />
    </div>
  );
});

export default PaymentStatusPage;
