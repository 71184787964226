export enum InvoiceStatus {
  CANCELED = 'canceled',
  PAID_IN_FULL = 'paid_in_full',
  PARTIALLY_PAID = 'partially_paid',
  PENDING_PAYMENT = 'pending_payment',
}

export const INVOICE_STATUSES = [
  InvoiceStatus.PENDING_PAYMENT,
  InvoiceStatus.PAID_IN_FULL,
  InvoiceStatus.PARTIALLY_PAID,
  InvoiceStatus.CANCELED,
];

export const PAYMENTS_STATUSES = [InvoiceStatus.PAID_IN_FULL, InvoiceStatus.PARTIALLY_PAID];

export const DEFAULT_INVOICE_SELECTED_STATUSES = [
  InvoiceStatus.PAID_IN_FULL,
  InvoiceStatus.PARTIALLY_PAID,
  InvoiceStatus.PENDING_PAYMENT,
];

export const DEFAULT_PAYMENTS_SELECTED_STATUSES = [
  InvoiceStatus.PAID_IN_FULL,
  InvoiceStatus.PARTIALLY_PAID,
];

export enum PaymentStatus {
  UNPAID = 'unpaid',
  PENDING = 'pending',
  PAID = 'paid',
  FAILED = 'failed',
}

export const StatusLabel: Record<InvoiceStatus | PaymentStatus, string> = {
  [InvoiceStatus.CANCELED]: 'Canceled',
  [InvoiceStatus.PAID_IN_FULL]: 'Paid in Full',
  [InvoiceStatus.PARTIALLY_PAID]: 'Partially Paid',
  [InvoiceStatus.PENDING_PAYMENT]: 'Pending Payment',

  [PaymentStatus.FAILED]: 'Failed',
  [PaymentStatus.PAID]: 'Paid',
  [PaymentStatus.PENDING]: 'Pending',
  [PaymentStatus.UNPAID]: 'Unpaid',
};
