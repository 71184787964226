import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';

import popupStore from 'src/stores/popup-store';

import { Language, Locale, PopupName } from 'src/constants';
import { copyToClipboard } from 'src/utils';
import type { CopyToClipboardButtonProps } from './copy-to-clipboard-button.props';

import './copy-to-clipboard-button.scss';

const CopyToClipboardButton = observer(
  ({ children, language = Language.ENGLISH, title, url }: CopyToClipboardButtonProps) => {
    const handleCopy = useCallback(() => {
      const { CLOSE, COPIED, PAGE_LINK_HAS_BEEN_COPIED } = Locale[language];
      popupStore.showPopup(PopupName.WARN, {
        buttonLabel: CLOSE,
        text: PAGE_LINK_HAS_BEEN_COPIED,
        title: COPIED,
      });
    }, [language]);

    const handleCopyButtonClick = useCallback(() => {
      void copyToClipboard(url, handleCopy);
    }, [url, handleCopy]);

    return (
      <button className="copy-to-clipboard-button" title={title} onClick={handleCopyButtonClick}>
        {children}
      </button>
    );
  }
);

export default CopyToClipboardButton;
