import type { SyntheticEvent } from 'react';
import { makeAutoObservable, reaction } from 'mobx';

import invoicesStore, { InvoicesStore } from './invoices-store';
import paymentsStore, { PaymentsStore } from './payments-store';

import { AppRoute, PageDataType, Title } from 'src/constants';
import type { IListInvoice, IListPayment } from 'src/interfaces';

class SearchWindowStore {
  isShown: boolean = false;
  store: InvoicesStore | PaymentsStore | null = null;

  init(pathname: string) {
    this.clear();

    const isPaymentsPage = pathname === AppRoute.PAYMENTS;

    if (isPaymentsPage) {
      this.setStore(paymentsStore);
    } else {
      this.setStore(invoicesStore);
    }
  }

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    reaction(
      () => this.isShown,
      (isShown: boolean) => {
        if (!isShown && !!this.store) {
          this.clear();
        }
      }
    );
  }

  setIsShown(isShown: boolean) {
    this.isShown = isShown;
  }

  setStore(store: InvoicesStore | PaymentsStore | null) {
    this.store = store;
  }

  show() {
    this.setIsShown(true);
  }

  hide() {
    this.setIsShown(false);
  }

  loadMore() {
    this.store?.loadMore();
  }

  clearSearch() {
    this.store?.setSearchString('');
  }

  clear() {
    this.setStore(null);
    this.clearSearch();
  }

  get searchString(): string {
    return this.store?.searchString || '';
  }

  get shouldShowClearButton(): boolean {
    return !!this.searchString;
  }

  get isLoading(): boolean {
    return !this.store || this.store?.isLoading;
  }

  get items(): IListInvoice[] | IListPayment[] {
    return this.store?.items || [];
  }

  get itemsType(): PageDataType {
    return this.store && this.store === paymentsStore
      ? PageDataType.PAYMENTS
      : PageDataType.INVOICES;
  }

  get hasMore(): boolean {
    return Boolean(this.store?.hasMore);
  }

  get inputProps() {
    return {
      value: this.searchString,
      onChange: (evt: SyntheticEvent<HTMLInputElement>) =>
        this.store?.setSearchString(evt.currentTarget.value),
    };
  }

  get title(): string {
    return this.store && this.store === paymentsStore
      ? Title.SEARCH_INPUT_PAYMENTS
      : Title.SEARCH_INPUT_INVOICES;
  }
}

export default new SearchWindowStore();
