import { makeAutoObservable, reaction } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

import type DateRangeStore from 'src/stores/common/date-range-store';
import PagingDataStore from 'src/stores/data-store/paging-data-store';
import salesFilterStore from 'src/stores/filters/sales-filter-store';
import popupStore from 'src/stores/popup-store';
import socketStore from 'src/stores/socket-store';

import { mapApiToInvoice } from 'src/adapters';
import { fetchInvoicesExport } from 'src/api';
import {
  ALL,
  ApiRoute,
  AppRoute,
  ErrorText,
  InvoiceStatus,
  MAX_TABLE_LIST_COUNT_PER_PAGE,
  PopupName,
  SEARCH_DEBOUNCE,
  SEARCH_STRING_MIN_LENGTH,
} from 'src/constants';
import { getApiFormatDateRange, getCurrentRoute } from 'src/utils';
import type { IApiDetailedInvoice, IApiListInvoice, IStatistics } from 'src/interfaces';

interface IInvoicesStoreFilter {
  created_at?: {
    from: string;
    to: string;
  };
  search_str?: string;
  seller?: string; // id
  status?: InvoiceStatus[];
}

const SOCKET_LISTENER_ID = uuidv4();

class InvoicesStore {
  init() {
    void this.invoicesDataStore.load();

    socketStore.addStatisticsListener({
      id: SOCKET_LISTENER_ID,
      cb: this.updateList,
    });
  }

  filtersSetId: string = uuidv4();
  isDownloading: boolean = false;
  searchString: string = '';

  invoicesDataStore = new PagingDataStore<IApiListInvoice, IInvoicesStoreFilter>({
    url: ApiRoute.INVOICES,
    data: [],
    pagingProps: {
      offset: 0,
      limit: MAX_TABLE_LIST_COUNT_PER_PAGE,
    },
    filters: {
      status: salesFilterStore.selectedStatuses,
      created_at: getApiFormatDateRange(salesFilterStore.dateRangeStore.dateRange),
      ...(salesFilterStore.selectedSeller && { seller: salesFilterStore.selectedSeller }),
    },
  });

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    reaction(
      () => salesFilterStore.dateRangeStore.dateRange,
      () => this.applyDateFilter(salesFilterStore.dateRangeStore)
    );

    reaction(
      () => salesFilterStore.selectedStatuses,
      (statuses) => this.applyStatusFilter(statuses)
    );

    reaction(
      () => salesFilterStore.selectedSeller,
      (seller) => this.applySeller(seller)
    );

    reaction(
      () => this.searchString,
      () => this.applySearch(),
      { delay: SEARCH_DEBOUNCE }
    );
  }

  updateFilterSetId() {
    this.filtersSetId = uuidv4();
  }

  setDownloading(isDownloading: boolean) {
    this.isDownloading = isDownloading;
  }

  setSearchString(value: string) {
    this.searchString = value;
  }

  loadMore() {
    if (this.hasMore) {
      void this.invoicesDataStore.loadNextPage();
    }
  }

  applySearch() {
    const { filters } = this.invoicesDataStore;

    const trimmedStr = this.searchString.trim();

    if (trimmedStr.length >= SEARCH_STRING_MIN_LENGTH) {
      this.applyFilter({ ...filters, search_str: trimmedStr });
    } else {
      const { search_str, ...otherFilters } = filters;
      if (search_str) {
        this.applyFilter(otherFilters);
      }
    }
  }

  applySeller(seller: string) {
    let newFilters = { ...this.invoicesDataStore.filters };

    if (seller === ALL || !seller) {
      delete newFilters.seller;
    } else {
      newFilters = { ...newFilters, seller };
    }

    this.applyFilter(newFilters);
  }

  applyDateFilter(dateRangeStore: DateRangeStore) {
    let newFilters = { ...this.invoicesDataStore.filters };

    if (dateRangeStore.isEmptyDate && newFilters.created_at) {
      delete newFilters.created_at;
      this.applyFilter(newFilters);
    }

    if (dateRangeStore.isDateValid.isValid) {
      newFilters = { ...newFilters, created_at: getApiFormatDateRange(dateRangeStore.dateRange) };
      this.applyFilter(newFilters);
    }
  }

  applyStatusFilter(statuses: InvoiceStatus[]) {
    let newFilters = { ...this.invoicesDataStore.filters };

    if (!salesFilterStore.dateRangeStore.isDateValid.isValid) {
      if (!salesFilterStore.dateRangeStore.isEmptyDate) {
        salesFilterStore.dateRangeStore.clearDate();
      }
    }

    this.applyFilter({ ...newFilters, status: statuses });
  }

  applyFilter(filter: IInvoicesStoreFilter) {
    const isSkipLoadByStatus = filter.status?.length === 0;
    this.invoicesDataStore.setFilters(filter, isSkipLoadByStatus);

    if (isSkipLoadByStatus) {
      this.invoicesDataStore.setData([]);
      this.invoicesDataStore.setTotal(0);
    }

    this.updateFilterSetId();
  }

  clear() {
    this.setSearchString('');
    salesFilterStore.resetStore();
    socketStore.removeStatisticsListener(SOCKET_LISTENER_ID);
  }

  updateInvoice(updatedInvoice: IApiDetailedInvoice) {
    const invoiceId = updatedInvoice.id;
    const filterStatuses = this.invoicesDataStore.filters.status;
    const invoices = this.invoicesDataStore.getData;
    const newInvoices: IApiListInvoice[] =
      filterStatuses && !filterStatuses.includes(updatedInvoice.status)
        ? invoices.filter((invoice) => invoice.id !== invoiceId)
        : invoices.map((invoice) =>
            invoice.id === invoiceId
              ? {
                  ...updatedInvoice,
                  created_at: invoice.created_at,
                  updated_at: invoice.created_at,
                }
              : invoice
          );
    this.invoicesDataStore.setData(newInvoices);
  }

  updateList(statistics: IStatistics, isFirstStatisticsLoad?: boolean) {
    if (isFirstStatisticsLoad) {
      return;
    }

    const currentRoute = getCurrentRoute(window.location.pathname) as AppRoute;

    if (currentRoute === AppRoute.INVOICES) {
      this.invoicesDataStore.reload();
    }
  }

  get isLoading() {
    return this.invoicesDataStore.isLoading;
  }

  get hasMore() {
    return !this.invoicesDataStore.isAllDataLoaded;
  }

  get items() {
    return this.invoicesDataStore.getData.map(mapApiToInvoice);
  }

  get isResetFilterDisabled() {
    return !this.searchString && salesFilterStore.hasDefaultFilters;
  }

  async exportInvoices() {
    if (this.isDownloading) {
      return;
    }

    this.setDownloading(true);

    const {
      filters: { search_str, ...otherFilters },
    } = this.invoicesDataStore;

    const { error, isSuccess } = await fetchInvoicesExport({ filter: otherFilters });
    if (!isSuccess) {
      popupStore.showPopup(PopupName.WARN, {
        title: ErrorText.REQUEST_FAILURE,
        text: error || ErrorText.DEFAULT,
      });
    }

    this.setDownloading(false);
  }
}

export default new InvoicesStore();
export type { InvoicesStore };
