import { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { observer } from 'mobx-react-lite';

import Filters from 'src/components/common/filters';
import FiltersPanel from 'src/components/common/filters-panel';
import FinancialsStatistics from 'src/components/common/financials-statistics';
import Loader from 'src/components/common/loader';
import MobileList from 'src/components/common/mobile-list';
import Status from 'src/components/common/status';
import Table, { TableMultidata } from 'src/components/common/table';

import constantsStore from 'src/stores/constants-store';
import salesFilterStore from 'src/stores/filters/sales-filter-store';
import invoicesStore from 'src/stores/invoices-store';
import sidebarStore from 'src/stores/sidebar-store';

import useIsTablet from 'src/hooks/use-is-tablet';
import {
  InvoiceStatus,
  PageDataType,
  TABLE_MAIN_COLUMNS,
  TableType,
  TimeFormat,
} from 'src/constants';
import { formatDate, formatMoney } from 'src/utils';

import './main-page.scss';

const SALES_TABLE_CONTAINER_ID = 'sales-table-container-id';

const MainPage = observer(() => {
  const {
    filtersSetId,
    hasMore,
    isLoading,
    isDownloading,
    isResetFilterDisabled,
    items,
    clear,
    loadMore,
    exportInvoices,
  } = invoicesStore;
  const { dateRangeStore, selectedStatuses, selectedSeller, toggleStatus, setSelectedSeller } =
    salesFilterStore;
  const { isLoading: isConstantsLoading } = constantsStore;
  const isTablet = useIsTablet();

  const isExportButtonDisabled = isDownloading || !items.length || isConstantsLoading;

  useEffect(() => {
    constantsStore.init();
    invoicesStore.init();

    return () => invoicesStore.invoicesDataStore.clear();
  }, []);

  const tableItems = items.map((invoice) => {
    const isFade = invoice.status === InvoiceStatus.CANCELED;

    return {
      id: invoice.id,
      items: [
        {
          childComponent: (
            <TableMultidata
              captionText={invoice.createdAt ? formatDate(invoice.createdAt, TimeFormat.DUAL) : ''}
              isFade={isFade}
              mainText={invoice.createdAt ? formatDate(invoice.createdAt) : '-'}
              tableType={TableType.MAIN}
            />
          ),
          isFade,
        },
        { text: invoice.no, isFade },
        { text: invoice.seller, isFade },
        { text: invoice.purchaserName, isFade },
        {
          text: formatMoney(invoice.total),
          isFade,
        },
        {
          text: formatMoney(invoice.paid),
          isFade,
        },
        {
          text: formatMoney(invoice.balance),
          isFade,
        },
        {
          childComponent: <Status type={invoice.status} />,
          isFade,
        },
      ],
      onClick: () => sidebarStore.showSidebar(invoice.id),
    };
  });

  return (
    <div className="main-page">
      <FiltersPanel
        className="main-page__filters"
        isDownloading={isDownloading}
        isDisabled={isExportButtonDisabled}
        onExportData={exportInvoices}
      >
        <Filters
          filtersType={PageDataType.INVOICES}
          dateRangeStore={dateRangeStore}
          selectedStatuses={selectedStatuses}
          selectedSeller={selectedSeller}
          toggleStatus={toggleStatus}
          setSelectedSeller={setSelectedSeller}
        />
      </FiltersPanel>
      <div
        className="main-page__content scrollbar scrollbar_horizontal"
        id={SALES_TABLE_CONTAINER_ID}
        key={filtersSetId}
      >
        {isTablet || <FinancialsStatistics />}
        <InfiniteScroll
          dataLength={items.length}
          hasMore={hasMore}
          loader={<Loader />}
          next={loadMore}
          scrollableTarget={SALES_TABLE_CONTAINER_ID}
          style={{ height: '100%', overflow: 'inherit' }}
        >
          {isTablet ? (
            <Table
              headerItems={TABLE_MAIN_COLUMNS}
              isProcessing={isLoading}
              isResetFilterDisabled={isResetFilterDisabled}
              rows={tableItems}
              tableType={TableType.MAIN}
              onResetFilter={clear}
            />
          ) : (
            <MobileList
              isProcessing={isLoading}
              isResetFilterDisabled={isResetFilterDisabled}
              items={items}
              type={PageDataType.INVOICES}
              onResetFilter={clear}
            />
          )}
        </InfiniteScroll>
        {(isLoading || isConstantsLoading) && <Loader position="overlay" isDebounce />}
      </div>
    </div>
  );
});

export default MainPage;
