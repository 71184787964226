export const ApiRoute = {
  INVOICE: (invoiceId: string) => `invoices/${invoiceId}`,
  INVOICES: 'invoices',
  INVOICES_EXPORT: 'invoices/xlsx',
  INVOICE_CANCEL: (invoiceId: string) => `invoices/${invoiceId}/cancel`,
  INVOICE_PAYMENT_CREATE: (invoiceId: string) => `invoices/${invoiceId}/payment/create`,
  INVOICE_PAYMENT_RECORD: (invoiceId: string) => `invoices/${invoiceId}/payment/record`,
  PAYMENTS: 'payments',
  PAYMENTS_EXPORT: 'payments/xlsx',
  PAYMENT: (paymentId: string) => `payments/${paymentId}`,
  PAYMENT_URL: (paymentId: string) => `payments/${paymentId}/transaction-page`,
  USERS: 'users',
  WEB_SOCKET_INVOICE: (invoiceId: string) => `ws/invoices/${invoiceId}`,
  WEB_SOCKET_MANAGER_STATISTICS: 'ws/payments-stat/manager',
  WEB_SOCKET_PAYMENTS_TOTAL: 'ws/payments-total',
  WEB_SOCKET_SELLER_STATISTICS: (sellerId: string) => `ws/payments-stat/seller/${sellerId}`,
};
