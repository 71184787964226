export enum MeshulamLink {
  TEST = 'https://sandbox.meshulam.co.il',
  PROD = 'https://secure.meshulam.co.il',
}

export enum MeshulamAction {
  CLOSE = 'close',
  PAYMENT = 'payment',
  FAILED_LOAD = 'failed_to_load_page',
}
