import React from 'react';
import { observer } from 'mobx-react-lite';

import Button from 'src/components/ui/button';

import type { ICommonPopupProps } from 'src/interfaces';

const PopupPaymentStatus = observer(({ isMobile, onCloseButtonClick }: ICommonPopupProps) => {
  return (
    <>
      <div className="popup__header">
        <p className="popup__title">Payment Status</p>
        <p className="popup__text">Your payment is being processed by GROW</p>
      </div>
      <Button
        className="popup__button"
        label="Close"
        size={isMobile ? 'regular' : 'big'}
        theme="outlined"
        onClick={onCloseButtonClick}
      />
    </>
  );
});

export default PopupPaymentStatus;
