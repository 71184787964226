import Button from 'src/components/ui/button';
import { ReactComponent as ListStubIcon } from 'src/assets/icons/not-found.svg';

import type { ListStubProps } from './list-stub.props';

import './list-stub.scss';

const ListStub = ({ isResetFilterDisabled, onResetFilter }: ListStubProps) => (
  <div className="list-stub">
    <ListStubIcon className="list-stub__icon" />

    <div className="list-stub__info">
      <span className="list-stub__title">Nothing to Display</span>
      <span className="list-stub__text">Try changing your filter settings or search term</span>
    </div>

    {onResetFilter && (
      <Button
        className="list-stub__button"
        isDisabled={isResetFilterDisabled}
        label="Show All"
        size="big"
        theme="outlined"
        onClick={onResetFilter}
      />
    )}
  </div>
);

export default ListStub;
