import { useEffect } from 'react';

import type { ILocaleType } from 'src/interfaces';

const useLocale = (locale: ILocaleType) => {
  useEffect(() => {
    const isHebrewVersion = locale === 'he';
    const direction = isHebrewVersion ? 'rtl' : 'ltr';
    document.documentElement.setAttribute('dir', direction);
    document.documentElement.setAttribute('lang', locale);
  }, [locale]);
};

export default useLocale;
