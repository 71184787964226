import { Language } from './language';

import { en, he } from 'src/locales';
import type { ILocaleType } from 'src/interfaces';

export type LocaleProperty = keyof (typeof en | typeof he);

export const LocaleValue: Record<Language, ILocaleType> = {
  [Language.ENGLISH]: 'en',
  [Language.HEBREW]: 'he',
};

export const Locale = { [Language.ENGLISH]: en, [Language.HEBREW]: he };
