import { makeAutoObservable } from 'mobx';

import globalAppStore from './global-app-store';

import { fetchUsers } from 'src/api';
import { ALL } from 'src/constants';
import type { IPerson } from 'src/interfaces';

class ConstantsStore {
  init() {
    if (globalAppStore.user && !this.isLoading && !this.sellers.length) {
      this.loadSellers();
    }
  }

  isLoading: boolean = false;
  sellers: IPerson[] = [];

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setSellers(sellers: IPerson[]) {
    this.sellers = sellers;
  }

  get sellersOptions() {
    return this.sellers.map((seller) => ({ id: seller.id, value: seller.fullName }));
  }

  get sellersOptionsWithAll() {
    const { sellers } = this;

    return sellers.length ? [{ id: '', value: ALL }, ...this.sellersOptions] : [];
  }

  async loadSellers() {
    this.setIsLoading(true);

    const sellers = await fetchUsers();

    if (sellers) {
      this.setSellers(sellers);
    } else {
      this.setSellers([]);
    }

    this.setIsLoading(false);
  }
}

export default new ConstantsStore();
