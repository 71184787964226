import { useRef } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import Logo from 'src/components/common/logo';
import MobileMenu from './components/mobile-menu';

import useOnClickOutside from 'src/hooks/use-on-click-outside';
import useToggle from 'src/hooks/use-toggle';
import type { MobileHeaderProps } from './mobile-header.props';

import './mobile-header.scss';

const MobileHeader = observer(({ title, userName }: MobileHeaderProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isMenuShown, toggleIsMenuShown] = useToggle();
  useOnClickOutside(ref, toggleIsMenuShown, !isMenuShown);

  return (
    <div
      className={classNames('mobile-header', isMenuShown && 'mobile-header_shown-menu')}
      ref={ref}
    >
      <div className="mobile-header__wrapper">
        <Logo className="mobile-header__logo" />
        <p className="mobile-header__title">{title}</p>
        <button
          className="mobile-header__hamburger"
          aria-label="Toggle menu"
          onClick={toggleIsMenuShown}
        />
        <div
          className={classNames(
            'mobile-header__dropdown',
            isMenuShown && 'mobile-header__dropdown_shown'
          )}
        >
          <MobileMenu userName={userName} />
        </div>
      </div>
    </div>
  );
});

export default MobileHeader;
