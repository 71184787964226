export const EN_LOCALES = {
  CEMETERY_WEBSITE: 'Cemetery Website',
  CHECK_THE_PAGE_ADDRESS: 'Check the page address',
  CLOSE: 'Close',
  COPIED: 'Copied',
  COPY_URL: 'Copy URL',
  CURRENT_PAYMENT: 'Current Payment',
  DOWNLOAD_FREE_MOBILE_APP: 'Download free mobile app',
  EMAIL: 'Email',
  GO_TO_MAIN_PAGE: 'Go to Main Page',
  GO_TO_PAYMENT_PAGE: 'Go to Payment Page',
  GO_TO_X_PAGE: 'Go to {X} page',
  INDEX_PAGE: 'Index',
  INSTALLMENTS: 'Installments',
  INVALID_PHONE: 'Invalid phone',
  INVOICE: 'Invoice',
  NAME: 'Name',
  NUMBER_OF_PAYMENTS: 'Number of Payments',
  PAGE_LINK_HAS_BEEN_COPIED: 'Page link has been copied to the clipboard',
  PAGE_NOT_FOUND: 'Page not found',
  PAID_ON: 'Paid on',
  PAY_WITH_BANK_CARD: 'Pay with Bank Card',
  PAY_WITH_BIT: 'Pay with Bit',
  PAYMENT: 'Payment',
  PAYMENT_ERROR: 'Payment error',
  PAYMENT_FAILED: 'Payment Failed',
  PAYMENT_FORM_EXPIRED: 'Payment form expired',
  PAYMENT_SUCCESSFUL: 'Payment Successful',
  PHONE: 'Phone',
  PLEASE_CONTACT_MANAGER: 'Please contact Manager',
  PRIVACY_POLICY: 'Privacy Policy',
  PRODUCTS_AND_SERVICES: 'Products & Services',
  PURCHASER_DETAILS: 'Purchaser Details',
  REMAINING_BALANCE: 'Remaining Balance',
  SHARE: 'Share',
  SHARE_PAYMENT_PAGE: 'Share Payment Page',
  SOMETHING_WENT_WRONG: 'Something went wrong',
  TOTAL_AMOUNT: 'Total Amount',
  YOU_CAN_NOW_CLOSE_THIS_PAGE: 'You can now close this page',
};
