import React, { FocusEvent, useId } from 'react';
import InputMask from 'react-input-mask';
import classNames from 'classnames';

import InputPlaceholder from 'src/components/ui/input-placeholder';
import Label from 'src/components/ui/label';
import TextError from 'src/components/ui/text-error';

import { CYRILLIC_SYMBOLS_REG_EXP } from 'src/constants';
import type { InputProps } from './input.props';

import '../input.scss';

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      hasCounter,
      icon,
      isDisabled = false,
      isValid = true,
      label,
      mask = '',
      maskPlaceholder = '',
      maxLength,
      placeholder,
      rows,
      size = 'regular',
      textError = '',
      value,
      onBlur,
      onChange,
      onFocus,
    },
    ref
  ) => {
    const [isFocused, setIsFocused] = React.useState(false);
    const isHidePlaceholder = isFocused || !!value;
    const id = useId();
    const inputClasses = classNames('input', className, {
      input_label: !!label,
      input_placeholder: !!placeholder,
      input_mask: !!mask,
      input_disabled: isDisabled,
      input_focus: isFocused,
      input_icon: !!icon,
      input_multi: !!rows,
      input_invalid: !isValid && !isFocused,
      input_flattened: size === 'flattened',
      'input_with-counter': hasCounter,
    });
    const labelClasses = classNames('input__label', {
      label_disabled: isDisabled,
      label_invalid: !isValid && !isFocused,
      label_placeholder: !value && !isFocused && !placeholder,
    });

    const handleFocus = () => {
      setIsFocused(true);
      onFocus?.();
    };
    const handleBlur = (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setIsFocused(false);
      onBlur?.(event.target.value);
    };
    const handleChange = (
      event: React.SyntheticEvent<HTMLInputElement> | React.SyntheticEvent<HTMLTextAreaElement>
    ) => {
      const inputValue = event.currentTarget.value;
      const testedValue = inputValue.replace(CYRILLIC_SYMBOLS_REG_EXP, '');

      if (inputValue === testedValue) {
        onChange(testedValue);
      }
    };

    const renderInputMask = () => (
      <>
        <InputMask
          className="input__field"
          id={id}
          dir="auto"
          disabled={isDisabled}
          mask={mask}
          maskPlaceholder={maskPlaceholder}
          type="text"
          value={value}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
        />
        {icon}
      </>
    );

    const renderInput = () => {
      return rows ? (
        <textarea
          className="scrollbar input__textarea"
          id={id}
          dir="auto"
          disabled={isDisabled}
          maxLength={maxLength}
          rows={rows}
          value={value}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
        />
      ) : (
        <>
          <input
            className="input__field"
            id={id}
            ref={ref}
            dir="auto"
            disabled={isDisabled}
            maxLength={maxLength}
            value={value}
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
          />
          {icon}
        </>
      );
    };

    return (
      <div className={inputClasses}>
        {label && <Label className={labelClasses} htmlFor={id} label={label} />}
        {placeholder && (
          <InputPlaceholder hide={isHidePlaceholder} isValid={isValid} placeholder={placeholder} />
        )}
        {mask ? renderInputMask() : renderInput()}
        {hasCounter && (
          <div className="input__counter">
            {value.length}
            {maxLength ? `/${maxLength}` : ''}
          </div>
        )}
        {!isValid && !isFocused && !isDisabled ? <TextError textError={textError} /> : null}
      </div>
    );
  }
);

export default Input;
