import { makeAutoObservable, reaction } from 'mobx';

import DateRangeStore from 'src/stores/common/date-range-store';
import globalAppStore from 'src/stores/global-app-store';
import socketStore from 'src/stores/socket-store';

import {
  DatePeriod,
  DEFAULT_INVOICE_SELECTED_STATUSES,
  InvoiceStatus,
  UserRole,
} from 'src/constants';
import { getApiFormatDateRange } from 'src/utils';
import type { IDateRange } from 'src/interfaces';

class SalesFilterStore {
  dateRangeStore = new DateRangeStore(DatePeriod.WEEK);
  selectedSeller: string = '';
  selectedStatuses: InvoiceStatus[] = DEFAULT_INVOICE_SELECTED_STATUSES;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    reaction(
      () => globalAppStore.user,
      (user) => {
        if (user && user.role !== UserRole.MANAGER) {
          this.setSelectedSeller(user.id);
        }
      },
      { fireImmediately: true }
    );

    reaction(
      () => [
        this.selectedSeller,
        globalAppStore.token,
        globalAppStore.user,
        this.selectedStatuses,
        this.dateRangeStore.dateRange,
      ],
      () => {
        if (!globalAppStore.token || !globalAppStore.user || !this.selectedStatuses.length) {
          socketStore.unsubscribeFromStatistics();
          return;
        }

        if (!this.dateRangeStore.isDateValid.isValid) {
          return;
        }

        const filter = {
          status: this.selectedStatuses,
          created_at: getApiFormatDateRange(this.dateRangeStore.dateRange),
        };

        if (this.selectedSeller) {
          socketStore.subscribeToSellerStatistics(this.selectedSeller, filter);
        } else if (globalAppStore.isManager) {
          socketStore.subscribeToManagerStatistics(filter);
        }
      },
      { fireImmediately: true }
    );
  }

  setSelectedSeller(sellerId: string) {
    this.selectedSeller = sellerId;
  }

  setDefaultSeller() {
    if (globalAppStore.isManager) {
      this.setSelectedSeller('');
    }
  }

  setSelectedStatuses(selectedStatuses: InvoiceStatus[]) {
    this.selectedStatuses = selectedStatuses;
  }

  toggleStatus(status: InvoiceStatus) {
    if (this.selectedStatuses.includes(status)) {
      this.setSelectedStatuses(this.selectedStatuses.filter((item) => status !== item));
    } else {
      this.setSelectedStatuses([...this.selectedStatuses, status]);
    }
  }

  applyFilters(selectedSeller: string, selectedStatuses: InvoiceStatus[], dateRange: IDateRange) {
    this.dateRangeStore.setDate(dateRange);
    this.setSelectedSeller(selectedSeller);
    this.setSelectedStatuses(selectedStatuses);
  }

  resetStore() {
    this.dateRangeStore.setActivePeriod(DatePeriod.WEEK);
    this.setSelectedStatuses(DEFAULT_INVOICE_SELECTED_STATUSES);
    this.setDefaultSeller();
  }

  get hasDefaultFilters() {
    return (
      this.dateRangeStore.activePeriod === DatePeriod.WEEK &&
      DEFAULT_INVOICE_SELECTED_STATUSES.length === this.selectedStatuses.length &&
      (!globalAppStore.isManager || !this.selectedSeller) &&
      DEFAULT_INVOICE_SELECTED_STATUSES.every((status) => this.selectedStatuses.includes(status))
    );
  }
}

export default new SalesFilterStore();
