import { doGet, doPost, doPut } from './axios-config';

import { mapApiToDetailedInvoice, mapApiToPayment } from 'src/adapters';
import { ApiResponseStatus, ApiRoute } from 'src/constants';
import { downloadFile, getFileName } from 'src/utils';
import type {
  IApiDetailedInvoice,
  IApiPayment,
  IExportInvoicesParams,
  IPayment,
  TRecordPaymentMethod,
} from 'src/interfaces';

export async function fetchInvoice(id: string) {
  try {
    const result = await doGet<IApiDetailedInvoice>(ApiRoute.INVOICE(id));

    return mapApiToDetailedInvoice(result.data);
  } catch (err) {
    console.log(err);
    return null;
  }
}

export async function fetchInvoicesExport(params: IExportInvoicesParams) {
  try {
    const response = await doGet(ApiRoute.INVOICES_EXPORT, {
      params: { filter: JSON.stringify(params.filter) },
      responseType: 'blob',
    });
    downloadFile(response, getFileName('invoices', params));
    return { isSuccess: true };
  } catch (err: any) {
    console.log(err);
    return { isSuccess: false, error: err.response?.data?.message };
  }
}

export async function fetchInvoicePaymentCreate(
  id: string,
  data: { amount: number; installments: number }
): Promise<
  | { data: IPayment; error?: never; isSuccess: true }
  | { data?: never; error?: string; isSuccess: false }
> {
  try {
    const result = await doPost<IApiPayment>(ApiRoute.INVOICE_PAYMENT_CREATE(id), data);

    const isSuccess = result.status === ApiResponseStatus.SUCCESS_CREATED;
    return isSuccess
      ? {
          isSuccess,
          data: mapApiToPayment(result.data),
        }
      : { isSuccess };
  } catch (err: any) {
    console.log(err);
    return { isSuccess: false, error: err.response?.data?.message };
  }
}

export async function fetchInvoicePaymentRecord(
  id: string,
  data: {
    amount: number;
    payment_type: TRecordPaymentMethod;
    paid_at: string;
  }
): Promise<
  | { data: IPayment; error?: never; isSuccess: true }
  | { data?: never; error?: string; isSuccess: false }
> {
  // WARN: DO NOT TEST RECORD PROCESS ON DEV!
  if (window.PREVENT_PAYMENTS_RECORD) {
    console.info('POST', ApiRoute.INVOICE_PAYMENT_RECORD(id), data);
    return new Promise((resolve) =>
      setTimeout(
        () =>
          resolve({ isSuccess: false, error: 'The operation is forbidden for this installation' }),
        500
      )
    );
  }

  try {
    const result = await doPost<IApiPayment>(ApiRoute.INVOICE_PAYMENT_RECORD(id), data);

    const isSuccess = result.status === ApiResponseStatus.SUCCESS_CREATED;
    return isSuccess
      ? {
          isSuccess,
          data: mapApiToPayment(result.data),
        }
      : { isSuccess };
  } catch (err: any) {
    console.log(err);
    return { isSuccess: false, error: err.response?.data?.message };
  }
}

export async function fetchInvoiceCancel(
  id: string
): Promise<{ isSuccess: boolean; error?: string }> {
  try {
    const result = await doPut<never>(ApiRoute.INVOICE_CANCEL(id));

    return { isSuccess: result.status === ApiResponseStatus.SUCCESS };
  } catch (err: any) {
    console.log(err);
    return { isSuccess: false, error: err.response?.data?.message };
  }
}
