import type { IExportInvoicesParams, IExportPaymentsParams } from 'src/interfaces';

export function getFileName(
  prefix: string,
  params: IExportInvoicesParams | IExportPaymentsParams
): string {
  return `${prefix}_${Object.entries(params.filter)
    .filter(([propKey, propValue]) => !!propValue && propKey !== 'seller')
    .flat()
    .map((value) =>
      typeof value === 'object' && !Array.isArray(value)
        ? Object.entries(value)
            .map(([key, property]) => `${key}-${property}`)
            .join(' ')
        : value
    )
    .join('-')
    .replace(/[_ ]/g, '-')}.xlsx`;
}
