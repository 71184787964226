import React from 'react';
import { observer } from 'mobx-react-lite';

import { formatMoney } from 'src/utils';
import type { FinancialsProps } from './financials.props';

import './financials.scss';

const Financials = observer(
  ({ balance, isPaidOnly, isShortTitles, location, paid, total }: FinancialsProps) => {
    const data = isPaidOnly
      ? [{ label: 'Paid', value: paid }]
      : [
          { label: isShortTitles ? 'Total' : 'Total Cost', value: total },
          { label: 'Paid', value: paid },
          { label: isShortTitles ? 'Balance' : 'Remaining Balance', value: balance },
        ];

    return (
      <div className={`financials financials_${location}`}>
        {data.map(({ label, value }) => (
          <div className="financials__info" key={label}>
            <span className="financials__info-name">{label}:</span>
            <span className="financials__info-value">
              {typeof value === 'undefined' ? '-' : formatMoney(value, false, true)}
            </span>
          </div>
        ))}
      </div>
    );
  }
);

export default Financials;
