import { useCallback } from 'react';

export default function useMouseStopPropagation() {
  const mouseDownListener = useCallback((event: MouseEvent) => {
    event.stopPropagation();
  }, []);

  const toggleMouseListener = useCallback(
    (shouldListen: boolean) => {
      if (shouldListen) {
        document.addEventListener('mousedown', mouseDownListener, true);
      } else {
        document.removeEventListener('mousedown', mouseDownListener, true);
      }

      return () => {
        document.removeEventListener('mousedown', mouseDownListener, true);
      };
    },
    [mouseDownListener]
  );

  return { toggleMouseListener };
}
