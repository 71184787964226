import React, { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { observer } from 'mobx-react-lite';

import Filters from 'src/components/common/filters';
import FiltersPanel from 'src/components/common/filters-panel';
import Loader from 'src/components/common/loader';
import MobileList from 'src/components/common/mobile-list';
import PaidStatistics from 'src/components/common/paid-statistics';
import Status from 'src/components/common/status';
import Table, { TableMultidata } from 'src/components/common/table';
import IconButton from 'src/components/ui/icon-button';
import { ReactComponent as DownloadIcon } from 'src/assets/icons/download-doc.svg';

import constantsStore from 'src/stores/constants-store';
import paymentsFilterStore from 'src/stores/filters/payments-filter-store';
import paymentsStore from 'src/stores/payments-store';
import sidebarStore from 'src/stores/sidebar-store';

import useIsTablet from 'src/hooks/use-is-tablet';
import {
  MIN_ISO_DATE_LENGTH,
  PageDataType,
  PaymentMethodLabel,
  TABLE_PAYMENTS_COLUMNS,
  TableType,
  TimeFormat,
} from 'src/constants';
import { formatApiToPhone, formatDate, formatMoney } from 'src/utils';
import type { IListPayment } from 'src/interfaces';

import './payments-page.scss';

const PAYMENTS_TABLE_CONTAINER_ID = 'payments-table-container-id';

const PaymentsPage = observer(() => {
  const {
    hasMore,
    filtersSetId,
    isDownloading,
    isLoading,
    isResetFilterDisabled,
    items,
    clear,
    exportPayments,
    loadMore,
  } = paymentsStore;
  const { dateRangeStore, toggleStatus, selectedStatuses, selectedSeller, setSelectedSeller } =
    paymentsFilterStore;
  const { isLoading: isConstantsLoading } = constantsStore;
  const isTablet = useIsTablet();
  const isExportButtonDisabled = isDownloading || !items.length || isConstantsLoading;

  useEffect(() => {
    constantsStore.init();
    paymentsStore.init();

    return () => paymentsStore.paymentsDataStore.clear();
  }, []);

  const handleDocumentButtonClick = React.useCallback(
    (payment: IListPayment) => () => {
      window.open(payment.meshulamInvoiceUrl, '_blank', 'noopener, noreferrer');
    },
    []
  );

  const tableItems = items.map((payment) => {
    return {
      id: payment.id,
      items: [
        {
          childComponent: (
            <TableMultidata
              captionText={
                payment.paidAt && payment.paidAt.length === MIN_ISO_DATE_LENGTH
                  ? formatDate(payment.paidAt, TimeFormat.DUAL)
                  : ''
              }
              mainText={payment.paidAt ? formatDate(payment.paidAt) : '-'}
              tableType={TableType.PAYMENTS}
            />
          ),
        },
        { text: payment.invoiceNo },
        { text: payment.seller },
        {
          childComponent: (
            <TableMultidata
              captionText={formatApiToPhone(payment.purchaserPhone)}
              mainText={payment.purchaserName}
              tableType={TableType.PAYMENTS}
            />
          ),
        },
        {
          text: PaymentMethodLabel[payment.type],
        },
        {
          text: formatMoney(payment.paid),
        },
        {
          text: formatMoney(payment.balance),
        },
        {
          childComponent: <Status type={payment.invoiceStatus} />,
        },
        {
          childComponent: (
            <IconButton
              ariaLabel="Download payment documents"
              icon={<DownloadIcon />}
              isDisabled={!payment.meshulamInvoiceUrl}
              onClick={handleDocumentButtonClick(payment)}
            />
          ),
        },
      ],
      onClick: () => sidebarStore.showSidebar(payment.invoiceId),
    };
  });

  return (
    <div className="payments-page">
      <FiltersPanel
        className="payments-page__filters"
        isDownloading={isDownloading}
        isDisabled={isExportButtonDisabled}
        onExportData={exportPayments}
      >
        <Filters
          filtersType={PageDataType.PAYMENTS}
          dateRangeStore={dateRangeStore}
          selectedStatuses={selectedStatuses}
          selectedSeller={selectedSeller}
          toggleStatus={toggleStatus}
          setSelectedSeller={setSelectedSeller}
        />
      </FiltersPanel>
      <div
        className="payments-page__content scrollbar scrollbar_horizontal"
        id={PAYMENTS_TABLE_CONTAINER_ID}
        key={filtersSetId}
      >
        {isTablet || <PaidStatistics />}
        <InfiniteScroll
          dataLength={items.length}
          hasMore={hasMore}
          loader={<Loader />}
          next={loadMore}
          scrollableTarget={PAYMENTS_TABLE_CONTAINER_ID}
          style={{ height: '100%', overflow: 'inherit' }}
        >
          {isTablet ? (
            <Table
              headerItems={TABLE_PAYMENTS_COLUMNS}
              isProcessing={isLoading}
              isResetFilterDisabled={isResetFilterDisabled}
              rows={tableItems}
              tableType={TableType.PAYMENTS}
              onResetFilter={clear}
            />
          ) : (
            <MobileList
              isProcessing={isLoading}
              isResetFilterDisabled={isResetFilterDisabled}
              items={items}
              type={PageDataType.PAYMENTS}
              onResetFilter={clear}
            />
          )}
        </InfiniteScroll>
        {(isLoading || isConstantsLoading) && <Loader position="overlay" isDebounce />}
      </div>
    </div>
  );
});

export default PaymentsPage;
