import { InvoiceStatus, PaymentStatus } from 'src/constants';

export const getStatusModifier = (status: InvoiceStatus | PaymentStatus) => {
  return status === InvoiceStatus.PAID_IN_FULL
    ? 'paid-in-full'
    : status === InvoiceStatus.PARTIALLY_PAID
    ? 'partially-paid'
    : status === InvoiceStatus.PENDING_PAYMENT
    ? 'pending-payment'
    : status;
};
