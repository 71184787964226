import React from 'react';
import { observer } from 'mobx-react-lite';

import Button from 'src/components/ui/button';
import ShareButton from './components/share-button';

import popupStore from 'src/stores/popup-store';

import { Language, Locale, PopupName, SHARE_BUTTONS } from 'src/constants';
import type { ICommonPopupProps } from 'src/interfaces';

const PopupShare = observer(({ isMobile, onCloseButtonClick }: ICommonPopupProps) => {
  const { language = Language.ENGLISH, title, url } = popupStore.getPopupState(PopupName.SHARE);
  const { CLOSE, SHARE_PAYMENT_PAGE } = Locale[language];

  return (
    <>
      <div className="popup__header">
        <p className="popup__title">{SHARE_PAYMENT_PAGE}</p>
      </div>
      <div className="popup__items-wrapper">
        {SHARE_BUTTONS.map((type) => (
          <ShareButton key={type} language={language} title={title} type={type} url={url} />
        ))}
      </div>
      <Button
        className="popup__button"
        label={CLOSE}
        size={isMobile ? 'regular' : 'big'}
        tag="button"
        theme="outlined"
        onClick={onCloseButtonClick}
      />
    </>
  );
});

export default PopupShare;
