import classNames from 'classnames';

import { PUBLIC_URL } from 'src/constants';
import type { LogoProps } from './logo.props';

import './logo.scss';

const Logo = ({ className }: LogoProps) => (
  <img
    className={classNames('logo', className)}
    alt="Bezikaron logo"
    height={36}
    src={`${PUBLIC_URL}/logos/logo-small-white.svg`}
    width={36}
  />
);

export default Logo;
