export enum PopupName {
  CONFIRM = 'confirm',
  CREATE_PAYMENT = 'create-payment',
  SALES_FILTERS = 'sales-filters',
  PAYMENTS_FILTERS = 'payments-filters',
  PAYMENT_STATUS = 'payment-status',
  RECORD_PAYMENT = 'record-payment',
  SHARE = 'share',
  WARN = 'warn',
}

export const PopupTitle = {
  CANCEL_INVOICE: 'Cancel Invoice?',
  CREATE_PAYMENT: 'Create Payment Page',
  DELETE_PAYMENT: 'Delete Payment?',
  PAYMENT_DETAILS: 'Payment Details',
  RECORD_PAYMENT: 'Record a Payment',
  SUCCESS: 'Success!',
};

export const PopupText = {
  CANCEL_INVOICE: 'Are you sure you want to cancel this invoice and all its data?',
  DELETE_PAYMENT: 'Are you sure you want to cancel this payment and payment page?',
};
