import React, {
  FocusEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from 'react';
import Flatpickr from 'react-flatpickr';
import InputMask from 'react-input-mask';
import classNames from 'classnames';
import dayjs from 'dayjs';

import InputPlaceholder from 'src/components/ui/input-placeholder';
import Label from 'src/components/ui/label';
import TextError from 'src/components/ui/text-error';
import DatePickerIcon from './components/date-picker-icon';

import useKeyUpGlobal from 'src/hooks/use-key-up-global';
import useWindowSize from 'src/hooks/use-window-size';
import {
  flatpickrLocaleFormat,
  Key,
  KeyUpGlobalConstants,
  LocaleFormat,
  Mask,
  MaskPlaceholder,
  MIN_WINDOW_HEIGHT_FOR_VERTICAL_CALENDAR,
  WindowWidth,
} from 'src/constants';
import type { InputDatePickerProps } from './input-date-picker.props';

import 'flatpickr/dist/flatpickr.css';
import './input-date-picker.scss';

const InputDatePicker = ({
  className,
  keepHorizontalLeftPosition,
  disableNextDates,
  isChecked,
  isDisabled = false,
  isSmall = false,
  isStatic = false,
  isValid = true,
  label,
  position = 'auto',
  textError = '',
  value,
  wrapperClassname,
  checkDisabledDay,
  onBlur,
  onCalendarVisibilityChange,
  onChange,
  onFocus,
}: InputDatePickerProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const id = useId();
  const { height, width } = useWindowSize();
  const { isHeightCalendarFullsizeLess, isTablet } = useMemo(
    () => ({
      isTablet: width >= WindowWidth.TABLET,
      isHeightCalendarFullsizeLess: height < MIN_WINDOW_HEIGHT_FOR_VERTICAL_CALENDAR,
    }),
    [height, width]
  );
  const isInvalidInputVisible = !isValid && !isFocused;
  const isHidePlaceholder = isFocused || !!value;

  const inputClasses = classNames(
    'input',
    'input_icon',
    'input-date-picker',
    className,
    isSmall && 'input-date-picker_flattened',
    {
      input_flattened: isSmall,
      input_disabled: isDisabled,
      input_invalid: isInvalidInputVisible,
    }
  );

  const labelClasses = classNames('input__label', {
    label_disabled: isDisabled,
    label_invalid: isInvalidInputVisible,
  });

  const handleChange = (
    event: SyntheticEvent<HTMLInputElement> | SyntheticEvent<HTMLTextAreaElement>
  ) => onChange(event.currentTarget.value);

  const handleDatePick = (value: Date[]) => {
    const formattedValue = dayjs(value[0]).format(LocaleFormat.he);

    onChange(formattedValue);
  };

  const handleFocus = (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setIsFocused(true);
    onFocus?.(event);
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setIsFocused(false);
    onBlur?.(event);
  };

  const keyDownListener = useCallback((event: KeyboardEvent) => {
    if (event.key === Key.ESCAPE) {
      event.stopPropagation();
    }
  }, []);

  const fp = useRef<Flatpickr>(null);

  const toggleCalendar = React.useCallback(() => {
    if (!fp?.current?.flatpickr) {
      return;
    }

    fp.current.flatpickr.toggle();
  }, []);

  useEffect(() => {
    if (isCalendarOpen) {
      window.addEventListener('keydown', keyDownListener, true);
    } else {
      window.removeEventListener('keydown', keyDownListener, true);
    }

    return () => {
      window.removeEventListener('keydown', keyDownListener, true);
    };
  }, [isCalendarOpen, keyDownListener]);

  useKeyUpGlobal(
    Key.ESCAPE,
    toggleCalendar,
    KeyUpGlobalConstants.CALENDAR_ORDER_IN_KEY_QUEUE,
    !isCalendarOpen
  );

  return (
    <Flatpickr
      className={wrapperClassname}
      options={{
        allowInput: true,
        allowInvalidPreload: true,
        altInputClass: 'hide',
        clickOpens: false,
        dateFormat: flatpickrLocaleFormat.he,
        ...(checkDisabledDay && { disable: [checkDisabledDay] }),
        disableMobile: true,
        ...(disableNextDates && { maxDate: 'today' }),
        locale: {
          weekdays: {
            shorthand: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
            longhand: [
              'Sunday',
              'Monday',
              'Tuesday',
              'Wednesday',
              'Thursday',
              'Friday',
              'Saturday',
            ],
          },
        },
        monthSelectorType: 'static',
        nextArrow: `<svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.3 6a.9.9 0 0 1-.264.636l-4.8 4.8a.9.9 0 1 1-1.272-1.272L5.127 6 .964 1.836A.9.9 0 1 1 2.236.564l4.8 4.8A.9.9 0 0 1 7.3 6z" fill="#444444"/></svg>`,
        prevArrow: `<svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M.6 6a1 1 0 0 1 .293-.707l4.8-4.8a1 1 0 1 1 1.414 1.414L3.014 6l4.093 4.093a1 1 0 0 1-1.414 1.414l-4.8-4.8A1 1 0 0 1 .6 6z" fill="#444444"/></svg>`,
        position:
          isTablet && isHeightCalendarFullsizeLess
            ? `above ${keepHorizontalLeftPosition ? 'left' : 'right'}`
            : position,
        static: isStatic,
        wrap: true,
      }}
      ref={fp}
      value={value}
      onChange={handleDatePick}
      onClose={(_selectedDates, _dateStr, instance) => {
        setIsCalendarOpen(false);
        onCalendarVisibilityChange?.(false);
      }}
      onOpen={(_selectedDates, _dateStr, instance) => {
        setIsCalendarOpen(true);
        onCalendarVisibilityChange?.(true);
        if (!isStatic) {
          setTimeout(() => {
            instance.calendarContainer.focus();
          }, 0);
        } else {
          setTimeout(() => instance.calendarContainer.focus(), 0);
        }
      }}
      onDayCreate={(_dObj, _dStr, _fp, dayElem) => {
        dayElem.setAttribute('tabindex', '0');
      }}
      onReady={(_selectedDates, _dateStr, instance) => {
        const prevMonth = instance.prevMonthNav;
        const nextMonth = instance.nextMonthNav;
        const handleKeyDown = (event: KeyboardEvent) => {
          if (event.key === Key.ENTER) {
            (event as any).currentTarget.click();
          }
        };

        [prevMonth, nextMonth].forEach((month) => {
          month.setAttribute('tabindex', '0');
          month.addEventListener('keydown', handleKeyDown);
        });

        instance.calendarContainer.setAttribute('tabindex', '0');
      }}
    >
      <div className={inputClasses}>
        {label && <Label className={labelClasses} htmlFor={id} label={label} />}
        <InputPlaceholder
          hide={isHidePlaceholder}
          isValid={isValid}
          placeholder={MaskPlaceholder.DATE}
        />
        <InputMask
          className="input__field"
          id={id}
          data-input
          disabled={isDisabled}
          mask={Mask.DATE}
          maskPlaceholder=""
          value={value}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
        />
        <DatePickerIcon
          isChecked={isChecked}
          isDisabled={isDisabled}
          toggleCalendar={toggleCalendar}
        />
        {isInvalidInputVisible && !isDisabled ? <TextError textError={textError} /> : null}
      </div>
    </Flatpickr>
  );
};

export default InputDatePicker;
