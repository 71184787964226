import { observer } from 'mobx-react-lite';

import { ReactComponent as Arrow } from 'src/assets/icons/arrow.svg';
import { ReactComponent as IconClear } from 'src/assets/icons/cross.svg';

import searchWindowStore from 'src/stores/search-window-store';

import type { MobileSearchHeaderProps } from './mobile-search-header.props';

import './mobile-search-header.scss';

const MobileSearchHeader = observer(({ title, onBackButtonClick }: MobileSearchHeaderProps) => {
  const { inputProps, shouldShowClearButton, clearSearch } = searchWindowStore;

  return (
    <div className="mobile-search-header">
      <div className="mobile-search-header__content">
        <button
          className="mobile-search-header__button  mobile-search-header__button_back"
          aria-label="Back"
          onClick={onBackButtonClick}
        >
          <Arrow height={24} width={24} />
        </button>

        <input
          className="mobile-search-header__input"
          autoFocus
          placeholder={title}
          type="text"
          {...inputProps}
        />

        {shouldShowClearButton && (
          <button
            className="mobile-search-header__button  mobile-search-header__button_clear"
            aria-label="Clear Search"
            onClick={clearSearch}
          >
            <IconClear height={12} width={12} />
          </button>
        )}
      </div>
    </div>
  );
});

export default MobileSearchHeader;
