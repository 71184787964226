export enum AppRoute {
  INVOICES = '/invoices',
  LOGIN = '/login',
  PAYMENT = '/payment',
  PAYMENTS = '/payments',
  PAYMENT_FAIL = '/payment/fail',
  PAYMENT_SUCCESS = '/payment/success',
  PRINT_INVOICE = '/print/invoice',
  NOT_FOUND = '/404',
}
