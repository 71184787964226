import React from 'react';
import classNames from 'classnames';

import ListStub from 'src/components/common/list-stub';
import TableEmptyRow from '../table-empty-row';
import TableRow from '../table-row';

import type { TableBodyProps } from './table-body.props';

import './table-body.scss';

function TableBody({
  isProcessing,
  isResetFilterDisabled,
  rows,
  tableType,
  onResetFilter,
}: TableBodyProps) {
  const isEmpty = !isProcessing && !rows.length;

  return (
    <tbody className={classNames('table-body', isEmpty && 'table-body_empty')}>
      {isEmpty ? (
        <TableEmptyRow>
          <ListStub isResetFilterDisabled={isResetFilterDisabled} onResetFilter={onResetFilter} />
        </TableEmptyRow>
      ) : (
        rows.map((row) => <TableRow key={row.id} row={row} tableType={tableType} />)
      )}
    </tbody>
  );
}

export default TableBody;
