import React from 'react';
import classNames from 'classnames';

import TableBody from './components/table-body';
import TableHeader from './components/table-header';

import type { TableProps } from './table.props';

import './table.scss';

const Table = ({
  headerItems,
  isProcessing,
  isResetFilterDisabled,
  rows,
  tableType,
  onResetFilter,
  onSortByColumn,
}: TableProps) => {
  return (
    <table className={classNames('table', `table_${tableType}`)}>
      <TableHeader items={headerItems} tableType={tableType} onSortByColumn={onSortByColumn} />
      <TableBody
        isProcessing={isProcessing}
        isResetFilterDisabled={isResetFilterDisabled}
        rows={rows}
        tableType={tableType}
        onResetFilter={onResetFilter}
      />
    </table>
  );
};

export default Table;
