import { makeAutoObservable } from 'mobx';

import globalAppStore from 'src/stores/global-app-store';

import { fetchUserSignin } from 'src/api';
import type { IUser } from 'src/interfaces';

class AuthStore {
  authError = '';
  isLoading = false;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setAuthError(error: string) {
    this.authError = error;
  }

  setGoogleAuthError() {
    this.setAuthError('Google Sign-in failed');
  }

  async authenticateUser(credential: string) {
    const onSuccess = (apiToken: string, user: IUser) => {
      globalAppStore.setToken(apiToken);
      globalAppStore.setUser(user);
    };

    const onFail = (err: string) => {
      this.setAuthError(err);
    };

    this.setIsLoading(true);
    this.setAuthError('');
    await fetchUserSignin(credential, onSuccess, onFail);
    this.setIsLoading(false);
  }
}

export default new AuthStore();
