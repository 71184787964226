import { makeAutoObservable } from 'mobx';

import type { AlertType } from 'src/components/ui/alert';

interface IAlert {
  id: string;
  desc: string;
  timeout?: number;
  title: string;
  type: AlertType;
  withIcon?: boolean;
}

class GlobalAlertStore {
  alerts: IAlert[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setAlerts(alerts: IAlert[]) {
    this.alerts = alerts;
  }

  get getAlerts() {
    return this.alerts;
  }

  addAlert(alert: IAlert) {
    this.setAlerts([...this.alerts, alert]);
  }

  deleteAlert(id: string) {
    this.setAlerts(this.alerts.filter((alert) => alert.id !== id));
  }
}

export default new GlobalAlertStore();
