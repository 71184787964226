import React, { useId } from 'react';

import Status from 'src/components/common/status';
import Checkbox from 'src/components/ui/checkbox';

import type { StatusesListProps } from './statuses-list.props';

import './statuses-list.scss';

const StatusesList = ({
  isLoading,
  statuses,
  selectedStatuses,
  toggleStatus,
}: StatusesListProps) => {
  const statusesListId = useId();

  return (
    <ul className="statuses-list">
      {statuses.map((status) => (
        <li key={status} className="statuses-list__item">
          <label className="statuses-list__item-label">
            <Checkbox
              id={`${status}-${statusesListId}`}
              isChecked={selectedStatuses.includes(status)}
              isDisabled={isLoading}
              onChange={() => toggleStatus(status)}
            />
            <Status key={status} type={status} />
          </label>
        </li>
      ))}
    </ul>
  );
};

export default StatusesList;
