import type { FC, SVGProps } from 'react';

import { ReactComponent as FacebookLogo } from 'src/assets/icons/logo-fb.svg';
import { ReactComponent as InstagramLogo } from 'src/assets/icons/logo-instagram.svg';
import { ReactComponent as WhatsappLogo } from 'src/assets/icons/logo-whatsapp.svg';
import { ReactComponent as YoutubeLogo } from 'src/assets/icons/logo-youtube.svg';

export const SOCIAL_LINKS = [
  {
    label: 'WhatsApp',
    logo: WhatsappLogo,
    url: window.WHATSAPP_PAGE_URL,
  },
  {
    label: 'Instagram',
    logo: InstagramLogo,
    url: window.INSTAGRAM_PAGE_URL,
  },
  {
    label: 'Facebook',
    logo: FacebookLogo,
    url: window.FACEBOOK_PAGE_URL,
  },
  {
    label: 'Youtube',
    logo: YoutubeLogo,
    url: window.YOUTUBE_PAGE_URL,
  },
].filter((item) => item.url) as { label: string; logo: FC<SVGProps<SVGSVGElement>>; url: string }[];
