import type { KeyboardEvent } from 'react';
import classNames from 'classnames';

import TableCol from 'src/components/common/table/components/table-col';

import { Key } from 'src/constants';
import { getTableRowId } from 'src/utils';
import type { TableRowProps } from './table-row.props';

import './table-row.scss';

const TableRow = ({ row, tableType }: TableRowProps) => {
  const handleKeyDown = (evt: KeyboardEvent) => {
    if (evt.target === evt.currentTarget && evt.key === Key.ENTER) {
      row.onClick?.();
    }
  };

  return (
    <tr
      className={classNames('table-row', `table-row_${tableType}`)}
      id={getTableRowId(row.id)}
      onClick={row.onClick}
      onKeyDown={row.onClick ? handleKeyDown : undefined}
      tabIndex={row.onClick ? 0 : -1}
    >
      {row.items.map(({ className, childComponent, isBold, isFade, text }, idx) => (
        <TableCol
          className={className}
          key={idx}
          isBold={isBold}
          isFade={isFade}
          {...(text && { text })}
        >
          {childComponent ?? null}
        </TableCol>
      ))}
    </tr>
  );
};

export default TableRow;
