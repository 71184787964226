import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import MobileHeader from 'src/components/common/mobile-header';
import TopPanel from 'src/components/common/top-panel';

import globalAppStore from 'src/stores/global-app-store';

import useWindowSize from 'src/hooks/use-window-size';
import { WindowWidth } from 'src/constants';
import type { HeaderProps } from './header.props';

const Header = observer(({ itemsStore, searchTitle, statistics, title }: HeaderProps) => {
  const { user } = globalAppStore;
  const { width } = useWindowSize();
  const { isMobile, isLaptop } = useMemo(
    () => ({
      isMobile: width < WindowWidth.TABLET,
      isLaptop: width >= WindowWidth.LAPTOP,
    }),
    [width]
  );

  return (
    <>
      {!isMobile && (
        <TopPanel
          itemsStore={itemsStore}
          searchTitle={searchTitle}
          statistics={statistics}
          subtitle={`Welcome, ${user?.fullName || ''}!`}
          title={title}
        />
      )}
      {!isLaptop && <MobileHeader title={title} userName={user?.fullName || ''} />}
    </>
  );
});

export default Header;
