import { useEffect } from 'react';

export default function useWindowScrollBlock(isBlock: boolean) {
  useEffect(() => {
    if (isBlock) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.removeAttribute('style');
    };
  }, [isBlock]);
}
