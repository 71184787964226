import PaymentHeader from 'src/components/common/payment-header';

import { LanguageProvider } from 'src/contexts';
import type { PaymentLayoutProps } from './payment-layout.props';

import './payment-layout.scss';

const PaymentLayout = ({ children }: PaymentLayoutProps) => {
  return (
    <LanguageProvider>
      <div className="payment-layout">
        <PaymentHeader />
        <div className="payment-layout__content">{children}</div>
      </div>
    </LanguageProvider>
  );
};

export default PaymentLayout;
