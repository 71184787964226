import { makeAutoObservable } from 'mobx';

import { INVOICE_GET_PARAM, SidebarTab } from 'src/constants';
import { addSearchParam, getSearchParam, removeSearchParam } from 'src/utils';

class SidebarStore {
  init() {
    const initialInvoiceId = getSearchParam(INVOICE_GET_PARAM);
    if (initialInvoiceId) {
      this.dataId = initialInvoiceId;
      this.isShown = true;
    }
  }

  activeTab: SidebarTab = SidebarTab.SUMMARY;
  dataId: string = '';
  isShown: boolean = false;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    this.init();
  }

  setActiveTab(activeTab: SidebarTab) {
    this.activeTab = activeTab;
  }

  setDataId(id: string) {
    this.dataId = id;
  }

  setIsShown(isShown: boolean) {
    this.isShown = isShown;
  }

  hideSidebar() {
    this.setIsShown(false);
    this.setDataId('');
    this.setActiveTab(SidebarTab.SUMMARY);
    removeSearchParam(INVOICE_GET_PARAM);
  }

  showSidebar(id: string) {
    this.setDataId(id);
    this.setIsShown(true);
    addSearchParam(INVOICE_GET_PARAM, id);
  }
}

export { SidebarStore };

export default new SidebarStore();
