export enum ApiResponseStatus {
  BAD_REQUEST = 400,
  SUCCESS = 200,
  SUCCESS_CREATED = 201,
  NOT_FOUND = 404,
  UNAUTHORIZED = 401,
  TOO_MANY_REQUESTS = 429,
}

export const RESPONSE_HEADERS_TOTAL_COUNT = 'x-total-count';
