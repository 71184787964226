import React from 'react';
import { observer } from 'mobx-react-lite';

import DateRange from 'src/components/common/date-range';
import FilterBlock from 'src/components/common/filter-block';
import StatusesList from 'src/components/common/statuses-list';
import Select from 'src/components/ui/select';

import constantsStore from 'src/stores/constants-store';
import globalAppStore from 'src/stores/global-app-store';

import { INVOICE_STATUSES, PageDataType, PAYMENTS_STATUSES } from 'src/constants';
import type { FiltersProps } from './filters.props';

const Filters = observer(
  ({
    filtersType,
    dateRangeStore,
    selectedStatuses,
    selectedSeller,
    toggleStatus,
    setSelectedSeller,
  }: FiltersProps) => {
    const { isManager } = globalAppStore;
    const { isLoading, sellersOptionsWithAll } = constantsStore;
    const isSalesFilter = filtersType === PageDataType.INVOICES;

    return (
      <>
        <FilterBlock title="Seller">
          <Select
            isDisabled={!isManager || isLoading}
            items={sellersOptionsWithAll}
            label="Seller"
            value={selectedSeller}
            size="flattened"
            onChange={setSelectedSeller}
          />
        </FilterBlock>
        <FilterBlock title={isSalesFilter ? 'Status' : 'Sales Status'}>
          <StatusesList
            isLoading={isLoading}
            statuses={isSalesFilter ? INVOICE_STATUSES : PAYMENTS_STATUSES}
            selectedStatuses={selectedStatuses}
            toggleStatus={toggleStatus}
          />
        </FilterBlock>
        <FilterBlock title={isSalesFilter ? 'Invoice Date' : 'Payment Date'}>
          <DateRange
            store={dateRangeStore}
            isDisabled={isLoading}
            withoutDatePeriods={!isSalesFilter}
          />
        </FilterBlock>
      </>
    );
  }
);

export default Filters;
