import LanguageToggler from './components/language-toggler';

import { PUBLIC_URL } from 'src/constants';

import './payment-header.scss';

const LOGO_PATH = `${PUBLIC_URL}/logos/logo-header.svg`;
const MOBILE_LOGO_PATH = `${PUBLIC_URL}/logos/logo-small-colored.svg`;
const MOBILE_MAX_WIDTH = 767;

const PaymentHeader = () => {
  return (
    <div className="payment-header">
      <picture>
        <source media={`(max-width: ${MOBILE_MAX_WIDTH}px)`} srcSet={MOBILE_LOGO_PATH} />
        <img
          className="payment-header__logo"
          alt="Bezikaron Logo"
          height={48}
          src={LOGO_PATH}
          width={32}
        />
      </picture>
      <LanguageToggler className="payment-header__toggler" />
    </div>
  );
};

export default PaymentHeader;
