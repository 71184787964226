import { useMemo } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import MobileSearchWindow from 'src/components/common/mobile-search-window';
import Navbar from 'src/components/common/navbar';
import Sidebar from 'src/components/common/sidebar';

import searchWindowStore from 'src/stores/search-window-store';
import sidebarStore from 'src/stores/sidebar-store';

import useWindowSize from 'src/hooks/use-window-size';
import { WindowWidth } from 'src/constants';
import type { MainLayoutProps } from './main-layout.props';

import './main-layout.scss';

const MainLayout = observer(
  ({ children, className, header: Header, mobileFilters: Filters }: MainLayoutProps) => {
    const { width } = useWindowSize();
    const { isTablet, isLaptop } = useMemo(
      () => ({
        isTablet: width >= WindowWidth.TABLET,
        isLaptop: width >= WindowWidth.LAPTOP,
      }),
      [width]
    );

    return (
      <>
        <div className={classNames('main-layout', className)}>
          {isLaptop && <Navbar />}
          <Header />
          <div className="main-layout__content scrollbar">{children}</div>
          {isTablet || <Filters />}
        </div>
        {sidebarStore.isShown && sidebarStore.dataId && (
          <Sidebar id={sidebarStore.dataId} isDesktop={isTablet} />
        )}
        {searchWindowStore.isShown && <MobileSearchWindow />}
      </>
    );
  }
);

export default MainLayout;
