import { observer } from 'mobx-react-lite';

import Status from 'src/components/common/status';

import sidebarStore from 'src/stores/sidebar-store';

import {
  LocaleFormat,
  MIN_ISO_DATE_LENGTH,
  PageDataType,
  PaymentMethodLabel,
  TimeFormat,
} from 'src/constants';
import { formatApiToPhone, formatDate, formatMoney } from 'src/utils';
import type { IListInvoice, IListPayment } from 'src/interfaces';
import type { MobileCardProps } from './mobile-card.props';

import './mobile-card.scss';

const checkIsPaymentType = (
  item: IListInvoice | IListPayment,
  type: PageDataType
): item is IListPayment => {
  return type === PageDataType.PAYMENTS;
};

const MobileCard = observer(({ item, type }: MobileCardProps) => {
  const isPaymentType = checkIsPaymentType(item, type);
  const isInvoiceType = !isPaymentType;

  const handleMobileCardClick = () => {
    const invoiceId = isInvoiceType ? item.id : item.invoiceId;
    sidebarStore.showSidebar(invoiceId);
  };

  return (
    <div className="mobile-card" onClick={handleMobileCardClick}>
      <div className="mobile-card__header">
        <p className="mobile-card__date">
          <span className="mobile-card__date-text">
            {formatDate(isInvoiceType ? item.createdAt : item.paidAt, LocaleFormat.he)}
          </span>
          <span className="mobile-card__date-text">
            {isInvoiceType && formatDate(item.createdAt, TimeFormat.DUAL)}
            {isPaymentType &&
              item.paidAt.length === MIN_ISO_DATE_LENGTH &&
              formatDate(item.paidAt, TimeFormat.DUAL)}
          </span>
        </p>
        {<Status type={isInvoiceType ? item.status : item.invoiceStatus} />}
      </div>

      <div className="mobile-card__container">
        <div className="mobile-card__persons-wrapper">
          <div className="mobile-card__person">
            <p className="mobile-card__person-title">Seller</p>
            <p className="mobile-card__person-name">{item.seller}</p>
          </div>
          <div className="mobile-card__person">
            <p className="mobile-card__person-title">Purchaser</p>
            <p className="mobile-card__person-name">{item.purchaserName}</p>
            {isPaymentType && (
              <p className="mobile-card__person-phone">{formatApiToPhone(item.purchaserPhone)}</p>
            )}
          </div>
        </div>

        <ul className="mobile-card__info-list">
          <li className="mobile-card__info-list-item">
            {isInvoiceType ? (
              <>
                <span className="mobile-card__info-label">Total:</span>
                <span className="mobile-card__info-value">
                  {formatMoney(item.total, false, true)}
                </span>
              </>
            ) : (
              <>
                <span className="mobile-card__info-label">Type:</span>
                <span className="mobile-card__info-value">{PaymentMethodLabel[item.type]}</span>
              </>
            )}
          </li>
          <li className="mobile-card__info-list-item">
            <span className="mobile-card__info-label">Paid:</span>
            <span className="mobile-card__info-value">{formatMoney(item.paid, false, true)}</span>
          </li>
          <li className="mobile-card__info-list-item">
            <span className="mobile-card__info-label">Balance:</span>
            <span className="mobile-card__info-value">
              {formatMoney(item.balance, false, true)}
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
});

export default MobileCard;
