import React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import Status from 'src/components/common/status';
import Table, { TableMultidata } from 'src/components/common/table';
import IconButton from 'src/components/ui/icon-button';
import { ReactComponent as SaveIcon } from 'src/assets/icons/save.svg';
import { ReactComponent as ShareIcon } from 'src/assets/icons/share.svg';
import { ReactComponent as TrashIcon } from 'src/assets/icons/trash.svg';

import globalAppStore from 'src/stores/global-app-store';
import popupStore from 'src/stores/popup-store';

import { fetchPaymentDelete } from 'src/api';
import {
  AppRoute,
  ErrorText,
  FULL_PAYMENT_INSTALLMENTS_NUMBER,
  PaymentMethod,
  PaymentMethodLabel,
  PaymentStatus,
  PopupName,
  PopupText,
  PopupTitle,
  PUBLIC_URL,
  TABLE_SIDEBAR_PAYMENTS_COLUMNS,
  TableType,
} from 'src/constants';
import { formatMoney } from 'src/utils';
import type { IInvoicePayment } from 'src/interfaces';
import type { SidebarPaymentsTableProps } from './sidebar-payments-table.props';

import './sidebar-payments-table.scss';

function getCaptionText(paymentType: PaymentMethod, installmentsCount: number) {
  if (
    (paymentType !== PaymentMethod.CREDIT_DEBIT_CARD &&
      paymentType !== PaymentMethod.INSTALLMENT) ||
    installmentsCount === FULL_PAYMENT_INSTALLMENTS_NUMBER
  ) {
    return '';
  }

  return `${installmentsCount} months`;
}

function getPaymentMethodLabel(paymentType: PaymentMethod, installmentsCount: number) {
  return installmentsCount > FULL_PAYMENT_INSTALLMENTS_NUMBER &&
    paymentType === PaymentMethod.CREDIT_DEBIT_CARD
    ? PaymentMethodLabel.installment
    : PaymentMethodLabel[paymentType];
}

const SidebarPaymentsTable = observer(
  ({
    isActionButtonsDisabled,
    isLoading,
    isPrintable,
    paymentItems,
  }: SidebarPaymentsTableProps) => {
    const handleShowSharePopup = React.useCallback(
      (payment: IInvoicePayment) => () => {
        const shareData = {
          title: `Copy payment url`,
          url: `${PUBLIC_URL || window.location.origin}${AppRoute.PAYMENT}/${payment.id}`,
        };

        popupStore.showPopup(PopupName.SHARE, shareData);
      },
      []
    );

    const handleDocumentButtonClick = React.useCallback(
      (payment: IInvoicePayment) => () => {
        window.open(payment.meshulamInvoiceUrl, '_blank', 'noopener, noreferrer');
      },
      []
    );

    const handleDeleteButtonClick = React.useCallback(
      (payment: IInvoicePayment) => () => {
        popupStore.showPopup(PopupName.CONFIRM, {
          mainActionLabel: 'Delete',
          secondaryActionLabel: 'Cancel',
          text: PopupText.DELETE_PAYMENT,
          title: PopupTitle.DELETE_PAYMENT,
          mainActionHandler: async () => {
            const { error, isSuccess } = await fetchPaymentDelete(payment.id);
            popupStore.showPopup(PopupName.WARN, {
              title: isSuccess ? PopupTitle.SUCCESS : ErrorText.REQUEST_FAILURE,
              text: isSuccess ? 'The payment was successfully deleted' : error || ErrorText.DEFAULT,
            });
          },
        });
      },
      []
    );

    const tableItems = paymentItems.map((payment) => {
      return {
        id: payment.id,
        items: [
          {
            className: 'sidebar-payments-table__cell sidebar-payments-table__cell_date',
            text: payment.paidAt || '-',
          },
          {
            className: 'sidebar-payments-table__cell sidebar-payments-table__cell_amount',
            isBold: true,
            text: formatMoney(payment.amount, false, true),
          },
          {
            className: 'sidebar-payments-table__cell sidebar-payments-table__cell_type',
            ...(payment.type
              ? {
                  childComponent: (
                    <TableMultidata
                      captionText={getCaptionText(payment.type, payment.installments)}
                      mainText={getPaymentMethodLabel(payment.type, payment.installments)}
                      tableType={TableType.SIDEBAR_PAYMENTS}
                    />
                  ),
                }
              : { text: '-' }),
          },
          {
            className: 'sidebar-payments-table__cell sidebar-payments-table__cell_actions',
            childComponent: (
              <div
                className={classNames(
                  'sidebar-payments-table__actions',
                  isPrintable && 'sidebar-payments-table__actions_print'
                )}
              >
                {!isPrintable && (
                  <>
                    <IconButton
                      className="sidebar-payments-table__button"
                      ariaLabel="Share payment page"
                      icon={<ShareIcon />}
                      isDisabled={!payment.hasPaymentPage || isActionButtonsDisabled}
                      theme="square"
                      onClick={handleShowSharePopup(payment)}
                    />
                    <IconButton
                      className="sidebar-payments-table__button"
                      ariaLabel="Download payment documents"
                      icon={<SaveIcon />}
                      isDisabled={!payment.meshulamInvoiceUrl || isActionButtonsDisabled}
                      theme="square"
                      onClick={handleDocumentButtonClick(payment)}
                    />
                    <IconButton
                      className="sidebar-payments-table__button"
                      ariaLabel="Delete payment"
                      icon={<TrashIcon />}
                      isDisabled={
                        payment.status !== PaymentStatus.UNPAID ||
                        !globalAppStore.isManager ||
                        isActionButtonsDisabled
                      }
                      theme="square"
                      onClick={handleDeleteButtonClick(payment)}
                    />
                  </>
                )}

                <Status className="sidebar-payments-table__status" type={payment.status} />
              </div>
            ),
          },
        ],
      };
    });

    return (
      <div
        className={classNames(
          'sidebar-payments-table',
          isPrintable && 'sidebar-payments-table_print'
        )}
      >
        <Table
          headerItems={TABLE_SIDEBAR_PAYMENTS_COLUMNS}
          isProcessing={isLoading}
          rows={tableItems}
          tableType={TableType.SIDEBAR_PAYMENTS}
        />
      </div>
    );
  }
);

export default SidebarPaymentsTable;
