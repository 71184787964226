import { doDelete, doGet } from './axios-config';

import { mapApiToPayment } from 'src/adapters';
import { ApiResponseStatus, ApiRoute, PaymentMethod } from 'src/constants';
import { downloadFile, getFileName, parseJSONObject } from 'src/utils';
import type { IApiPayment, IExportPaymentsParams } from 'src/interfaces';

export async function fetchPaymentById(paymentId: string) {
  try {
    const result = await doGet<IApiPayment>(ApiRoute.PAYMENT(paymentId));

    return mapApiToPayment(result.data);
  } catch (err: any) {
    console.log(err);
    return null;
  }
}

export async function fetchPaymentUrl(
  paymentId: string,
  paymentType: PaymentMethod.CREDIT_DEBIT_CARD | PaymentMethod.BIT
): Promise<
  | { data: string; error?: never; isSuccess: true; status?: never }
  | { data?: never; error?: string; isSuccess: false; status?: number }
> {
  try {
    const result = await doGet<{ transaction_url: string }>(ApiRoute.PAYMENT_URL(paymentId), {
      params: { payment_type: paymentType },
    });

    return { isSuccess: true, data: result.data.transaction_url };
  } catch (err: any) {
    console.log(err);
    return {
      isSuccess: false,
      ...(err.response?.data?.message?.message && {
        error: parseJSONObject(err.response?.data?.message?.message).message,
      }),
      ...(err.response?.status && { status: err.response?.status }),
    };
  }
}

export async function fetchPaymentDelete(
  paymentId: string
): Promise<{ error?: never; isSuccess: true } | { error?: string; isSuccess: false }> {
  try {
    const result = await doDelete<never>(ApiRoute.PAYMENT(paymentId));
    return { isSuccess: result.status === ApiResponseStatus.SUCCESS };
  } catch (err: any) {
    console.log(err);
    return { isSuccess: false, error: err.response?.data?.message };
  }
}

export async function fetchPaymentsExport(params: IExportPaymentsParams) {
  try {
    const response = await doGet(ApiRoute.PAYMENTS_EXPORT, {
      params: { filter: JSON.stringify(params.filter) },
      responseType: 'blob',
    });
    downloadFile(response, getFileName('payments', params));
    return { isSuccess: true };
  } catch (err: any) {
    console.log(err);
    return { isSuccess: false, error: err.response?.data?.message };
  }
}
