import { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import Loader from 'src/components/common/loader';
import MobileSearchHeader from './components/mobile-search-header';
import MobileList from '../mobile-list';

import searchWindowStore from 'src/stores/search-window-store';

import useUnmountAnimation from 'src/hooks/use-unmount-animation';
import useWindowScrollBlock from 'src/hooks/use-window-scroll-block';

import './mobile-search-window.scss';

const SCROLLABLE_LIST_ID = 'mobile-search-list';

const MobileSearchWindow = observer(() => {
  const { hasMore, isLoading, items, itemsType, title, loadMore, hide } = searchWindowStore;
  const { isUnmounting, onAnimationEnd, unmountComponent } = useUnmountAnimation(hide);
  const location = useLocation();
  useWindowScrollBlock(true);

  useEffect(() => {
    searchWindowStore.init(location.pathname);
  }, [location]);

  return (
    <div
      className={classNames('mobile-search-window', isUnmounting && 'mobile-search-window_hiding')}
      onAnimationEnd={onAnimationEnd}
    >
      <div className="mobile-search-window__wrapper">
        <MobileSearchHeader title={title} onBackButtonClick={unmountComponent} />
        <div className="mobile-search-window__result" id={SCROLLABLE_LIST_ID}>
          <InfiniteScroll
            dataLength={items.length}
            hasMore={hasMore}
            loader={<Loader />}
            next={loadMore}
            scrollableTarget={SCROLLABLE_LIST_ID}
            style={{ height: '100%', overflow: 'inherit' }}
          >
            <MobileList isProcessing={isLoading} items={items} type={itemsType} />
          </InfiniteScroll>
          {isLoading && <Loader position="overlay" />}
        </div>
      </div>
    </div>
  );
});

export default MobileSearchWindow;
