import type { FC } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import Loader from 'src/components/common/loader';
import AccountTabSection from './components/account-tab-section';
import SidebarHeader from './components/sidebar-header';
import SidebarTabs from './components/sidebar-tabs';
import SummaryTabSection from './components/summary-tab-section';

import invoiceDetailsStore from 'src/stores/invoice-details-store';
import sidebarStore from 'src/stores/sidebar-store';

import useKeyUpGlobal from 'src/hooks/use-key-up-global';
import useUnmountAnimation from 'src/hooks/use-unmount-animation';
import { Key, SidebarTab } from 'src/constants';
import { getTableRowId } from 'src/utils';
import type { IDetailedInvoice } from 'src/interfaces';
import type { SidebarProps } from './sidebar.props';

import './sidebar.scss';

const TAB_SECTIONS: Record<SidebarTab, FC<{ invoice: IDetailedInvoice; isDesktop?: boolean }>> = {
  [SidebarTab.SUMMARY]: SummaryTabSection,
  [SidebarTab.ACCOUNT]: AccountTabSection,
};

const Sidebar = observer(({ id, isDesktop }: SidebarProps) => {
  const { hideSidebar } = sidebarStore;
  const { invoice, isLoading } = invoiceDetailsStore;
  const { isUnmounting, onAnimationEnd, unmountComponent } = useUnmountAnimation(hideSidebar);

  useKeyUpGlobal(Key.ESCAPE, () => {
    unmountComponent();
    (document.querySelector(`#${getTableRowId(id)}`) as HTMLTableRowElement)?.focus();
  });

  const TabSection = TAB_SECTIONS[sidebarStore.activeTab];

  return (
    <div
      className={classNames('sidebar', isUnmounting && 'sidebar_hide')}
      onAnimationEnd={onAnimationEnd}
    >
      <div className="sidebar__shadow" onClick={unmountComponent} />
      <div className="sidebar__content">
        <div className="sidebar__content-wrapper">
          <SidebarHeader invoice={invoice} onCancelBtnClick={unmountComponent} />
          {isLoading || !invoice ? (
            <Loader position="overlay" />
          ) : (
            <>
              <SidebarTabs />
              <div className="sidebar__content-body  scrollbar">
                <TabSection invoice={invoice} isDesktop={isDesktop} />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
});

export default Sidebar;
