import { makeAutoObservable } from 'mobx';

import { DateError, DatePeriod } from 'src/constants';
import {
  checkDateValid,
  checkIsDateBefore,
  formatDateFromSlashToDot,
  getDatePeriodValues,
  getPeriodByDatesRange,
} from 'src/utils';
import type { IDateRange } from 'src/interfaces';

type DateRangeProperty = keyof IDateRange;

class DateRangeStore {
  init(initialPeriod: DatePeriod | IDateRange) {
    if (typeof initialPeriod === 'string') {
      this.setActivePeriod(initialPeriod);
    } else if (typeof initialPeriod === 'object') {
      this.setDate(initialPeriod);
    }
  }

  dateRange: IDateRange = {
    dateFrom: '',
    dateTo: '',
  };

  constructor(initialPeriod?: DatePeriod | IDateRange) {
    makeAutoObservable(this, {}, { autoBind: true });

    if (initialPeriod) {
      this.init(initialPeriod);
    }
  }

  getValidationProps(dateUnit: DateRangeProperty) {
    const date = formatDateFromSlashToDot(this.dateRange[dateUnit]);
    const isValid = checkDateValid(date) || date === '';
    const textError = isValid ? '' : DateError.DEFAULT;

    return { isValid, textError };
  }

  setDate(dateRange: IDateRange) {
    this.dateRange = dateRange;
  }

  setDateUnit(dateUnit: DateRangeProperty, value: string) {
    if (value !== this.dateRange[dateUnit]) {
      this.dateRange = { ...this.dateRange, [dateUnit]: value };
    }
  }

  setActivePeriod(period: DatePeriod) {
    const { from, to } = getDatePeriodValues(period);
    this.setDate({ dateFrom: from, dateTo: to });
  }

  getDateUnitValue(dateUnit: DateRangeProperty) {
    return this.dateRange[dateUnit];
  }

  getInputProps(dateUnit: DateRangeProperty) {
    return {
      value: this.getDateUnitValue(dateUnit) ?? '',
      onChange: (value: string) => this.setDateUnit(dateUnit, value),
    };
  }

  clearDate() {
    this.dateRange = {
      dateFrom: '',
      dateTo: '',
    };
  }

  get activePeriod() {
    return getPeriodByDatesRange(this.dateRange);
  }

  get isDateValid() {
    let isValid = checkDateValid(this.dateRange.dateFrom) && checkDateValid(this.dateRange.dateTo);
    let textError = '';

    if (!isValid) {
      return { isValid, textError };
    }
    if (checkIsDateBefore(this.dateRange.dateTo, this.dateRange.dateFrom)) {
      isValid = false;
      textError = DateError.START_DATE_IS_GREATER_THAN_END_DATE;
    }

    return { isValid, textError };
  }

  get isEmptyDate() {
    const { dateFrom, dateTo } = this.dateRange;

    return !dateFrom && !dateTo;
  }
}

export default DateRangeStore;
