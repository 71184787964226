import React from 'react';
import classNames from 'classnames';

import type { TableMultidataProps } from './table-multidata.props';

import './table-multidata.scss';

const TableMultidata = ({ captionText, isFade, mainText, tableType }: TableMultidataProps) => (
  <div
    className={classNames(
      'table-multidata',
      `table-multidata_${tableType}`,
      isFade && 'table-multidata_fade'
    )}
  >
    <span className="table-multidata__main-text">{mainText}</span>
    {captionText && (
      <span className="table-multidata__caption">
        <span className="table-multidata__caption-text">{captionText}</span>
      </span>
    )}
  </div>
);

export default TableMultidata;
