import React from 'react';
import classNames from 'classnames';

import InputPlaceholder from 'src/components/ui/input-placeholder';
import TextError from 'src/components/ui/text-error';
import { ReactComponent as SearchIcon } from 'src/assets/icons/search.svg';

import { CYRILLIC_SYMBOLS_REG_EXP } from 'src/constants';
import type { InputSearchProps } from './input-search.props';

import '../input.scss';

const InputSearch = React.forwardRef<HTMLInputElement, InputSearchProps>(
  (
    {
      className,
      isDisabled = false,
      isValid = true,
      placeholder = 'Search',
      size = 'regular',
      textError = '',
      value,
      onFocus,
      onBlur,
      onChange,
    },
    ref
  ) => {
    const [isFocused, setIsFocused] = React.useState(false);
    const isHidePlaceholder = isFocused || !!value;
    const inputClasses = classNames('input  input_search', className, {
      input_placeholder: !!placeholder,
      input_disabled: isDisabled,
      input_focus: isFocused,
      input_invalid: !isValid && !isFocused,
      input_flattened: size === 'flattened',
    });

    const handleFocus = () => {
      setIsFocused(true);
      onFocus?.();
    };
    const handleBlur = () => {
      setIsFocused(false);
      onBlur?.();
    };
    const handleChange = (
      event: React.SyntheticEvent<HTMLInputElement> | React.SyntheticEvent<HTMLTextAreaElement>
    ) => {
      const inputValue = event.currentTarget.value;
      const testedValue = inputValue.replace(CYRILLIC_SYMBOLS_REG_EXP, '');

      if (inputValue === testedValue) {
        onChange(testedValue);
      }
    };

    return (
      <div className={inputClasses}>
        <InputPlaceholder
          placeholder={placeholder}
          hide={isHidePlaceholder}
          isValid={isValid}
          style={{ left: '44px' }}
        />
        {<SearchIcon className="input__icon" />}
        <input
          ref={ref}
          dir="auto"
          className="input__field"
          value={value}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          disabled={isDisabled}
        />
        {!isValid && !isFocused && !isDisabled ? <TextError textError={textError} /> : null}
      </div>
    );
  }
);

export default InputSearch;
