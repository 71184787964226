import Status from 'src/components/common/status';
import IconButton from 'src/components/ui/icon-button';
import { ReactComponent as IconCancel } from 'src/assets/icons/cross.svg';
import { ReactComponent as IconPrint } from 'src/assets/icons/print.svg';

import { AppRoute } from 'src/constants';
import type { SidebarHeaderProps } from './sidebar-header.props';

import './sidebar-header.scss';

const SidebarHeader = ({ invoice, onCancelBtnClick }: SidebarHeaderProps) => (
  <div className="sidebar-header">
    <div className="sidebar-header__content">
      <IconButton
        className="sidebar-header__icon-button"
        ariaLabel="Hide Sidebar"
        icon={<IconCancel />}
        theme="plain"
        onClick={onCancelBtnClick}
      />

      {invoice && <h2 className="sidebar-header__title">Invoice {invoice.no}</h2>}
    </div>

    {invoice && (
      <div className="sidebar-header__content sidebar-header__content_info">
        <Status className="sidebar-header__status" type={invoice.status} />

        <IconButton
          className="sidebar-header__icon-button"
          ariaLabel="Print invoice"
          href={`${AppRoute.PRINT_INVOICE}/${invoice.id}`}
          icon={<IconPrint />}
          tag="link"
          target="_blank"
        />
      </div>
    )}
  </div>
);

export default SidebarHeader;
