import { RefObject, useCallback, useEffect, useRef } from 'react';

export default function useMobilePopup(
  popupRef: RefObject<HTMLDivElement>,
  onClosePopup: () => void,
  isListenerStopped: boolean = false
) {
  const dragState = useRef({ currentPosition: 0, height: 0, startPosition: 0 });

  const updateShadowStyle = useCallback(
    (callback: (shadow: HTMLElement) => void) => {
      const popupShadow = popupRef.current?.parentElement?.querySelector(
        '.popup__shadow'
      ) as HTMLElement;
      if (popupShadow) {
        callback(popupShadow);
      }
    },
    [popupRef]
  );

  const updatePopupPosition = useCallback(
    (currentPosition: number) => {
      dragState.current.currentPosition = currentPosition;
      const popup = popupRef.current;
      if (popup) {
        const { startPosition, height } = dragState.current;
        const offset = Math.max(currentPosition - startPosition, 0);
        popup.style.transform = `translateY(${Math.round((offset * 100) / height)}%)`;
        updateShadowStyle((shadow) => {
          shadow.style.opacity = `${1 - Math.round((offset / height) * 100) / 100}`;
        });
      }
    },
    [popupRef, updateShadowStyle]
  );

  const startDrag = useCallback(
    (startPosition: number) => {
      const popup = popupRef.current;
      if (popup) {
        dragState.current.height = popup.clientHeight;
        dragState.current.startPosition = startPosition;
        updatePopupPosition(startPosition);
        popup.classList.add('popup__wrapper_dragging');
        popup.classList.remove('popup__wrapper_dragged-to-top', 'popup__wrapper_dragged-to-bottom');
        updateShadowStyle((shadow) => {
          shadow.classList.add('popup__shadow_dragging');
        });
      }
    },
    [popupRef, updatePopupPosition, updateShadowStyle]
  );

  const endDrag = useCallback(
    (endPosition?: number) => {
      const popup = popupRef.current;
      if (popup) {
        const { currentPosition, startPosition, height } = dragState.current;
        const shouldHidePopup = ((endPosition ?? currentPosition) - startPosition) * 2 > height;
        dragState.current.currentPosition = 0;
        dragState.current.height = 0;
        dragState.current.startPosition = 0;
        const transitionClassName = shouldHidePopup
          ? 'popup__wrapper_dragged-to-bottom'
          : 'popup__wrapper_dragged-to-top';
        const shadowTransitionClassName = shouldHidePopup
          ? 'popup__shadow_fade-out'
          : 'popup__shadow_fade-in';

        popup.addEventListener(
          'transitionend',
          () => {
            popup.classList.remove('popup__wrapper_dragging', transitionClassName);
            updateShadowStyle((shadow) => {
              shadow.classList.remove('popup__shadow_dragging', shadowTransitionClassName);
            });
            if (shouldHidePopup) {
              onClosePopup();
            }
          },
          { once: true }
        );
        popup.classList.add(transitionClassName);
        popup.removeAttribute('style');
        updateShadowStyle((shadow) => {
          shadow.classList.add(shadowTransitionClassName);
          shadow.removeAttribute('style');
        });
      }
    },
    [popupRef, updateShadowStyle, onClosePopup]
  );

  const onDragStart = useCallback((e: DragEvent) => startDrag(e.pageY), [startDrag]);

  const onDrag = useCallback((e: DragEvent) => updatePopupPosition(e.pageY), [updatePopupPosition]);

  const onDragEnd = useCallback((e: DragEvent) => endDrag(e.pageY), [endDrag]);

  const onTouchStart = useCallback((e: TouchEvent) => startDrag(e.touches[0]?.pageY), [startDrag]);

  const onTouchMove = useCallback(
    (e: TouchEvent) => {
      e.preventDefault();
      updatePopupPosition(e.touches[0]?.pageY);
    },
    [updatePopupPosition]
  );

  const onTouchEnd = useCallback((e: TouchEvent) => endDrag(e.touches[0]?.pageY), [endDrag]);

  useEffect(() => {
    const popup = popupRef.current;
    if (popup) {
      const listenersOptions: AddEventListenerOptions & EventListenerOptions = { passive: false };
      const removeListeners = () => {
        popup.removeEventListener('dragstart', onDragStart, listenersOptions);
        popup.removeEventListener('drag', onDrag, listenersOptions);
        popup.removeEventListener('dragend', onDragEnd, listenersOptions);
        popup.removeEventListener('touchstart', onTouchStart, listenersOptions);
        popup.removeEventListener('touchmove', onTouchMove, listenersOptions);
        popup.removeEventListener('touchend', onTouchEnd, listenersOptions);
        popup.removeEventListener('touchcancel', onTouchEnd, listenersOptions);
      };

      if (isListenerStopped) {
        removeListeners();
      } else {
        popup.addEventListener('dragstart', onDragStart, listenersOptions);
        popup.addEventListener('drag', onDrag, listenersOptions);
        popup.addEventListener('dragend', onDragEnd, listenersOptions);
        popup.addEventListener('touchstart', onTouchStart, listenersOptions);
        popup.addEventListener('touchmove', onTouchMove, listenersOptions);
        popup.addEventListener('touchend', onTouchEnd, listenersOptions);
        popup.addEventListener('touchcancel', onTouchEnd, listenersOptions);
      }

      return removeListeners;
    }
  }, [
    isListenerStopped,
    onDrag,
    onDragEnd,
    onDragStart,
    onTouchEnd,
    onTouchMove,
    onTouchStart,
    popupRef,
  ]);
}
