import { makeAutoObservable, reaction } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

import type DateRangeStore from 'src/stores/common/date-range-store';
import PagingDataStore from 'src/stores/data-store/paging-data-store';
import paymentsFilterStore from 'src/stores/filters/payments-filter-store';
import popupStore from 'src/stores/popup-store';
import socketStore from 'src/stores/socket-store';

import { mapApiToListPayment } from 'src/adapters';
import { fetchPaymentsExport } from 'src/api';
import {
  ALL,
  ApiRoute,
  AppRoute,
  ErrorText,
  InvoiceStatus,
  MAX_TABLE_LIST_COUNT_PER_PAGE,
  PopupName,
  SEARCH_DEBOUNCE,
  SEARCH_STRING_MIN_LENGTH,
} from 'src/constants';
import { getApiFormatDateRange, getCurrentRoute } from 'src/utils';
import type { IApiListPayment, IApiPaymentsFilters } from 'src/interfaces';

const SOCKET_LISTENER_ID = uuidv4();

class PaymentsStore {
  init() {
    void this.paymentsDataStore.load();

    socketStore.addPaymentsStatisticsListener({
      id: SOCKET_LISTENER_ID,
      cb: this.updateList,
    });
  }

  filtersSetId: string = uuidv4();
  isDownloading: boolean = false;
  searchString: string = '';

  paymentsDataStore = new PagingDataStore<IApiListPayment, IApiPaymentsFilters>({
    url: ApiRoute.PAYMENTS,
    data: [],
    pagingProps: {
      offset: 0,
      limit: MAX_TABLE_LIST_COUNT_PER_PAGE,
    },
    filters: {
      invoice_status: paymentsFilterStore.selectedStatuses,
      paid_at: getApiFormatDateRange(paymentsFilterStore.dateRangeStore.dateRange),
      ...(paymentsFilterStore.selectedSeller && { seller: paymentsFilterStore.selectedSeller }),
    },
  });

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    reaction(
      () => paymentsFilterStore.dateRangeStore.dateRange,
      () => this.applyDateFilter(paymentsFilterStore.dateRangeStore)
    );

    reaction(
      () => paymentsFilterStore.selectedStatuses,
      (statuses) => this.applyStatusFilter(statuses)
    );

    reaction(
      () => paymentsFilterStore.selectedSeller,
      (seller) => this.applySeller(seller)
    );

    reaction(
      () => this.searchString,
      () => this.applySearch(),
      { delay: SEARCH_DEBOUNCE }
    );
  }

  updateFilterSetId() {
    this.filtersSetId = uuidv4();
  }

  setIsDownloading(isDownloading: boolean) {
    this.isDownloading = isDownloading;
  }

  setSearchString(value: string) {
    this.searchString = value;
  }

  loadMore() {
    if (this.hasMore) {
      void this.paymentsDataStore.loadNextPage();
    }
  }

  applySeller(seller: string) {
    let newFilters = { ...this.paymentsDataStore.filters };

    if (seller === ALL || !seller) {
      delete newFilters.seller;
    } else {
      newFilters = { ...newFilters, seller };
    }

    this.applyFilter(newFilters);
  }

  applyDateFilter(dateRangeStore: DateRangeStore) {
    let newFilters = { ...this.paymentsDataStore.filters };

    if (dateRangeStore.isEmptyDate && newFilters.paid_at) {
      delete newFilters.paid_at;
      this.applyFilter(newFilters);
    }

    if (dateRangeStore.isDateValid.isValid) {
      newFilters = { ...newFilters, paid_at: getApiFormatDateRange(dateRangeStore.dateRange) };
      this.applyFilter(newFilters);
    }
  }

  applyStatusFilter(statuses: InvoiceStatus[]) {
    let newFilters = { ...this.paymentsDataStore.filters };

    if (!paymentsFilterStore.dateRangeStore.isDateValid.isValid) {
      if (!paymentsFilterStore.dateRangeStore.isEmptyDate) {
        paymentsFilterStore.dateRangeStore.clearDate();
      }
    }

    this.applyFilter({ ...newFilters, invoice_status: statuses });
  }

  applySearch() {
    const { filters } = this.paymentsDataStore;

    const trimmedStr = this.searchString.trim();

    if (trimmedStr.length >= SEARCH_STRING_MIN_LENGTH) {
      this.applyFilter({ ...filters, search_str: trimmedStr });
    } else {
      const { search_str, ...otherFilters } = filters;
      if (search_str) {
        this.applyFilter(otherFilters);
      }
    }
  }

  applyFilter(filter: IApiPaymentsFilters) {
    const isSkipLoadByStatus = filter.invoice_status?.length === 0;
    this.paymentsDataStore.setFilters(filter, isSkipLoadByStatus);

    if (isSkipLoadByStatus) {
      this.paymentsDataStore.setData([]);
      this.paymentsDataStore.setTotal(0);
    }

    this.updateFilterSetId();
  }

  clear() {
    paymentsFilterStore.resetStore();
    socketStore.removePaymentsStatisticsListener(SOCKET_LISTENER_ID);
  }

  updateList(statistics: unknown, isFirstStatisticsLoad?: boolean) {
    if (isFirstStatisticsLoad) {
      return;
    }

    const currentRoute = getCurrentRoute(window.location.pathname) as AppRoute;

    if (currentRoute === AppRoute.PAYMENTS) {
      this.paymentsDataStore.reload();
    }
  }

  get isLoading() {
    return this.paymentsDataStore.isLoading;
  }

  get hasMore() {
    return !this.paymentsDataStore.isAllDataLoaded;
  }

  get items() {
    return this.paymentsDataStore.getData.map(mapApiToListPayment);
  }

  get isResetFilterDisabled() {
    return !this.searchString && paymentsFilterStore.hasDefaultFilters;
  }

  async exportPayments() {
    if (this.isDownloading) {
      return;
    }

    this.setIsDownloading(true);

    const {
      filters: { search_str, ...otherFilters },
    } = this.paymentsDataStore;

    const { error, isSuccess } = await fetchPaymentsExport({ filter: otherFilters });
    if (!isSuccess) {
      popupStore.showPopup(PopupName.WARN, {
        title: ErrorText.REQUEST_FAILURE,
        text: error || ErrorText.DEFAULT,
      });
    }

    this.setIsDownloading(false);
  }
}

export default new PaymentsStore();
export type { PaymentsStore };
