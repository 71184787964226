import type { TableEmptyRowProps } from './table-empty-row.props';

import './table-empty-row.scss';

const TableEmptyRow = ({ children }: TableEmptyRowProps) => (
  <tr className="table-empty-row">
    <td className="table-empty-row__cell">{children}</td>
  </tr>
);

export default TableEmptyRow;
